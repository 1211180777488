import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { AdminPlayer } from '../../models';
import { Error } from '../../components/UI/Error';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Players } from '../../components/admin/Players';

export const PlayersPage = () => {
  const [players, setPlayers] = useState<AdminPlayer[]>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const axios = useAxiosPrivate();

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    const doRequest = async () => {
      try {
        const resp = await axios.get('/admin/players');
        const res = resp.data.map((p: any) => {
          const player: AdminPlayer = {
            id: p['id'],
            username: p['username'],
            isRanked: p['is_ranked'],
            avatarUrl: p['avatar_url'],
            stickers: [],
          };
          return player;
        });
        setPlayers(res);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Players players={players!} />;
};
