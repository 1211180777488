import React from 'react';
import { Killers as killerStat } from '../../models';
import styled from 'styled-components';
import { NoContent } from '../UI/NoContent';
import { Column, Row, Table } from '../UI/Table';
import { Avatar } from '../UI/Avatar';
import { Link } from 'react-router-dom';

interface Props {
  killers: killerStat;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

const TableBlock = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  span {
    color: white;
    font-size: 18px;
    margin-bottom: 12px;
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled(Link)`
  margin-left: 8px;
  transition: color 0.3s;

  &:hover {
    color: var(--red-accent);
  }
`;

export const Killers: React.FC<Props> = (props) => {
  if (
    props.killers.topKilled.length === 0 &&
    props.killers.topKillers.length === 0
  ) {
    return <NoContent text="Статистики об убийцах нет" />;
  }

  return (
    <Container>
      {props.killers.topKillers.length > 0 && (
        <TableBlock>
          <span>Наиболее частые смерти от</span>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '100%' }}>Игрок</th>
                <th>Убийств</th>
              </tr>
            </thead>
            <tbody>
              {props.killers.topKillers.map((killer) => (
                <Row key={killer.userId}>
                  <Column>
                    <UserContainer>
                      <Avatar
                        widthpx={40}
                        heightpx={40}
                        radiuspx="50%"
                        avatarUrl={killer.avatarUrl}
                      ></Avatar>
                      <Username to={`/players/${killer.userId}`}>
                        {killer.username}
                      </Username>
                    </UserContainer>
                  </Column>
                  <Column>{killer.kills}</Column>
                </Row>
              ))}
            </tbody>
          </Table>
        </TableBlock>
      )}
      {props.killers.topKilled.length > 0 && (
        <TableBlock>
          <span>Наиболее частые убийства</span>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '100%' }}>Игрок</th>
                <th>Убийств</th>
              </tr>
            </thead>
            <tbody>
              {props.killers.topKilled.map((killer) => (
                <Row key={killer.userId}>
                  <Column>
                    <UserContainer>
                      <Avatar
                        widthpx={40}
                        heightpx={40}
                        radiuspx="50%"
                        avatarUrl={killer.avatarUrl}
                      ></Avatar>
                      <Username to={`/players/${killer.userId}`}>
                        {killer.username}
                      </Username>
                    </UserContainer>
                  </Column>
                  <Column>{killer.kills}</Column>
                </Row>
              ))}
            </tbody>
          </Table>
        </TableBlock>
      )}
    </Container>
  );
};
