import React from 'react';
import { Tournament } from '../../models';
import styled from 'styled-components';
import { Card } from '../UI/Card';
import { prettyDate } from '../../lib/date';
import { Column, Row, Table } from '../UI/Table';
import { Avatar } from '../UI/Avatar';
import { Link } from 'react-router-dom';
import { DescriptionCard } from '../UI/Desc-Card';

interface Props {
  tournament: Tournament;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

const Badge = styled.img`
  width: 215px;
  height: 100px;
  margin-right: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Title = styled.span`
  color: white;
  font-size: 24px;
  font-weight: bold;
`;

const Date = styled.span`
  color: var(--gray-accent-light);
  font-size: 16px;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled(Link)`
  margin-left: 8px;
  transition: color 0.3s;

  &:hover {
    color: var(--red-accent);
  }
`;

const VideoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 12px;
`;

const Video = styled.div`
  border: 2px solid var(--red-accent);
`;

const extractYoutubeVideoId = (url: string) => {
  const regexp = /watch\?v=(.+)/;
  const matches = url.match(regexp);
  if (matches && matches.length > 1) {
    return matches[1];
  }

  return '';
};

export const TournamentInfo: React.FC<Props> = (props) => {
  return (
    <Card>
      <Wrapper>
        <TitleContainer>
          <Title>{props.tournament.name}</Title>
          <Date>
            {prettyDate(props.tournament.startedAt)} -{' '}
            {prettyDate(props.tournament.endedAt)}
          </Date>
        </TitleContainer>
        <Header>
          <Badge src={props.tournament.badge}></Badge>
          <span style={{ color: 'white' }}>{props.tournament.description}</span>
        </Header>

        <Table>
          <thead>
            <tr>
              <th style={{ width: '80%', textAlign: 'left' }}>Игрок</th>
              <th>Место</th>
              <th>Баллы</th>
            </tr>
          </thead>
          <tbody>
            {props.tournament.placements.map((placement) => (
              <Row key={placement.username}>
                <Column>
                  <UserContainer>
                    <Avatar
                      widthpx={40}
                      heightpx={40}
                      radiuspx="50%"
                      avatarUrl={placement.avatarUrl}
                    ></Avatar>
                    <Username to={`/players/${placement.playerId}`}>
                      {placement.username}
                    </Username>
                  </UserContainer>
                </Column>
                <Column>{placement.placement}</Column>
                <Column>{placement.points}</Column>
              </Row>
            ))}
          </tbody>
        </Table>

        {props.tournament.links &&
          (props.tournament.links.vk || props.tournament.links.youtube) && (
            <VideoContainer>
              {props.tournament.links.youtube &&
                props.tournament.links.youtube.map((link) => (
                  <Video>
                    <iframe
                      width="560"
                      height="315"
                      src={`https://www.youtube.com/embed/${extractYoutubeVideoId(
                        link
                      )}`}
                      title="YouTube video player"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      key={link}
                      frameBorder={0}
                      allowFullScreen={true}
                    ></iframe>
                  </Video>
                ))}
              {props.tournament.links.vk &&
                props.tournament.links.vk.map((link) => (
                  <Video>
                    <iframe
                      src={link}
                      width="560"
                      height="315"
                      allow="autoplay; encrypted-media; fullscreen; picture-in-picture;"
                      frameBorder={0}
                      allowFullScreen={true}
                    ></iframe>
                  </Video>
                ))}
            </VideoContainer>
          )}
      </Wrapper>
    </Card>
  );
};
