import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

const Fade = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 24px;
`;

const Spinner = styled.div<Props>`
  height: ${(props) => (props.zeroPadding ? '24px' : '40px')};
  width: ${(props) => (props.zeroPadding ? '24px' : '40px')};
  border-radius: 50%;
  background-color: transparent;
  border-left: 2px solid white;
  animation: ${Fade} 0.3s infinite linear;
`;

interface Props {
  showTimeoutMs?: number;
  zeroPadding?: boolean;
}

export const LoadingSpinner: React.FC<Props> = ({
  showTimeoutMs = 100,
  zeroPadding = false,
}) => {
  const [show, setShow] = useState<boolean>(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShow(true);
    }, showTimeoutMs);

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [showTimeoutMs]);

  let content = <></>;
  if (show) {
    if (zeroPadding) {
      content = <Spinner zeroPadding={true}></Spinner>;
    } else {
      content = (
        <>
          <Wrapper>
            <Spinner zeroPadding={false}></Spinner>
          </Wrapper>
        </>
      );
    }
  }

  return content;
};
