import React from 'react';
import styled from 'styled-components';

interface Props {
  text: string;
}

const Container = styled.div`
  margin-top: 12px;
  text-align: center;
  color: var(--green-accent);
`;

export const Success: React.FC<Props> = (props) => {
  return <Container>{props.text}</Container>;
};
