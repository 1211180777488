import React, { useContext, useEffect, useState } from 'react';
import { Search } from '../../search/Search';
import { NavLink as BaseNavLink, useLocation } from 'react-router-dom';
import { Hat } from '../../../icons';
import styled from 'styled-components';
import { SearchV2 } from '../../search/SearchV2';
import { MagnifyingGlass } from 'phosphor-react';
import { PlayerSearchResult } from '../../../models';
import useAuth from '../../../hooks/useAuth';

const Wrapper = styled.header<{ color: string }>`
  width: 100%;
  height: 64px;
  background-color: ${(props) => props.color};
  color: white;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  display: flex;
  width: var(--content-width);
  height: 100%;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Navigation = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const NavLink = styled(BaseNavLink)`
  margin-right: 24px;
  transition: color 0.3s;
  font-weight: 400;

  &.active {
    color: var(--red-accent);
  }

  &:hover {
    color: var(--red-accent);
  }

  &.logo {
    margin-right: 12px;
    display: flex;
    transition: transform 0.1s;
  }

  &.logo:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
`;

export const Header = () => {
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [backgroundColor, setBackgroundColor] = useState<string>(
    'var(--black-accent)'
  );

  const location = useLocation();

  const auth = useAuth();

  useEffect(() => {
    if (location.pathname == '/hall-of-fame') {
      setBackgroundColor('transparent');
    } else {
      setBackgroundColor('var(--black-accent)');
    }
  }, [location.pathname]);

  const isValid = (): boolean => {
    if (auth.authData === null) {
      return false;
    }

    if (auth.authData.accessToken === '' || auth.authData.refreshToken === '')
      return false;

    return true;
  };

  return (
    <Wrapper color={backgroundColor}>
      <Container>
        <Navigation>
          <NavLink to="/" className="logo">
            <Hat />
          </NavLink>
          <NavLink to="/leaderboard">Лидерборд</NavLink>
          <NavLink to="/games">Матчи</NavLink>
          <NavLink to="/bans">Баны</NavLink>
          <NavLink to="/hall-of-fame">Зал славы</NavLink>
          <NavLink to="/versus">Противостояние игроков</NavLink>
          <NavLink to="/combos">Комбос</NavLink>
          <NavLink to="/tournaments">Турниры</NavLink>
          <NavLink to="/support">Поддержать</NavLink>
          {isValid() && <NavLink to="/admin">Админка</NavLink>}
        </Navigation>
        {!searchOpen && (
          <MagnifyingGlass
            size={24}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setSearchOpen(true);
            }}
          ></MagnifyingGlass>
        )}
        {searchOpen && (
          <SearchV2
            asLink={true}
            // onBlur={() => {
            //   setSearchOpen(false);
            // }}
            closed={() => setSearchOpen(false)}
          />
        )}
      </Container>
    </Wrapper>
  );
};
