import { Tooltip } from '../components/UI/Tooltip';

export const Mafia = () => {
  return (
    <Tooltip text="Мафия">
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_19126_53593)">
          <path
            d="M16 3.78176H15.3227V3.477L6.34745 3.47688L5.70391 4.62836C5.67001 4.72995 5.53452 4.76385 5.46683 4.69605C5.36524 4.66214 5.36524 4.52665 5.39915 4.45897L5.94111 3.47677H4.85731L4.21377 4.62825C4.17987 4.72984 4.04438 4.72984 3.97669 4.69594C3.8751 4.66204 3.8412 4.52655 3.90901 4.45886L4.45097 3.47667H3.36716L2.72363 4.62815C2.68973 4.72974 2.55423 4.76364 2.48655 4.69583C2.38496 4.66193 2.38496 4.52644 2.41886 4.45876L2.96083 3.47656L2.11386 3.47692C1.87679 3.47692 1.6736 3.61241 1.53812 3.81558L0.081765 6.35574C-0.155311 6.796 0.149451 7.33793 0.657505 7.33793H1.6058L0.0478357 12.3844C-0.0876548 12.7569 0.217228 13.1634 0.657475 13.1634H4.62013C4.92489 13.1634 5.19587 12.9603 5.22977 12.6554L5.67003 10.3184C7.63445 11.5716 10.1407 9.53935 9.19239 7.33796H13.426C13.9002 7.33796 14.3404 6.99931 14.3743 6.52514C14.3743 6.35575 14.3743 6.18648 14.2727 6.05098H11.3261C11.2245 6.05098 11.1229 5.94939 11.1229 5.8478C11.1229 5.74621 11.2245 5.64463 11.3261 5.64463H15.2887V5.33987H15.9661L15.9662 3.78181L16 3.78176ZM5.80548 9.64107L6.11024 8.0831L6.85539 8.82824C7.05856 9.03142 7.39735 9.03142 7.63443 8.82824C7.83761 8.62507 7.83761 8.2525 7.63443 8.0492L6.92319 7.33795H8.54895C9.59885 9.09919 7.36345 10.9958 5.80548 9.64104V9.64107Z"
            fill="#A3A4A8"
          ></path>
        </g>
        <defs>
          <clipPath id="clip0_19126_53593">
            <rect width="16" height="16" fill="white"></rect>
          </clipPath>
        </defs>
      </svg>
    </Tooltip>
  );
};
