import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card } from '../../components/UI/Card';
import { Plus } from 'phosphor-react';
import { PlayerSearchResult } from '../../models';
import { SearchV2 } from '../../components/search/SearchV2';
import { Avatar } from '../../components/UI/Avatar';
import { Combos } from '../../components/combos/Combos';
import { LoadingButton } from '../../components/UI/LoadingButton';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  align-items: center;
`;

const RoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  gap: 12px;
`;

const Title = styled.span`
  color: white;
  font-size: 24px;
  text-align: center;
`;

const PlayersContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
`;

const Player = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const PlayerTitle = styled.span`
  font-size: 14px;
  color: white;
`;

const PlayerCross = styled.div`
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 24px;
  color: white;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
`;

export const CombosPage = () => {
  const [civilians, setCivilians] = useState<PlayerSearchResult[]>([]);
  const [mafia, setMafia] = useState<PlayerSearchResult[]>([]);
  const [sheriff, setSheriff] = useState<PlayerSearchResult[]>([]);
  const [godfather, setGodfather] = useState<PlayerSearchResult[]>([]);

  const [civilianFilter, setCivilianFilter] = useState<number[]>([]);
  const [mafiaFilter, setMafiaFilter] = useState<number[]>([]);
  const [sheriffFilter, setSheriffFilter] = useState<number[]>([]);
  const [godfatherFilter, setGodfatherFilter] = useState<number[]>([]);

  const getIds = (
    arr1: PlayerSearchResult[],
    arr2: PlayerSearchResult[],
    arr3: PlayerSearchResult[]
  ): number[] => {
    const res = [
      ...arr1.map((a1) => a1.userId),
      ...arr2.map((a2) => a2.userId),
      ...arr3.map((a3) => a3.userId),
    ];

    return res;
  };

  useEffect(() => {
    setCivilianFilter(getIds(mafia, sheriff, godfather));
    setMafiaFilter(getIds(civilians, sheriff, godfather));
    setSheriffFilter(getIds(civilians, mafia, godfather));
    setGodfatherFilter(getIds(civilians, mafia, sheriff));
  }, [civilians, mafia, sheriff, godfather]);

  return (
    <Card>
      <Container>
        <SearchContainer
          name="Мирные"
          maxAmount={6}
          onSelectionChange={(players) => setCivilians(players)}
          playersFromOtherCards={civilianFilter}
        ></SearchContainer>
        <SearchContainer
          name="Мафия"
          maxAmount={2}
          onSelectionChange={(players) => setMafia(players)}
          playersFromOtherCards={mafiaFilter}
        ></SearchContainer>
        <SearchContainer
          name="Шериф"
          maxAmount={1}
          onSelectionChange={(players) => setSheriff(players)}
          playersFromOtherCards={sheriffFilter}
        ></SearchContainer>
        <SearchContainer
          name="Дон"
          maxAmount={1}
          onSelectionChange={(players) => setGodfather(players)}
          playersFromOtherCards={godfatherFilter}
        ></SearchContainer>
      </Container>

      {(civilians.length > 0 ||
        mafia.length > 0 ||
        sheriff.length > 0 ||
        godfather.length > 0) && (
        <Combos
          civilians={civilians}
          mafia={mafia}
          sheriff={sheriff}
          godfather={godfather}
        />
      )}
    </Card>
  );
};

interface Props {
  name: string;
  maxAmount: number;
  playersFromOtherCards: number[];
  onSelectionChange: (players: PlayerSearchResult[]) => void;
}

const SearchContainer: React.FC<Props> = (props) => {
  const [selectedPlayers, setSelectedPlayers] = useState<PlayerSearchResult[]>(
    []
  );
  const [showSearch, setShowSearch] = useState<boolean>(false);

  const [filter, setFilter] = useState<number[]>([]);

  const onResultSelected = (player: PlayerSearchResult) => {
    setSelectedPlayers((prev) => [...prev, player]);
    setShowSearch(false);
  };

  const removePlayer = (id: number) => {
    setSelectedPlayers((prev) => prev.filter((p) => p.userId !== id));
  };

  useEffect(() => {
    setFilter([
      ...selectedPlayers.map((p) => p.userId),
      ...props.playersFromOtherCards,
    ]);
  }, [props.playersFromOtherCards]);

  useEffect(() => {
    props.onSelectionChange(selectedPlayers);
  }, [selectedPlayers]);

  return (
    <RoleContainer>
      <Title>{props.name}</Title>
      <Card height={200}>
        <PlayersContainer>
          {selectedPlayers.map((player) => (
            <Player key={player.userId}>
              <PlayerCross onClick={() => removePlayer(player.userId)}>
                &times;
              </PlayerCross>
              <Avatar
                widthpx={40}
                heightpx={40}
                radiuspx="50%"
                avatarUrl={player.avatarUrl}
              ></Avatar>
              <PlayerTitle>{player.username}</PlayerTitle>
            </Player>
          ))}
          {selectedPlayers.length < props.maxAmount ? (
            showSearch ? (
              <SearchV2
                onResultSelected={onResultSelected}
                filteredPlayers={filter}
              />
            ) : (
              <Plus
                size={24}
                style={{ cursor: 'pointer', color: 'white' }}
                onClick={() => setShowSearch((prev) => !prev)}
              ></Plus>
            )
          ) : null}
        </PlayersContainer>
      </Card>
    </RoleContainer>
  );
};
