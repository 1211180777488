import React from 'react';
import { AdminTournament } from '../../models';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Plus } from 'phosphor-react';

interface Props {
  tournaments: AdminTournament[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-self: flex-end;
`;

const TournamentList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tournament = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  color: white;
  transition: 0.3s background-color;
  padding: 8px;
  align-items: center;

  &:hover {
    background-color: var(--red-accent-light);
    cursor: pointer;
  }
`;

const AddButton = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Tournaments: React.FC<Props> = (props) => {
  return (
    <Container>
      <TopBar>
        <AddButton to="/admin/tournaments/add">
          <Plus width={24} height={24} color="white" />
        </AddButton>
      </TopBar>
      <TournamentList>
        {props.tournaments.map((t) => (
          <Tournament key={t.id} to={`/admin/tournaments/${t.id}`}>
            <span>
              {t.name} ({t.startedAt.getFullYear()})
            </span>
          </Tournament>
        ))}
      </TournamentList>
    </Container>
  );
};
