import React, { ReactNode, useEffect, useState } from 'react';

interface TokenPair {
  accessToken: string;
  refreshToken: string;
}

interface Props {
  authData: TokenPair | null;
  setAuth: (access: string, refresh: string) => void;
  clear: () => void;
  children?: ReactNode;
}

export const AuthContext = React.createContext<Props>({
  authData: {
    accessToken: '',
    refreshToken: '',
  },
  setAuth: (access: string, refresh: string) => {},
  clear: () => {},
});

const getInitialState = (): TokenPair => {
  return {
    accessToken: localStorage.getItem('access_token') || '',
    refreshToken: localStorage.getItem('refresh_token') || '',
  };
};

export const AuthContextProvider = (props: any) => {
  const [auth, setAuth] = useState<TokenPair>(getInitialState());

  const setAuthHandler = (access: string, refresh: string) => {
    localStorage.setItem('access_token', access);
    localStorage.setItem('refresh_token', refresh);
    setAuth({
      accessToken: access,
      refreshToken: refresh,
    });
  };

  const value: Props = {
    authData: auth,
    setAuth: setAuthHandler,
    clear: () => {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      setAuth({
        accessToken: '',
        refreshToken: '',
      });
    },
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
};
