import React, { useState, useEffect } from 'react';
import { Leaderboard } from '../../components/leaderboard/Leaderboard';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { leaderboard } from '../../lib/api';
import { LeaderboardPlayer } from '../../models/leaderboard';
import { Error } from '../../components/UI/Error';
import styled from 'styled-components';
import { DescriptionCard } from '../../components/UI/Desc-Card';
import { Card } from '../../components/UI/Card';
import { FaqCardContainer, FaqContainer, FaqElement } from './Faq';

const Pre = styled.p`
  background-color: #333333;
  padding: 12px;
  border-radius: 4px;
`;

export const LeaderboardPage = () => {
  const [players, setPlayers] = useState<LeaderboardPlayer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    setLoading(true);
    const doRequest = async () => {
      try {
        const res = await leaderboard();
        setPlayers(res);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return (
    <>
      <Leaderboard
        players={players}
        title="Таблица лидеров"
        description="Таблица лидеров No Toxic"
      />
      <FaqContainer>
        <FaqCardContainer>
          <Card>
            <FaqElement>
              <DescriptionCard title="Как попасть в таблицу лидеров?">
                Для попадания в таблицу лидеров у вас должно быть сыграно не
                менее 60 игр в лобби No Toxic.
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="Я играю в лобби, но моей страницы нет на сайте. Что делать?">
                Продолжайте играть, и вас добавят вручную. Если этого долго не
                происходит - напишите в личные сообщения FloreaN'у.
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="Моя страница есть на сайте, но нет в лидерборде. Что делать?">
                Вы появитесь в лидерборде как только отыграете 60 игр в лобби.
                Продолжайте играть.
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="По какой формуле высчитываются общие винрейты?">
                Мы складываем все ваши победы, после чего делим полученный
                результат на общее количество игр. Это позволяет избежать
                погрешностей и некоторых ошибок.
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="Как часто обновляется таблица лидеров?">
                Каждый день в 6 утра (МСК)
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="No Toxic Points">
                <p>
                  NTP расчитывается для каждой сыгранной игры и добавляется (или
                  вычитается) к общему количеству по следующей формуле:
                </p>
                <br />
                <Pre>
                  ntp = (20 * (p*10))^ap + b[1] ... + b[n], где:
                  <br />
                  <br />
                  p - балл за игру
                  <br />
                  ap - средний балл
                  <br />
                  b - бонус за определенное действие в игре
                  <br />
                  <br />
                  В случае поражения формула меняется и работают следующие
                  условия:
                  <br />
                  При 0&le;p&lt;0.1:
                  <br />
                  &nbsp;&nbsp;ntp = -27 + b[1] ... + b[n]
                  <br />
                  <br />
                  При p&ge;0.1&lt;0.2:
                  <br />
                  &nbsp;&nbsp;ntp = -25 + b[1] ... + b[n]
                  <br />
                  <br />
                  При p&ge;0.2&lt;0.3:
                  <br />
                  &nbsp;&nbsp;ntp = -20 + b[1] ... + b[n]
                  <br />
                  <br />
                  При p&ge;0.3&lt;0.4:
                  <br />
                  &nbsp;&nbsp;ntp = -10 + b[1] ... + b[n]
                  <br />
                  <br />
                  При p &lt; 0:
                  <br />
                  &nbsp;&nbsp;ntp = -30 + b[1] ... + b[n]
                  <br />
                  <br />
                  Возможные бонусы за действия в игре:
                  <br />
                  &nbsp;&nbsp;Несострел: -20 * 0.2
                  <br />
                  <br />
                  &nbsp;&nbsp;Дисквалификация: -20 * 0.5
                  <br />
                  <br />
                  &nbsp;&nbsp;Нахождение шерифа в первую ночь доном: 20 * 0.4
                  <br />
                  <br />
                  &nbsp;&nbsp;Убийство шерифа в первую ночь доном: 20 * 0.8
                  <br />
                  <br />
                  &nbsp;&nbsp;Нахождение мафии в первую ночь шерифом: 20 * 0.2
                  <br />
                  <br />
                  &nbsp;&nbsp;Две черные проверки подряд шерифом: 20 * 0.6
                  <br />
                  <br />
                  &nbsp;&nbsp;Могут быть и другие значительные и не очень бонусы
                  <br />
                  <br />
                </Pre>
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="Смена аватара">
                Если вы в шоке от жадности полемики и хотите поменять аватар,
                можете написать лично Флореану и он поменяет ваш аватар на
                notoxic.pro (он останется прежним на полемике)
              </DescriptionCard>
            </FaqElement>
          </Card>
        </FaqCardContainer>
      </FaqContainer>
    </>
  );
};
