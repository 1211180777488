import React, { useEffect } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { NavLink, Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  background-color: var(--gray-accent2);
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const SiderbarItem = styled(NavLink)`
  padding: 12px;
  color: white;
  transition: 0.3s background-color;

  &:hover {
    cursor: pointer;
    background-color: var(--red-accent-light);
  }

  &.active {
    background-color: var(--red-accent-light);
  }

  &:first-child {
    border-top-left-radius: 12px;
  }
`;

const OutletWrapper = styled.div`
  background-color: var(--gray-accent);
  flex-grow: 1;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 12px;
`;

export const AdminPage = () => {
  return (
    <Container>
      <Sidebar>
        <SiderbarItem to="players">Игроки ⛹️‍♀️</SiderbarItem>
        <SiderbarItem to="tournaments">Турниры 📋</SiderbarItem>
        <SiderbarItem to="hof">Зал славы 🏆</SiderbarItem>
        <SiderbarItem to="bans">Баны 🍌</SiderbarItem>
      </Sidebar>
      <OutletWrapper>
        <Outlet></Outlet>
      </OutletWrapper>
    </Container>
  );
};
