import React from 'react';
import styled from 'styled-components';
import { NightStat, PlayerStat } from '../../models';
import { TitledCard } from './TitledCard';

interface Props {
  nightStats: NightStat;
  playerStats: PlayerStat;
}

const Container = styled.div`
  color: white;
`;

export const Night: React.FC<Props> = (props) => {
  const formatWithPercent = (
    text: string,
    number: number,
    games?: number
  ): string => {
    let percent = 0;
    if (number > 0 && games && games > 0) {
      percent = Math.round((number / games) * 100);
    }

    return `${text}: ${number} (${games ? games : 0} игр, ${percent}%)`;
  };
  return (
    <Container>
      <TitledCard title="Дон">
        <span>
          {formatWithPercent(
            'Шерифов убито в первую ночь',
            props.nightStats.sheriffsKilledAtFirstNight,
            props.playerStats.Godfather?.gamesPlayed
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Шерифов найдено в первую ночь',
            props.nightStats.sheriffsFoundAtFirstNight,
            props.playerStats.Godfather?.gamesPlayed
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Шерифов найдено и убито в первую ночь',
            props.nightStats.sheriffsFoundAndKilledAtFirstNight,
            props.playerStats.Godfather?.gamesPlayed
          )}
        </span>
      </TitledCard>
      <TitledCard title="Черная карта">
        <span>
          {formatWithPercent(
            'Промахов',
            props.nightStats.misses,
            (props.playerStats.Godfather
              ? props.playerStats.Godfather.gamesPlayed
              : 0) +
              (props.playerStats.Mafia
                ? props.playerStats.Mafia.gamesPlayed
                : 0)
          )}
        </span>
      </TitledCard>
      <TitledCard title="Шериф">
        <span>
          {formatWithPercent(
            'Черных проверок в первую ночь',
            props.nightStats.blackChecksAtFirstNight,
            props.playerStats.Sheriff?.gamesPlayed
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Две черные проверки подряд',
            props.nightStats.twoBlackChecksInARow,
            props.playerStats.Sheriff?.gamesPlayed
          )}
        </span>
      </TitledCard>
      <TitledCard title="Первый убиенный">
        <span>
          {formatWithPercent(
            'Мирный',
            props.nightStats.firstKilled.civilian,
            props.playerStats.Civilian?.gamesPlayed
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Черный',
            props.nightStats.firstKilled.mafia,
            props.playerStats.Mafia?.gamesPlayed
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Шериф',
            props.nightStats.firstKilled.sheriff,
            props.playerStats.Sheriff?.gamesPlayed
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Дон',
            props.nightStats.firstKilled.godfather,
            props.playerStats.Godfather?.gamesPlayed
          )}
        </span>
      </TitledCard>
    </Container>
  );
};
