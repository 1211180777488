import React from 'react';
import styled from 'styled-components';

interface Props {
  error: any;
}

const Container = styled.div`
  margin-top: 12px;
  text-align: center;
  color: var(--red-accent);
`;

export const Error: React.FC<Props> = (props) => {
  const errText = props.error.response?.data
    ? props.error.response.data.message
      ? props.error.response.data.message
      : props.error.response.data
    : 'Internal error';
  return <Container>Ошибка при загрузке данных: {errText}</Container>;
};
