export const prettyDate = (date: Date) => {
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
  ];

  return `${date.getDate()} ${months[date.getMonth()].slice(
    0,
    3
  )}. ${date.getFullYear()} г.`;
};

export const prettyDateDiff = (d1: Date, d2: Date) => {
  const minutes = (d2.getTime() / 1000 - d1.getTime() / 1000) / 60;
  if (minutes < 60) {
    return `${Math.round(minutes)} мин.`;
  }

  const hours = Math.round(minutes / 60);
  if (hours < 24) {
    return `${hours} ч.`;
  }

  return `${Math.round(hours / 24)} д.`;
};
