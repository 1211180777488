import axios from 'axios';
import {
  DayActivity,
  HourActivity,
  MonthActivity,
  Game,
  LeaderboardPlayer,
  Partner,
  Player,
  PlayerSearchResult,
  PlayerStat,
  Violation,
  ViolationType,
  LastActivity,
  SlotStat,
  NightStat,
  Tournament,
  ComboGameRequest,
  ComboGame,
  AccuracyStat,
  HallOfFame,
  HOFPlayer,
  Stats,
  LoginRequest,
  RegisterRequest,
  TokenPair,
  NtpHistory,
  Killers,
} from '../models/';
import { PaginatedResponse } from '../models/pagination';
import client, { BASE_URL } from './axios';

export const leaderboard = async () => {
  try {
    const resp = await client.get('/players/leaderboard');

    let res: LeaderboardPlayer[] = [];
    for (const item of resp.data) {
      res.push({
        userId: item['user_id'],
        username: item['username'],
        avatarUrl: item['avatar_url'],
        country: item['country'],
        civilWr: item['civil_wr'],
        mafiaWr: item['mafia_wr'],
        sheriffWr: item['sheriff_wr'],
        godfatherWr: item['godfather_wr'],
        wrTotal: item['wr_total'],
        avgPoints: item['avg_points'],
        totalGames: item['total_games'],
        rank: item['rank'],
        ntp: item['ntp'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const leaderboardMonthly = async () => {
  try {
    const resp = await client.get('/players/leaderboard/monthly');

    let res: LeaderboardPlayer[] = [];
    for (const item of resp.data) {
      res.push({
        userId: item['user_id'],
        username: item['username'],
        avatarUrl: item['avatar_url'],
        country: item['country'],
        civilWr: item['civil_wr'],
        mafiaWr: item['mafia_wr'],
        sheriffWr: item['sheriff_wr'],
        godfatherWr: item['godfather_wr'],
        wrTotal: item['wr_total'],
        avgPoints: item['avg_points'],
        totalGames: item['total_games'],
        rank: item['rank'],
        ntp: item['ntp'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const search = async (term: string) => {
  try {
    const resp = await client.get(`/players/search?term=${term}`);

    let res: PlayerSearchResult[] = [];
    for (const item of resp.data) {
      res.push({
        userId: item['user_id'],
        username: item['username'],
        avatarUrl: item['avatar_url'],
        country: item['country'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const info = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}`);
    const json = resp.data;

    let res: Player = {
      id: json['id'],
      username: json['username'],
      avatarUrl: json['avatar_url'],
      country: json['country'],
      registeredAt: new Date(json['registered_at']),
      firstPlayedAt: json['first_played_at']
        ? new Date(json['first_played_at'])
        : new Date(),
      lastPlayedAt: json['last_played_at']
        ? new Date(json['last_played_at'])
        : new Date(),
      rank: json['rank'] ? json['rank'] : 0,
      gamesWon: json['games_won'],
      gamesLost: json['games_lost'],
      hoursPlayed: json['hours_played'],
      badges: [],
      notorious: json['notorious'],
      ntp: json['ntp'] ? json['ntp'] : 0,
      stickers: json['stickers'],
    };

    for (const item of json['badges']) {
      res.badges.push({
        badge: item['badge'],
        title: item['title'],
        tournamentID: item['tournament_id'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const roleStats = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/roles`);
    const json = resp.data;

    let res: PlayerStat = {
      Civilian: json['civilian']
        ? {
            gamesPlayed: json['civilian']['games_played'],
            gamesWon: json['civilian']['games_won'],
          }
        : undefined,
      Mafia: json['mafia']
        ? {
            gamesPlayed: json['mafia']['games_played'],
            gamesWon: json['mafia']['games_won'],
          }
        : undefined,
      Sheriff: json['sheriff']
        ? {
            gamesPlayed: json['sheriff']['games_played'],
            gamesWon: json['sheriff']['games_won'],
          }
        : undefined,
      Godfather: json['godfather']
        ? {
            gamesPlayed: json['godfather']['games_played'],
            gamesWon: json['godfather']['games_won'],
          }
        : undefined,
    };

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const games = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/games`);
    const json = resp.data;

    let res: Game[] = [];

    for (const item of json) {
      res.push({
        userId: item['user_id'],
        username: item['username'],
        avatarUrl: item['avatar_url'],
        gameId: item['game_id'],
        startedAt: new Date(item['started_at']),
        endedAt: new Date(item['ended_at']),
        mode: item['mode'],
        role: item['role'],
        won: item['won'],
        slot: item['slot'],
        isMvp: item['is_mvp'],
        points: item['points'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchPartners = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/partners`);
    const json = resp.data;

    let res: Partner[] = [];

    for (const item of json) {
      res.push({
        userId: item['user_id'],
        username: item['username'],
        avatarUrl: item['avatar_url'],
        sameColor: {
          gamesPlayed: item['same_color'].played,
          gamesWon: item['same_color'].won,
        },
        diffColor: {
          gamesPlayed: item['diff_color'].played,
          gamesWon: item['diff_color'].won,
        },
        totalGames: item['total_games'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchDayActivity = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/activity/day`);
    const json = resp.data;

    let res: DayActivity[] = [];

    for (const item of json) {
      res.push({
        day: item['day'],
        played: item['played'],
        won: item['won'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchHourActivity = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/activity/hour`);
    const json = resp.data;

    let res: HourActivity[] = [];

    for (const item of json) {
      res.push({
        hour: item['hour'],
        played: item['played'],
        won: item['won'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchMonthActivity = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/activity/month`);
    const json = resp.data;

    let res: MonthActivity[] = [];

    for (const item of json) {
      res.push({
        month: item['month'],
        played: item['played'],
        won: item['won'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchViolations = async (
  violationType: ViolationType,
  userId?: number
) => {
  try {
    const resp = await client.get(
      `/violations?m=${violationType}${userId ? '&uid=' + userId : ''}`
    );
    const json = resp.data;

    let res: Violation[] = [];

    for (const item of json) {
      res.push({
        userId: item['user_id'],
        username: item['username'],
        avatarUrl: item['avatar_url'],
        id: item['id'],
        startsAt: new Date(item['starts_at']),
        endsAt:
          item['ends_at'] === '0001-01-01T00:00:00Z'
            ? undefined
            : new Date(item['ends_at']),
        isBan: item['is_ban'],
        reasons: item['reasons'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchGames = async (
  userId?: number,
  limit?: number,
  nextToken?: string
) => {
  try {
    const url = new URL(`${BASE_URL}/games`);

    const params: any = {};
    if (userId) {
      params['uid'] = userId;
    }
    if (limit) {
      params['limit'] = limit;
    }
    if (nextToken) {
      params['next_token'] = nextToken;
    }

    url.search = new URLSearchParams(params).toString();

    const resp = await client.get(url.toString());
    const json = resp.data;

    const items: Game[] = [];
    for (const item of json['items']) {
      items.push({
        userId: item['user_id'],
        username: item['username'],
        avatarUrl: item['avatar_url'],
        gameId: item['game_id'],
        startedAt: new Date(item['started_at']),
        endedAt: new Date(item['ended_at']),
        mode: item['mode'],
        role: item['role'],
        won: item['won'],
        slot: item['slot'],
        isMvp: item['is_mvp'],
        points: item['points'],
        ntp: item['ntp'] || 0,
      });
    }

    const res: PaginatedResponse<Game> = {
      prevToken: json['prev_token'],
      nextToken: json['next_token'],
      items: items,
    };

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchLastActivity = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/activity`);
    const json = resp.data;

    let res: LastActivity[] = [];

    for (const item of json) {
      res.push({
        dt: item['dt'],
        played: item['played'],
        won: item['won'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchSlotsStats = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/slots`);
    const json = resp.data;

    let res: SlotStat[] = [];

    for (const item of json) {
      res.push({
        slot: item['slot'],
        played: item['played'],
        won: item['won'],
        killedAtFirstNight: item['killed_at_first_night'],
        killedAtFirstNightPercent:
          item['killed_at_first_night'] > 0
            ? Math.round((item['killed_at_first_night'] / item['played']) * 100)
            : 0,
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchNightStats = async (id: number) => {
  try {
    const resp = await client.get(`/players/${id}/night`);
    const json = resp.data;

    let res: NightStat = {
      blackChecksAtFirstNight: json['black_check_at_first_night'],
      misses: json['misses'],
      sheriffsFoundAndKilledAtFirstNight:
        json['sheriffs_found_and_killed_at_first_night'],
      sheriffsFoundAtFirstNight: json['sheriffs_found_at_first_night'],
      sheriffsKilledAtFirstNight: json['sheriffs_killed_at_first_night'],
      twoBlackChecksInARow: json['two_black_checks_in_a_row'],
      firstKilled: json['first_killed'],
    };

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTournamentInfo = async (id: number) => {
  try {
    const resp = await client.get(`/tournaments/${id}`);
    const json = resp.data;

    let res: Tournament = {
      id: json['id'],
      name: json['name'],
      type: json['type'],
      startedAt: new Date(json['started_at']),
      endedAt: new Date(json['ended_at']),
      badge: json['badge'],
      description: json['description'],
      placements: [],
      links: json['links'],
    };

    for (const p of json['placements']) {
      res.placements.push({
        playerId: p['player_id'],
        avatarUrl: p['avatar_url'],
        placement: p['placement'],
        username: p['username'],
        points: p['points'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTournaments = async () => {
  try {
    const resp = await client.get(`/tournaments`);
    const json = resp.data;

    let res: Tournament[] = [];
    for (const item of json) {
      res.push({
        id: item['id'],
        name: item['name'],
        type: item['type'],
        startedAt: new Date(item['started_at']),
        endedAt: new Date(item['ended_at']),
        badge: item['badge'],
        description: item['description'],
        placements: [],
        links: item['links'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchComboGames = async (
  req: ComboGameRequest,
  nextToken: string
) => {
  try {
    let url = `${BASE_URL}/games/combo`;
    if (nextToken && nextToken !== '') {
      url += `?next_token=${nextToken}`;
    }

    const resp = await client.post(url, req);
    const json = resp.data;

    let items: ComboGame[] = [];
    for (const game of json['items']) {
      items.push({
        gameId: game['game_id'],
        startedAt: new Date(game['started_at']),
        endedAt: new Date(game['ended_at']),
      });
    }

    const res: PaginatedResponse<ComboGame> = {
      prevToken: json['prev_token'],
      nextToken: json['next_token'],
      items: items,
    };

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchAccuracy = async (userId: number) => {
  try {
    const resp = await client.get(`/players/${userId}/accuracy`);
    const json = resp.data;

    const res: AccuracyStat = {
      civilian: json['civilian'],
      mafia: json['mafia'],
      sheriff: json['sheriff'],
      godfather: json['godfather'],
    };

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const hallOfFame = async () => {
  try {
    const resp = await client.get(`/hof`);
    const json = resp.data;

    const parseArray = (json: any): HOFPlayer[] => {
      const res: HOFPlayer[] = [];
      for (const item of json) {
        const a: HOFPlayer = {
          description: item['description'],
          photoUrl: item['photo_url'],
          username: item['username'],
          country: item['country'] ? item['country'] : null,
          playerId: item['player_id'],
          badges: [],
        };
        for (const badge of item['badges']) {
          a.badges.push({
            name: badge['name'],
            text: badge['text'],
            tournamentId: badge['tournament_id'],
          });
        }
        res.push(a);
      }

      return res;
    };

    const res: HallOfFame = {
      allStars: json['all_stars'] && parseArray(json['all_stars']),
      major: json['major'] && parseArray(json['major']),
      minor: json['minor'] && parseArray(json['minor']),
    };

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchStats = async (userId: number) => {
  try {
    const resp = await client.get(`/players/${userId}/stats`);
    const json = resp.data;

    const res: Stats = {
      accuracy: {
        civilian: json['accuracy']['civilian'],
        mafia: json['accuracy']['mafia'],
        sheriff: json['accuracy']['sheriff'],
        godfather: json['accuracy']['godfather'],
      },
      guesses: {
        total: json['guesses']['total'],
        win: json['guesses']['win'],
      },
      bestSeries: {
        civilian: json['best_series']['civilian'],
        mafia: json['best_series']['mafia'],
        sheriff: json['best_series']['sheriff'],
        godfather: json['best_series']['godfather'],
      },
      bestMoves: {
        zeroOfThree: json['best_moves']['zero_of_three'],
        oneOfThree: json['best_moves']['one_of_three'],
        twoOfThree: json['best_moves']['two_of_three'],
        threeOfThree: json['best_moves']['three_of_three'],
      },
      mvp: json['mvp'],
      leaves: json['leaves'],
    };

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const login = async (req: LoginRequest): Promise<TokenPair> => {
  try {
    const resp = await client.post('/auth/login', req);
    const res: TokenPair = {
      access: resp.data['access_token'],
      refresh: resp.data['refresh_token'],
    };

    return res;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const register = async (req: RegisterRequest) => {
  try {
    await client.post('/auth/register', req);
  } catch (err) {
    return Promise.reject(err);
  }
};

export const ntpHistory = async (userId: number) => {
  try {
    const resp = await client.get(`/players/${userId}/ntp_history`);
    const data = resp.data;
    const res: NtpHistory[] = [];
    for (const item of data) {
      res.push({
        dt: new Date(item['dt']),
        ntp: item['ntp'],
      });
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const killers = async (userId: number) => {
  try {
    const resp = await client.get(`/players/${userId}/killers`);
    const data = resp.data;
    const res: Killers = {
      topKillers: [],
      topKilled: [],
    };
    if (data['killers']) {
      for (const item of data['killers']) {
        res.topKillers.push({
          userId: item['user_id'],
          username: item['username'],
          avatarUrl: item['avatar_url'],
          kills: item['kills'],
        });
      }
    }
    if (data['killed']) {
      for (const item of data['killed']) {
        res.topKilled.push({
          userId: item['user_id'],
          username: item['username'],
          avatarUrl: item['avatar_url'],
          kills: item['kills'],
        });
      }
    }

    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
