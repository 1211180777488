import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL: BASE_URL,
  timeout: 5000,
  headers: {
    'Accept-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  withCredentials: false,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
  headers: {
    'Accept-Timezone': Intl.DateTimeFormat().resolvedOptions().timeZone,
  },
  withCredentials: false,
});
