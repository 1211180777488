import React from 'react';
import { PlayerStat, Stats as PlayerStats } from '../../models';
import styled from 'styled-components';
import { TitledCard } from '../profile/TitledCard';

interface Props {
  stats: PlayerStats;
  roles: PlayerStat;
}

const Container = styled.div`
  color: white;
`;

export const Stats: React.FC<Props> = (props) => {
  const formatWithPercent = (
    text: string,
    number: number,
    games?: number,
    customName?: string
  ): string => {
    let percent = 0;
    if (number > 0 && games && games > 0) {
      percent = Math.round((number / games) * 100);
    }

    return `${text}: ${number} (${games ? games : 0} ${
      customName ? customName : 'игр'
    }, ${percent}%)`;
  };

  const formatBestMove = (text: string, value: number, percent: number) => {
    const total = Math.round(value / (percent / 100)) || 0;
    return `${text}: ${value} (${total} голосований, ${percent}%)`;
  };

  const totalGames =
    (props.roles?.Civilian?.gamesPlayed || 0) +
    (props.roles?.Mafia?.gamesPlayed || 0) +
    (props.roles?.Sheriff?.gamesPlayed || 0) +
    (props.roles?.Godfather?.gamesPlayed || 0);

  return (
    <Container>
      <TitledCard title="Точность голосования">
        <span>
          {formatWithPercent(
            'Мирный',
            props.stats.accuracy.civilian?.positive || 0,
            props.stats.accuracy.civilian?.total || 0,
            'голосований'
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Мафия',
            props.stats.accuracy.mafia?.positive || 0,
            props.stats.accuracy.mafia?.total || 0,
            'голосований'
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Шериф',
            props.stats.accuracy.sheriff?.positive || 0,
            props.stats.accuracy.sheriff?.total || 0,
            'голосований'
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Дон',
            props.stats.accuracy.godfather?.positive || 0,
            props.stats.accuracy.godfather?.total || 0,
            'голосований'
          )}
        </span>
      </TitledCard>
      <TitledCard title="Серия побед">
        <span>Мирный: {props.stats.bestSeries.civilian}</span>
        <br />
        <span>Мафия: {props.stats.bestSeries.mafia}</span>
        <br />
        <span>Шериф: {props.stats.bestSeries.sheriff}</span>
        <br />
        <span>Дон: {props.stats.bestSeries.godfather}</span>
      </TitledCard>
      <TitledCard title="Лучший ход">
        <span>
          {formatBestMove(
            '0 из 3',
            props.stats.bestMoves.zeroOfThree.value,
            props.stats.bestMoves.zeroOfThree.percent
          )}
        </span>
        <br />
        <span>
          {formatBestMove(
            '1 из 3',
            props.stats.bestMoves.oneOfThree.value,
            props.stats.bestMoves.oneOfThree.percent
          )}
        </span>
        <br />
        <span>
          {formatBestMove(
            '2 из 3',
            props.stats.bestMoves.twoOfThree.value,
            props.stats.bestMoves.twoOfThree.percent
          )}
        </span>
        <br />
        <span>
          {formatBestMove(
            '3 из 3',
            props.stats.bestMoves.threeOfThree.value,
            props.stats.bestMoves.threeOfThree.percent
          )}
        </span>
      </TitledCard>
      <TitledCard title="МВП">
        <span>
          {formatWithPercent(
            'Мирный',
            props.stats.mvp.civilian || 0,
            props.roles.Civilian?.gamesPlayed || 0
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Мафия',
            props.stats.mvp.mafia || 0,
            props.roles.Mafia?.gamesPlayed || 0
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Шериф',
            props.stats.mvp.sheriff || 0,
            props.roles.Sheriff?.gamesPlayed || 0
          )}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Дон',
            props.stats.mvp.godfather || 0,
            props.roles.Godfather?.gamesPlayed || 0
          )}
        </span>
      </TitledCard>
      <TitledCard title="Угадайки">
        <span>
          {formatWithPercent('Дожил', props.stats.guesses.total, totalGames)}
        </span>
        <br />
        <span>
          {formatWithPercent(
            'Выиграл',
            props.stats.guesses.win,
            props.stats.guesses.total
          )}
        </span>
      </TitledCard>
      <TitledCard title="Другое">
        <span>
          {formatWithPercent('Дисквалификаций', props.stats.leaves, totalGames)}
        </span>
      </TitledCard>
    </Container>
  );
};
