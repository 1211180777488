import React from 'react';
import { SlotStat } from '../../models';
import { Card } from '../UI/Card';
import { Column, Row, Table } from '../UI/Table';

interface Props {
  slots: SlotStat[];
}

export const Slots: React.FC<Props> = (props) => {
  return (
    <Card>
      <Table>
        <thead>
          <tr>
            <th>Слот</th>
            <th>Игр</th>
            <th>Побед</th>
            <th>% побед</th>
            <th>П.У.</th>
          </tr>
        </thead>
        <tbody>
          {props.slots.map((slot) => (
            <Row key={slot.slot}>
              <Column>{slot.slot}</Column>
              <Column>{slot.played > 0 ? slot.played : '-'}</Column>
              <Column>{slot.won > 0 ? slot.won : '-'}</Column>
              <Column>
                {slot.won > 0
                  ? Math.round((slot.won / slot.played) * 100)
                  : '-'}
              </Column>
              <Column>
                {slot.killedAtFirstNight > 0
                  ? slot.killedAtFirstNight +
                    ' ' +
                    '(' +
                    slot.killedAtFirstNightPercent +
                    '%)'
                  : '-'}
              </Column>
            </Row>
          ))}
        </tbody>
      </Table>
    </Card>
  );
  return <div>Slots</div>;
};
