import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

interface Props {
  children: any;
  title: string;
  onClose?: () => void;
  notCentered?: boolean;
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalContainer = styled.div<{ notCentered?: boolean }>`
  position: fixed;
  min-width: 500px;
  overflow: hidden;
  top: ${(props) => (props.notCentered ? null : '30%')};
`;

const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  background-color: var(--red-accent-light);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  span {
    color: white;
    margin-left: 12px;
    font-size: 18px;
  }
`;

const ModalBody = styled.div`
  padding: 12px;
  background-color: var(--black-accent);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const Cross = styled.span`
  margin-right: 12px;
  user-select: none;
  font-size: 24px;

  &:hover {
    cursor: pointer;
  }
`;

export const Modal: React.FC<Props> = (props) => {
  const element = document.getElementById('overlay');
  return (
    <>
      {ReactDOM.createPortal(
        <Backdrop onClick={props.onClose}>
          <ModalContainer
            onClick={(e) => e.stopPropagation()}
            notCentered={props.notCentered}
          >
            <ModalTitle>
              <span>{props.title}</span>
              <Cross onClick={props.onClose}>&times;</Cross>
            </ModalTitle>
            <ModalBody>{props.children}</ModalBody>
          </ModalContainer>
        </Backdrop>,
        element!
      )}
      ;
    </>
  );
};
