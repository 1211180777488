import React from 'react';
import { LeaderboardPlayer } from '../../models/leaderboard';
import { LeaderboardItem } from './LeaderboardItem';
import { DescriptionCard } from '../UI/Desc-Card';
import { Card } from '../UI/Card';
import styled from 'styled-components';
import { CEO } from '../CEO/CEO';

const Table = styled.table`
  width: 100%;
  color: white;
  text-align: center;
  border-collapse: separate;
  border-spacing: 0 8px;
  border-radius: 8px;

  tbody tr {
    transition: background-color 0.3s;
  }

  tbody tr:hover {
    background-color: var(--red-accent-light);
  }

  td:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  td:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
`;

const Heading = styled.th`
  padding: 12px 6px;
  /* opacity: 0.7; */
  font-size: 14px;
  white-space: nowrap;
  /* color: var(--red-accent); */
  color: white;
  /* border: 1px solid var(--red-accent);
  border-radius: 24px; */

  &.name {
    width: 100%;
  }
`;

const Column = styled.tr`
  background-color: var(--gray-accent);
`;

const BorderedSpan = styled.span`
  border: 2px solid var(--red-accent);
  border-radius: 12px;
  padding: 8px;
`;

const FaqContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
`;

const FaqCardContainer = styled.div`
  margin-top: 24px;
  width: 80%;
`;

const FaqElement = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

interface Props {
  players: LeaderboardPlayer[];
  title: string;
  description: string;
}

export const Leaderboard: React.FC<Props> = (props) => {
  return (
    <>
      <CEO title="Таблица лидеров" description="Таблица лидеров No Toxic" />
      <Card>
        <Table>
          <thead>
            <tr>
              <Heading></Heading>
              <Heading className="name"></Heading>
              <Heading>
                <BorderedSpan>total games</BorderedSpan>
              </Heading>
              <Heading>
                <BorderedSpan>civilian wr%</BorderedSpan>
              </Heading>
              <Heading>
                <BorderedSpan>mafia wr%</BorderedSpan>
              </Heading>
              <Heading>
                <BorderedSpan>sheriff wr%</BorderedSpan>
              </Heading>
              <Heading>
                <BorderedSpan>godfather wr%</BorderedSpan>
              </Heading>
              <Heading>
                <BorderedSpan>total wr%</BorderedSpan>
              </Heading>
              <Heading>
                <BorderedSpan>avg points</BorderedSpan>
              </Heading>
              <Heading>
                <BorderedSpan>ntpoints</BorderedSpan>
              </Heading>
            </tr>
          </thead>
          <tbody>
            {props.players.map((player, idx) => (
              <Column key={player.userId}>
                <LeaderboardItem player={player} />
              </Column>
            ))}
          </tbody>
        </Table>
      </Card>
    </>
  );
};
