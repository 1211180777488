export interface Violation {
  userId: number;
  username: string;
  avatarUrl?: string;
  id: number;
  startsAt: Date;
  endsAt?: Date;
  isBan: boolean;
  reasons: string[];
}

export type ViolationType = 0 | 1 | 2;

export const VIOLATION_TYPE_TEMP: ViolationType = 0;
export const VIOLATION_TYPE_CURRENT: ViolationType = 1;
export const VIOLATION_TYPE_EXPIRED: ViolationType = 2;
