import React, { ReactNode, useState } from 'react';
import styled from 'styled-components';

interface Props {
  title: string;
  children?: ReactNode;
}

const Title = styled.div`
  background-color: var(--red-accent-light);
  padding: 12px;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  font-size: 18px;

  &:hover {
    cursor: pointer;
  }
`;

const Body = styled.div`
  padding: 12px;
  background-color: var(--gray-accent);
  color: white;
`;

export const DescriptionCard: React.FC<Props> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const openHandler = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div>
      <Title onClick={openHandler}>{props.title}</Title>
      {isOpen && <Body>{props.children}</Body>}
    </div>
  );
};
