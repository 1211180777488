import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { useParams } from 'react-router-dom';
import { AdminHofPlayer } from '../../models';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';
import { EditHOFPlayer } from '../../components/admin/EditHOFPlayer';

export const EditHOFPlayerPage = () => {
  const axios = useAxiosPrivate();
  const params = useParams();
  const playerId = params.playerId!;
  const [player, setPlayer] = useState<AdminHofPlayer>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    const doRequest = async () => {
      try {
        const resp = await axios.get(`/admin/hof/${playerId}`);
        const data = resp.data;
        setPlayer({
          id: data['id'],
          description: data['description'],
          username: data['username'],
          photoUrl: data['photo_url'],
        });
      } catch (error) {
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <EditHOFPlayer player={player!} />;
};
