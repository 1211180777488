import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { HallOfFame as hofModel } from '../../models';
import { hallOfFame } from '../../lib/api';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { HallOfFame } from '../../components/hof/HallOfFame';
import { Error } from '../../components/UI/Error';

const BookContainer = styled.div`
  position: absolute;
  margin-top: -90px;
  width: 1600px;
  height: 800px;
  background-color: transparent;
  z-index: -1;
`;

const Book = styled.img`
  position: absolute;
  margin-top: -90px;
  z-index: -1;
`;

const HallOfFameText = styled.span`
  font-family: 'Kameron';
  font-size: 200px;
  color: #f6f6f6;
  text-shadow: 5px 8px 0px #f06868;
  user-select: none;
  font-weight: bold;
  line-height: 150px;
`;

const TextContainer = styled.div`
  margin-top: 150px;
  margin-left: 100px;
  width: 50%;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 620px;
`;

const TitleBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: var(--red-accent);
  color: white;
  height: 80px;
  align-items: center;

  span {
    font-size: 24px;
  }
`;

const TitleTextContainer = styled.div`
  display: flex;
  justify-content: center;
  color: white;
  margin-top: 24px;
`;

const TitleTextContent = styled.div`
  width: 30%;
  text-align: center;
`;

export const HallOfFamePage = () => {
  const [hof, setHof] = useState<hofModel>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await hallOfFame();
        setHof(resp);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return (
    <>
      <BookContainer>
        <Book src="https://a.notoxic.pro/hof/book.png"></Book>
        <TextContainer>
          <HallOfFameText>HALL OF FAME</HallOfFameText>
        </TextContainer>
      </BookContainer>
      <MainContainer>
        <TitleBar>
          <span>Победители турниров года</span>
          <span>Победители мажоров</span>
          <span>Победители миноров</span>
        </TitleBar>
        <TitleTextContainer>
          <TitleTextContent>
            <span style={{ fontSize: '16px' }}>
              ПОБЕДИТЕЛИ ТУРНИРОВ NO TOXIC
            </span>
            <br></br>
            <span style={{ fontSize: '60px', fontWeight: 'bold' }}>ЗАЛ</span>
            <br></br>
            <span style={{ fontSize: '60px', fontWeight: 'bold' }}>СЛАВЫ</span>
            <br></br>
            <span style={{ fontSize: '20px', fontWeight: 'lighter' }}>
              На этой странице вы увидите настоящих легенд нашего сообщества,
              которые успели доказать свое право находиться здесь, выиграв
              турниры под эгидой No Toxic Community
            </span>
          </TitleTextContent>
        </TitleTextContainer>

        <HallOfFame hof={hof!}></HallOfFame>
      </MainContainer>
    </>
  );
};
