import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchTournamentInfo } from '../lib/api';
import { Tournament } from '../models';
import { LoadingSpinner } from '../components/UI/LoadingSpinner';
import { TournamentInfo } from '../components/tournaments/Tournament';
import { Error } from '../components/UI/Error';

export const TournamentsPage = () => {
  const [tournament, setTournament] = useState<Tournament>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await fetchTournamentInfo(+params.tournamentId!);
        setTournament(resp);
        setLoading(false);
      } catch (error: any) {
        if (error.response?.status === 404) {
          navigate('/notfound', { replace: true });
          return;
        }
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [params.tournamentId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <TournamentInfo tournament={tournament!} />;
};
