import styled from 'styled-components';

export const FaqContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: white;
`;

export const FaqCardContainer = styled.div`
  margin-top: 24px;
  width: 80%;
`;

export const FaqElement = styled.div`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;
