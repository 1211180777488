import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
  color: white;
`;

export const NoContent: React.FC<{ text: string }> = (props) => {
  return (
    <Container>
      <span>{props.text}</span>
    </Container>
  );
};
