import React from 'react';
import styled from 'styled-components';

interface Props {
  placeholder: string;
  type?: string;
  onChange?: (data: string) => void;
  value?: string;
}

const InputContainer = styled.input`
  outline: none;
  border: none;
  padding: 8px 16px;
  background-color: var(--gray-accent2);
  color: white;
  border-radius: 24px;
`;

export const Input: React.FC<Props> = (props) => {
  return (
    <InputContainer
      placeholder={props.placeholder}
      type={props.type ? props.type : 'text'}
      onChange={(e) => (props.onChange ? props.onChange(e.target.value) : {})}
      value={props.value}
    />
  );
};
