import React from 'react';
import styled from 'styled-components';
import { NavLink as BaseNavLink } from 'react-router-dom';
import { Outlet } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const NavigationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;
`;

const Navigation = styled.div`
  width: 30%;
  display: flex;
  gap: 4px;
`;

const NavLink = styled(BaseNavLink)`
  padding: 8px;
  color: white;
  text-align: center;
  background-color: var(--gray-accent);
  width: 100%;
  /* margin-right: 4px; */
  transition: background-color 0.3s;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--red-accent-light);
  }

  &.active {
    background-color: var(--red-accent-light);
  }
`;

export const LeaderboardContainer = () => {
  return (
    <Container>
      <NavigationContainer>
        <Navigation>
          <NavLink to="top">За все время</NavLink>
          <NavLink to="monthly">Текущий месяц</NavLink>
        </Navigation>
      </NavigationContainer>
      <Outlet />
    </Container>
  );
};
