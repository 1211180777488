import { Tooltip } from '../components/UI/Tooltip';

export const Civilian = () => {
  return (
    <Tooltip text="Мирный житель">
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 14.2266C8 14.2266 1.75 10.7266 1.75 6.47656C1.75013 5.7254 2.01041 4.99746 2.48659 4.41651C2.96278 3.83557 3.62547 3.43747 4.362 3.28991C5.0985 3.14234 5.86345 3.25442 6.52665 3.60709C7.1899 3.95975 7.7105 4.53124 8 5.22438V5.22439C8.2895 4.53124 8.8101 3.95976 9.47335 3.60709C10.1366 3.25442 10.9015 3.14234 11.638 3.28991C12.3746 3.43747 13.0373 3.83557 13.5134 4.41651C13.9896 4.99746 14.2499 5.7254 14.25 6.47656C14.25 10.7266 8 14.2266 8 14.2266Z"
          fill="#A3A4A8"
        ></path>
      </svg>
    </Tooltip>
  );
};
