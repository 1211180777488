import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { fetchNightStats, roleStats } from '../../lib/api';
import { NightStat, PlayerStat } from '../../models';
import { Night } from '../../components/profile/Night';
import { Error } from '../../components/UI/Error';

export const NightPage = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [nightStats, setNightStats] = useState<NightStat>();
  const [playerStats, setPlayerStats] = useState<PlayerStat>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const [nightStatsResp, playerStatsResp] = await Promise.all([
          fetchNightStats(playerId),
          roleStats(playerId),
        ]);
        setNightStats(nightStatsResp);
        setPlayerStats(playerStatsResp);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Night nightStats={nightStats!} playerStats={playerStats!} />;
};
