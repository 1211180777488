import axios from '../lib/axios';
import useAuth from './useAuth';

export const useRefreshToken = () => {
  const auth = useAuth();

  const refresh = async () => {
    const response = await axios.post(
      '/auth/refresh',
      {
        access_token: auth.authData?.accessToken,
        refresh_token: auth.authData?.refreshToken,
      },
      {
        headers: {
          Authorization: `Bearer ${auth.authData?.accessToken}`,
        },
      }
    );
    auth.setAuth(response.data['access_token'], response.data['refresh_token']);
    return {
      accessToken: response.data['access_token'],
      refreshToken: response.data['refresh_token'],
    };
  };

  return refresh;
};

export default useRefreshToken;
