import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Killers as killerStat } from '../../models';
import { killers } from '../../lib/api';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';
import { Killers } from '../../components/profile/Killers';

export const KillersPage = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [killersStats, setKillersStats] = useState<killerStat>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await killers(playerId);
        setKillersStats(resp);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Killers killers={killersStats!} />;
};
