import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '../UI/Avatar';
import { AdminHofPlayer, PlayerSearchResult } from '../../models';
import { TextArea } from '../UI/TextArea';
import { LoadingButton } from '../UI/LoadingButton';
import { Pencil } from 'phosphor-react';
import { SearchV2 } from '../search/SearchV2';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Error } from '../UI/Error';
import { Success } from '../UI/Success';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  justify-content: center;
`;

const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 12px;
  transition: 0.3s background-color, color;
  color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    cursor: pointer;
  }
`;

export const HOFAdd = () => {
  const inputRef = useRef<any>(null);
  const [imgSrc, setImgSrc] = useState<string>('');
  const [imageBlob, setImageBlob] = useState<Blob>();
  const [player, setPlayer] = useState<PlayerSearchResult>();
  const [description, setDescription] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const axios = useAxiosPrivate();

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const res = reader.result?.toString() || '';
        setImgSrc(res);
        fetch(res)
          .then((res) => res.blob())
          .then(setImageBlob);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const overlayClicked = () => {
    inputRef.current!.click();
  };

  const selectPlayer = (player: PlayerSearchResult) => {
    setPlayer(player);
  };

  const onDescriptionChange = (str: string) => {
    setDescription(str);
  };

  const buttonClicked = () => {
    if (description === '' || !player || !imageBlob) return;

    setLoading(true);
    setError(undefined);
    setSuccess(false);

    const doRequest = async () => {
      try {
        await axios.postForm('/admin/hof', {
          id: player?.userId,
          description: description,
          photo: imageBlob,
        });
        setSuccess(true);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  };

  return (
    <Container>
      <input
        type="file"
        onChange={(e) => handleFileSelected(e)}
        ref={inputRef}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png,.gif"
      ></input>
      {player ? (
        <span style={{ color: 'white', fontSize: '24px' }}>
          {player.username}
        </span>
      ) : (
        <SearchV2 onResultSelected={selectPlayer} placeholder="Игрок" />
      )}
      <Avatar heightpx={200} widthpx={200} radiuspx="12px" avatarUrl={imgSrc}>
        <AvatarOverlay onClick={overlayClicked}>
          <Pencil height={48} width={48} />
          <span>Изменить фотографию</span>
        </AvatarOverlay>
      </Avatar>
      <TextArea placeholder="Описание" onChange={onDescriptionChange} />
      <LoadingButton
        isLoading={loading}
        text="Сохранить"
        onClick={buttonClicked}
      />
      {error && <Error error={error} />}
      {success && <Success text="Игрок добавлен" />}
    </Container>
  );
};
