import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { fetchViolations } from '../../lib/api';
import { Violation, VIOLATION_TYPE_CURRENT } from '../../models';
import { Bans } from '../../components/bans/Bans';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';

export const CurrentBans = () => {
  const params = useParams();
  const userId = params.playerId ? +params.playerId : undefined;

  const [violations, setViolations] = useState<Violation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await fetchViolations(VIOLATION_TYPE_CURRENT, userId);
        setViolations(resp);
        setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [userId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Bans violations={violations} forUser={!!userId} />;
};
