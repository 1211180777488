import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlayerStat, Stats } from '../../models';
import { fetchStats, roleStats } from '../../lib/api';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Stats as StatsComp } from '../../components/stats/Stats';
import { Error } from '../../components/UI/Error';

export const StatsPage = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [stats, setStats] = useState<Stats>();
  const [roles, setRoles] = useState<PlayerStat>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const [statsResp, rolesResp] = await Promise.all([
          fetchStats(playerId),
          roleStats(playerId),
        ]);
        setStats(statsResp);
        setRoles(rolesResp);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <StatsComp stats={stats!} roles={roles!} />;
};
