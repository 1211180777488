import React from 'react';
import useAuth from '../hooks/useAuth';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

export const RouteGuard = () => {
  const auth = useAuth();
  const location = useLocation();
  const pathName = location.pathname;

  const isValid = (): boolean => {
    if (auth.authData === null) {
      return false;
    }

    if (auth.authData.accessToken === '' || auth.authData.refreshToken === '')
      return false;

    return true;
  };

  if (isValid() && pathName.includes('/auth'))
    return <Navigate to="/admin" replace />;
  if (!isValid() && pathName.includes('/admin'))
    return <Navigate to="/auth" replace />;

  return <Outlet></Outlet>;
};
