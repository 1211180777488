import React from 'react';
import { Partner } from '../../models/partner';
import defaultavatar from '../../assets/img/defaultavatar.png';
import { Card } from '../UI/Card';
import classes from './Partners.module.css';
import { Link } from 'react-router-dom';
import { Table, Row, Column } from '../UI/Table';
import { Avatar } from '../UI/Avatar';

interface Props {
  partners: Partner[];
}

export const Partners: React.FC<Props> = (props) => {
  return (
    <Card>
      <Table>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}>Игрок</th>
            <th>Всего игр</th>
            <th>Одноцвет</th>
            <th>% побед в одноцвете</th>
            <th>Разноцвет</th>
            <th>% побед в разноцвете</th>
          </tr>
        </thead>
        <tbody>
          {props.partners.map((partner) => (
            <Row key={partner.userId}>
              <Column>
                <div className={classes['user-block']}>
                  <Avatar
                    widthpx={40}
                    heightpx={40}
                    radiuspx="50%"
                    avatarUrl={partner.avatarUrl}
                  ></Avatar>
                  <Link
                    to={`/players/${partner.userId}`}
                    className={classes.username}
                  >
                    {partner.username}
                  </Link>
                </div>
              </Column>
              <Column>{partner.totalGames}</Column>
              <Column>
                {partner.sameColor.gamesPlayed
                  ? partner.sameColor.gamesPlayed
                  : '-'}
              </Column>
              <Column>
                {partner.sameColor.gamesWon
                  ? (
                      (partner.sameColor.gamesWon /
                        partner.sameColor.gamesPlayed) *
                      100
                    ).toFixed(1)
                  : '-'}
              </Column>
              <Column>
                {partner.diffColor.gamesPlayed
                  ? partner.diffColor.gamesPlayed
                  : '-'}
              </Column>
              <Column>
                {partner.diffColor.gamesWon
                  ? (
                      (partner.diffColor.gamesWon /
                        partner.diffColor.gamesPlayed) *
                      100
                    ).toFixed(1)
                  : '-'}
              </Column>
            </Row>
          ))}
        </tbody>
      </Table>
    </Card>
  );
};
