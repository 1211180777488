import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  roleStats,
  fetchGames,
  fetchLastActivity,
  ntpHistory,
} from '../../lib/api';
import { PlayerStat } from '../../models/stats';
import { Game } from '../../models/game';
import { Overview as ProfileOveriew } from '../../components/profile/Overview';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { LastActivity, NtpHistory } from '../../models';
import { Error } from '../../components/UI/Error';

export const Overview = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [stats, setStats] = useState<PlayerStat>();
  const [games, setGames] = useState<Game[]>([]);
  const [lastActivity, setLastActivity] = useState<LastActivity[]>([]);
  const [ntp, setNtp] = useState<NtpHistory[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const [statsResp, gamesResp, activityResp, ntpHistoryResp] =
          await Promise.all([
            roleStats(playerId),
            fetchGames(playerId, 10),
            fetchLastActivity(playerId),
            ntpHistory(playerId),
          ]);
        setStats(statsResp);
        setGames(gamesResp.items);
        setLastActivity(activityResp);
        setNtp(ntpHistoryResp);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return (
    <ProfileOveriew
      stats={stats!}
      games={games!}
      activity={lastActivity!}
      ntpHistory={ntp!}
    />
  );
};
