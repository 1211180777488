import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  fetchDayActivity,
  fetchHourActivity,
  fetchMonthActivity,
} from '../../lib/api';
import {
  DayActivity,
  HourActivity,
  MonthActivity,
} from '../../models/activity';
import { Activity } from '../../components/profile/Activity';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';

export const ActivityPage = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [days, setDays] = useState<DayActivity[]>([]);
  const [hours, setHours] = useState<HourActivity[]>([]);
  const [months, setMonths] = useState<MonthActivity[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const [daysResp, hoursResp, monthsResp] = await Promise.all([
          fetchDayActivity(playerId),
          fetchHourActivity(playerId),
          fetchMonthActivity(playerId),
        ]);
        setDays(daysResp);
        setHours(hoursResp);
        setMonths(monthsResp);
        setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Activity days={days} hours={hours} months={months} />;
};
