import React, { useState } from 'react';
import { AdminBanReason, Violation } from '../../models';
import styled from 'styled-components';
import { Column, Row, Table } from '../UI/Table';
import { prettyDate } from '../../lib/date';
import { Modal } from '../UI/Modal';
import { Minus } from 'phosphor-react';
import Select from 'react-select';
import { LoadingButton } from '../UI/LoadingButton';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Error } from '../UI/Error';
import { Success } from '../UI/Success';

interface Props {
  current: Violation[];
  expired: Violation[];
  citations: Violation[];
  reasons: AdminBanReason[];
  playerId: number;
}

const ShowModalSpan = styled.span`
  color: var(--red-accent);
  transition: color 0.3s;

  &:hover {
    color: white;
    cursor: pointer;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;

  span {
    color: white;
    text-align: center;
  }
`;

const TopBar = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  span {
    color: white;
  }
`;

export const EditBans: React.FC<Props> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const [modalItems, setModalItems] = useState<string[]>([]);
  const [selectedReasons, setSelectedReasons] = useState<number[]>([]);
  const [selectedReasonsStr, setSelectedReasonsStr] = useState<string[]>([]);
  const [isCitation, setIsCitation] = useState<boolean>(false);
  const [isPermanent, setIsPermanent] = useState<boolean>(false);
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>('');
  const [current, setCurrent] = useState<Violation[]>(props.current);
  const [expired, setExpired] = useState<Violation[]>(props.expired);
  const [citations, setCitations] = useState<Violation[]>(props.citations);
  const axios = useAxiosPrivate();

  const selectOptions = props.reasons.map((r) => {
    const d = {
      value: r.id,
      label: r.body,
      color: '#000000',
    };
    return d;
  });

  const onSelectChange = (reasons: any) => {
    setSelectedReasons(reasons.map((r: any) => r['value']));
    setSelectedReasonsStr(reasons.map((r: any) => r['label']));
  };

  const handleDelete = async (
    id: number,
    setter: React.Dispatch<React.SetStateAction<Violation[]>>
  ) => {
    setLoading(true);
    setError(undefined);
    setSuccess(false);

    try {
      await axios.delete(`/admin/bans/${id}`);
      setter((prev) => prev.filter((v) => v.id !== id));
      setSuccess(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async () => {
    setLoading(true);
    setError(undefined);
    setSuccess(false);

    try {
      const resp = await axios.post('/admin/bans', {
        user_id: props.playerId,
        start: new Date(start),
        end: new Date(end),
        is_citation: isCitation,
        is_permanent: isPermanent,
        reasons: selectedReasons,
      });
      const id = +resp.data['id'];
      setCurrent((prev) => {
        const v: Violation = {
          userId: props.playerId,
          username: '',
          id: id,
          startsAt: new Date(start),
          endsAt: end !== '' ? new Date(end) : undefined,
          isBan: !isCitation,
          reasons: selectedReasonsStr,
        };
        return [v, ...prev];
      });

      setSelectedReasons([]);
      setSelectedReasonsStr([]);
      setIsCitation(false);
      setIsPermanent(false);
      setStart('');
      setEnd('');
      setSuccess(true);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container>
      {error && <Error error={error} />}
      {success && <Success text="Успешно" />}
      <TopBar>
        <span>Начало</span>
        <input
          type="datetime-local"
          onChange={(e) => setStart(e.target.value)}
        />
        {!isPermanent && (
          <>
            <span>Конец</span>
            <input
              type="datetime-local"
              onChange={(e) => setEnd(e.target.value)}
            />
          </>
        )}
        {!isPermanent && (
          <>
            <input
              type="checkbox"
              onChange={(e) => setIsCitation(e.target.checked)}
            />
            <span>Предупреждение</span>
          </>
        )}
        {!isCitation && (
          <>
            <input
              type="checkbox"
              onChange={(e) => setIsPermanent(e.target.checked)}
            />
            <span>Перманент</span>
          </>
        )}
      </TopBar>
      <Select
        isMulti
        name="reasons"
        options={selectOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        isSearchable={false}
        onChange={(e) => onSelectChange(e)}
        placeholder="Причины"
      ></Select>
      <LoadingButton
        isLoading={loading}
        text="Добавить"
        onClick={handleClick}
      />
      {current.length > 0 && (
        <TableContainer>
          <span>Текущие</span>
          <Table>
            <thead>
              <tr>
                <th>Начало</th>
                <th>Конец</th>
                <th>Причины</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {current.map((violation) => (
                <Row key={violation.id}>
                  <Column>{prettyDate(violation.startsAt)}</Column>
                  <Column>
                    {violation.endsAt
                      ? prettyDate(violation.endsAt)
                      : 'Перманент'}
                  </Column>
                  <Column>
                    {violation.reasons.length > 0 && (
                      <ShowModalSpan
                        onClick={() => {
                          setModalItems(violation.reasons);
                        }}
                      >{`Показать (${violation.reasons.length} причин)`}</ShowModalSpan>
                    )}
                  </Column>
                  <Column>
                    <Minus
                      width={24}
                      height={24}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDelete(violation.id, setCurrent)}
                    ></Minus>
                  </Column>
                </Row>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}

      {expired.length > 0 && (
        <TableContainer>
          <span>Прошедшие</span>
          <Table>
            <thead>
              <tr>
                <th>Начало</th>
                <th>Конец</th>
                <th>Причины</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {expired.map((violation) => (
                <Row key={violation.id}>
                  <Column>{prettyDate(violation.startsAt)}</Column>
                  <Column>
                    {violation.endsAt
                      ? prettyDate(violation.endsAt)
                      : 'Перманент'}
                  </Column>
                  <Column>
                    {violation.reasons.length > 0 && (
                      <ShowModalSpan
                        onClick={() => {
                          setModalItems(violation.reasons);
                        }}
                      >{`Показать (${violation.reasons.length} причин)`}</ShowModalSpan>
                    )}
                  </Column>
                  <Column>
                    <Minus
                      width={24}
                      height={24}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDelete(violation.id, setExpired)}
                    ></Minus>
                  </Column>
                </Row>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}

      {citations.length > 0 && (
        <TableContainer>
          <span>Предупреждения</span>
          <Table>
            <thead>
              <tr>
                <th>Начало</th>
                <th>Конец</th>
                <th>Причины</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {citations.map((violation) => (
                <Row key={violation.id}>
                  <Column>{prettyDate(violation.startsAt)}</Column>
                  <Column>
                    {violation.endsAt
                      ? prettyDate(violation.endsAt)
                      : 'Перманент'}
                  </Column>
                  <Column>
                    {violation.reasons.length > 0 && (
                      <ShowModalSpan
                        onClick={() => {
                          setModalItems(violation.reasons);
                        }}
                      >{`Показать (${violation.reasons.length} причин)`}</ShowModalSpan>
                    )}
                  </Column>
                  <Column>
                    <Minus
                      width={24}
                      height={24}
                      style={{ cursor: 'pointer' }}
                      onClick={() => handleDelete(violation.id, setCitations)}
                    ></Minus>
                  </Column>
                </Row>
              ))}
            </tbody>
          </Table>
        </TableContainer>
      )}

      {modalItems.length > 0 && (
        <Modal
          title="Причины"
          onClose={() => {
            setModalItems([]);
          }}
        >
          {modalItems.map((reason) => (
            <div style={{ color: 'white' }} key={reason}>
              {reason}
            </div>
          ))}
        </Modal>
      )}
    </Container>
  );
};
