import React, { useEffect, useState } from 'react';
import { AdminHofPlayer } from '../../models';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';
import { HOF } from '../../components/admin/HOF';

export const HOFPage = () => {
  const [players, setPlayers] = useState<AdminHofPlayer[]>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const axios = useAxiosPrivate();

  useEffect(() => {
    setLoading(true);
    setError(undefined);

    const doRequest = async () => {
      try {
        const resp = await axios.get('/admin/hof');
        const res = resp.data.map((p: any) => {
          const player: AdminHofPlayer = {
            id: p['id'],
            username: p['username'],
            photoUrl: p['photo_url'],
            description: p['description'],
          };
          return player;
        });
        setPlayers(res);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <HOF players={players!} />;
};
