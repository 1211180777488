import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Slots } from '../../components/profile/Slots';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { fetchSlotsStats } from '../../lib/api';
import { SlotStat } from '../../models';
import { Error } from '../../components/UI/Error';

export const SlotsPage = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [slots, setSlots] = useState<SlotStat[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await fetchSlotsStats(playerId);
        setSlots(resp);
        setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Slots slots={slots}></Slots>;
};
