import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../components/UI/Input';
import { LoadingButton } from '../../components/UI/LoadingButton';
import { register } from '../../lib/api';
import { Error } from '../../components/UI/Error';
import { Success } from '../../components/UI/Success';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const InputTitle = styled.span`
  color: white;
  margin-right: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RegisterPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const [success, setSuccess] = useState<boolean>(false);

  const handleClick = async () => {
    setLoading(true);
    setError('');
    setSuccess(false);

    try {
      await register({
        login: username,
        password: password,
        invite_code: code,
      });
      setSuccess(true);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const usernameChange = (name: string) => {
    setUsername(name);
  };

  const passwordChange = (pass: string) => {
    setPassword(pass);
  };

  const codeChange = (pass: string) => {
    setCode(pass);
  };

  return (
    <Container>
      <InputContainer>
        <Input placeholder="Логин" onChange={usernameChange} />
      </InputContainer>
      <InputContainer>
        <Input placeholder="Пароль" type="password" onChange={passwordChange} />
      </InputContainer>
      <InputContainer>
        <Input placeholder="Код приглашения" onChange={codeChange} />
      </InputContainer>

      <ButtonContainer>
        <LoadingButton
          isLoading={loading}
          text="Отправить"
          onClick={handleClick}
        />
      </ButtonContainer>

      {error && <Error error={error} />}
      {success && <Success text="Пользователь создан" />}
    </Container>
  );
};
