import React from 'react';
import './App.css';
import { MainLayout } from './components/layout/MainLayout';
import { LeaderboardPage } from './pages/leaderboard/Leaderboard';
import { Route, Routes, Navigate } from 'react-router-dom';
import {
  ProfilePage,
  Overview,
  PartnersPage,
  ActivityPage,
  SlotsPage,
  NightPage,
} from './pages/profile';
import {
  BansPage,
  CitationsPage,
  CurrentBans,
  ExpiredBans,
} from './pages/bans';
import { GamesPage } from './pages/games/Games';
import { VersusPage } from './pages/versus/Versus';
import { TournamentsPage as TournamentsAdminPage } from './pages/admin/TournamentsPage';
import { TournamentsPage } from './pages/Tournaments';
import { CombosPage } from './pages/combos/Combos';
import { HallOfFamePage } from './pages/hall-of-fame/HallOfFame';
import { StatsPage } from './pages/stats/StatsPage';
import { SupportPage } from './pages/support/SupportPage';
import { NotFoundPage } from './pages/notfound/NotFound';
import { AuthPage } from './pages/auth/AuthPage';
import { LoginPage } from './pages/auth/LoginPage';
import { RegisterPage } from './pages/auth/RegisterPage';
import { AuthContextProvider } from './store/auth-context';
import { AdminPage } from './pages/admin/AdminPage';
import { PlayersPage } from './pages/admin/PlayersPage';
import { AddPlayer } from './pages/admin/AddPlayer';
import { EditPlayerPage } from './pages/admin/EditPlayerPage';
import { HOFPage } from './pages/admin/HOF';
import { HOFAdd } from './components/admin/HOFAdd';
import { EditHOFPlayerPage } from './pages/admin/EditHOFPlayer';
import { AddTournament } from './pages/admin/AddTournament';
import { EditTournamentPage } from './pages/admin/EditTournament';
import { BansPage as AdminBansPage } from './pages/admin/BansPage';
import { EditBansPage } from './pages/admin/EditBansPage';
import { RouteGuard } from './components/RouteGuard';
import { TournamentListPage } from './pages/TournamentListPage';
import { HelmetProvider } from 'react-helmet-async';
import { KillersPage } from './pages/profile/KillersPage';
import { LeaderboardContainer } from './pages/leaderboard/LeaderboardContainer';
import { LeaderboardMonthlyPage } from './pages/leaderboard/LeaderboardMonthlyPage';

function App() {
  return (
    <div className="App">
      <AuthContextProvider>
        <HelmetProvider>
          <MainLayout>
            <Routes>
              {/* <Route
                path="/"
                element={<Navigate to="/leaderboard" replace />}
              ></Route> */}
              <Route
                path=""
                element={<Navigate to="/leaderboard/top" replace />}
              />
              <Route path="leaderboard/*" element={<LeaderboardContainer />}>
                <Route
                  path=""
                  element={<Navigate to="/leaderboard/top" replace />}
                ></Route>
                <Route path="top" element={<LeaderboardPage />}></Route>
                <Route
                  path="monthly"
                  element={<LeaderboardMonthlyPage />}
                ></Route>
                <Route
                  path="*"
                  element={<Navigate to="/notfound" replace />}
                ></Route>
              </Route>

              <Route path="/games" element={<GamesPage />}></Route>
              <Route path="/players/:playerId/*" element={<ProfilePage />}>
                <Route
                  path=""
                  element={<Navigate to="overview" replace />}
                ></Route>
                <Route path="overview" element={<Overview />}></Route>
                <Route path="games" element={<GamesPage />}></Route>
                <Route path="partners" element={<PartnersPage />}></Route>
                <Route path="activity" element={<ActivityPage />}></Route>
                <Route path="bans/*" element={<BansPage />}>
                  <Route
                    path=""
                    element={<Navigate to="current" replace />}
                  ></Route>
                  <Route path="current" element={<CurrentBans />}></Route>
                  <Route path="expired" element={<ExpiredBans />}></Route>
                  <Route path="citations" element={<CitationsPage />}></Route>
                  <Route
                    path="*"
                    element={<Navigate to="/notfound" replace />}
                  ></Route>
                </Route>
                <Route path="slots" element={<SlotsPage />}></Route>
                <Route path="night" element={<NightPage />}></Route>
                <Route path="stats" element={<StatsPage />}></Route>
                <Route path="killers" element={<KillersPage />}></Route>
                <Route
                  path="*"
                  element={<Navigate to="/notfound" replace />}
                ></Route>
              </Route>
              <Route path="/bans/*" element={<BansPage />}>
                <Route
                  path=""
                  element={<Navigate to="current" replace />}
                ></Route>
                <Route path="current" element={<CurrentBans />}></Route>
                <Route path="expired" element={<ExpiredBans />}></Route>
                <Route path="citations" element={<CitationsPage />}></Route>
                <Route
                  path="*"
                  element={<Navigate to="/notfound" replace />}
                ></Route>
              </Route>
              <Route path="/versus" element={<VersusPage />}></Route>
              <Route
                path="/tournaments"
                element={<TournamentListPage />}
              ></Route>
              <Route
                path="/tournaments/:tournamentId"
                element={<TournamentsPage />}
              ></Route>
              <Route path="/combos" element={<CombosPage />}></Route>
              <Route path="/hall-of-fame" element={<HallOfFamePage />}></Route>
              <Route path="/support" element={<SupportPage />}></Route>
              <Route element={<RouteGuard />}>
                <Route path="/auth/*" element={<AuthPage />}>
                  <Route
                    path=""
                    element={<Navigate to="login" replace />}
                  ></Route>
                  <Route path="login" element={<LoginPage />}></Route>
                  <Route path="register" element={<RegisterPage />}></Route>
                </Route>
                <Route path="/admin" element={<AdminPage />}>
                  <Route
                    path=""
                    element={<Navigate to="players" replace />}
                  ></Route>
                  <Route path="players" element={<PlayersPage />}></Route>
                  <Route path="players/add" element={<AddPlayer />}></Route>
                  <Route
                    path="players/:playerId"
                    element={<EditPlayerPage />}
                  ></Route>
                  <Route
                    path="tournaments"
                    element={<TournamentsAdminPage />}
                  ></Route>
                  <Route
                    path="tournaments/add"
                    element={<AddTournament />}
                  ></Route>
                  <Route
                    path="tournaments/:tournamentId"
                    element={<EditTournamentPage />}
                  ></Route>
                  <Route path="hof" element={<HOFPage />}></Route>
                  <Route path="hof/add" element={<HOFAdd />}></Route>
                  <Route
                    path="hof/:playerId"
                    element={<EditHOFPlayerPage />}
                  ></Route>
                  <Route path="bans" element={<AdminBansPage />}></Route>
                  <Route
                    path="bans/:playerId"
                    element={<EditBansPage />}
                  ></Route>
                </Route>
              </Route>
              <Route path="/notfound" element={<NotFoundPage />}></Route>
              <Route
                path="*"
                element={<Navigate to="/notfound" replace />}
              ></Route>
            </Routes>
          </MainLayout>
        </HelmetProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;
