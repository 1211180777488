import React from 'react';
import { Player } from '../../models/player';
import { Card } from '../UI/Card';
import { prettyDate } from '../../lib/date';
import { NavLink } from 'react-router-dom';
import { Flag } from '../../flags/flags';
import styled from 'styled-components';
import { Tooltip } from '../UI/Tooltip';
import { Avatar } from '../UI/Avatar';
import { Badge } from '../UI/Badge';

interface Props {
  player: Player;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: white;
`;

const ProfileLeft = styled.div`
  display: flex;
  align-items: center;
  color: white;
`;

const PlayerAvatar = styled(Avatar)`
  position: relative;
`;

const AvatarOverlay = styled(NavLink)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: transparent;
  border-radius: 24px;
  transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const UserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
  height: 100%;
  justify-content: space-around;
`;

const UserInfo = styled.div`
  font-size: 40px;
  display: flex;
  flex-direction: column;
`;

const UserNameContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-left: 12px;
  }
`;

const FlagContainer = styled.div`
  img {
    width: 30px;
    height: 20px;
  }
`;

const DateSpan = styled.span`
  font-size: 14px;
  color: var(--gray-accent-light);
`;

const StatsContainer = styled.div`
  display: flex;
`;

const Stat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 12px;

  &:first-child {
    margin-left: 0;
  }
`;

const WonSpan = styled.span`
  color: var(--green-accent);
`;

const LostSpan = styled.span`
  color: var(--red-accent);
`;

const ProfileRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

const RankSpan = styled.span<{ rank?: number }>`
  font-size: 24px;
  margin-left: 8px;
  color: ${({ rank }) =>
    rank ? (rank < 6 ? 'var(--golden)' : 'var(--red-accent)') : 'white'};
`;

const StickerContainer = styled.div`
  img {
    height: 25px;
  }
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PanelCard = styled(Card)`
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
`;

export const Panel: React.FC<Props> = (props) => {
  return (
    <PanelCard>
      <Wrapper>
        <ProfileContainer>
          <ProfileLeft>
            <PlayerAvatar
              widthpx={150}
              heightpx={150}
              radiuspx="24px"
              avatarUrl={props.player.avatarUrl}
            >
              <AvatarOverlay
                to={`/players/${props.player.id}/overview`}
              ></AvatarOverlay>
            </PlayerAvatar>
            <UserInfoContainer>
              <UserInfo>
                <UserNameContainer>
                  <span>{props.player.username}</span>
                  <FlagContainer>
                    <Flag country={props.player.country} />
                  </FlagContainer>
                  {props.player.notorious && (
                    <StickerContainer>
                      <Tooltip text="Легенда">
                        <img src="https://a.notoxic.pro/profile/star.png" />
                      </Tooltip>
                    </StickerContainer>
                  )}
                  {props.player.stickers.map((sticker) =>
                    sticker.title !== '' ? (
                      <StickerContainer>
                        <Tooltip text={sticker.title}>
                          <img src={sticker.url} />
                        </Tooltip>
                      </StickerContainer>
                    ) : (
                      <StickerContainer>
                        <img src={sticker.url} />
                      </StickerContainer>
                    )
                  )}
                </UserNameContainer>
                <DateSpan>
                  Регистрация: {prettyDate(props.player.registeredAt)}
                </DateSpan>
              </UserInfo>
              <StatsContainer>
                <Stat>
                  <span>Победы</span>
                  <WonSpan>{props.player.gamesWon}</WonSpan>
                </Stat>
                <Stat>
                  <span>Поражения</span>
                  <LostSpan>{props.player.gamesLost}</LostSpan>
                </Stat>
                <Stat>
                  <span>Доля побед</span>
                  <span>
                    {(
                      (props.player.gamesWon /
                        (props.player.gamesLost + props.player.gamesWon)) *
                      100
                    ).toFixed(1)}
                    %
                  </span>
                </Stat>
                <Stat>
                  <span>Часов сыграно</span>
                  <span>{props.player.hoursPlayed}</span>
                </Stat>
                <Stat>
                  <span>NTP</span>
                  <span>
                    {props.player.ntp
                      ? props.player.ntp.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                        })
                      : 0}
                  </span>
                </Stat>
              </StatsContainer>
            </UserInfoContainer>
          </ProfileLeft>
          <ProfileRight>
            <Stat>
              <span>Место в лидерборде</span>
              <RankSpan rank={props.player.rank}>
                {props.player.rank ? props.player.rank : 'NA'}
              </RankSpan>
            </Stat>
            <Stat>
              <span>Последняя игра</span>
              <DateSpan>
                {props.player.lastPlayedAt
                  ? prettyDate(props.player.lastPlayedAt)
                  : 'NA'}
              </DateSpan>
            </Stat>
          </ProfileRight>
        </ProfileContainer>
        {props.player.badges.length > 0 && (
          <BadgesContainer>
            {props.player.badges.map((badge) => (
              <Badge
                badge={badge.badge}
                tooltip={badge.title}
                key={badge.badge}
                tournamentId={badge.tournamentID}
              ></Badge>
            ))}
          </BadgesContainer>
        )}
      </Wrapper>
    </PanelCard>
  );
};
