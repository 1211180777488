import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { AdminTournament, AdminTournamentPlacement } from '../../models';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';
import { EditTournament } from '../../components/admin/EditTournament';

export const EditTournamentPage = () => {
  const params = useParams();
  const tournamentId = +params.tournamentId!;
  const axios = useAxiosPrivate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [tournament, setTournament] = useState<AdminTournament>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await axios.get(`/admin/tournaments/${tournamentId}`);
        const data = resp.data;
        const res: AdminTournament = {
          id: data['id'],
          name: data['name'],
          description: data['description'],
          type: data['type'],
          startedAt: new Date(data['started_at']),
          endedAt: new Date(data['ended_at']),
          defaultBadge: data['default_badge'],
          firstPlaceBadge: data['first_place_badge'],
          secondPlaceBadge: data['second_place_badge'],
          thirdPlaceBadge: data['third_place_badge'],
          hqBadge: data['hq_badge'],
          links: data['links'],
          placements: data['placements'].map((p: any) => {
            const placement: AdminTournamentPlacement = {
              id: p['id'],
              placement: p['placement'],
              points: p['points'],
              username: p['username'],
            };
            return placement;
          }),
        };
        setTournament(res);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [tournamentId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <EditTournament tournament={tournament!} />;
};
