import debounce from 'lodash.debounce';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { search } from '../../lib/api';
import { PlayerSearchResult } from '../../models';
import { LoadingSpinner } from '../UI/LoadingSpinner';
import { Avatar } from '../UI/Avatar';
import { Flag } from '../../flags/flags';
import { Link, NavigationType, useNavigate } from 'react-router-dom';
import { link } from 'fs';
import { Error } from '../UI/Error';

const Container = styled.div`
  /* width: 100%; */
  position: relative;
`;

const Input = styled.input`
  outline: none;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  background-color: var(--gray-accent2);
  color: white;
  transform-origin: right;
`;

const ResultsContainer = styled.div`
  position: absolute;
  width: 400px;
  right: 0;
  top: calc(100% + 10px);
  opacity: 1;
  background-color: var(--gray-accent2);
  /* padding: 12px; */
  border-radius: 8px;
  /* animation: fade 0.3s; */
  display: flex;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  overflow: hidden;
  z-index: 10;
`;

const ResultsTitle = styled.span`
  width: 100%;
  color: white;
  height: 60px;
  background-color: var(--red-accent-light);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

const Results = styled.div`
  padding: 12px;
`;

const Result = styled.div<{ avatarUrl?: string }>`
  position: relative;
  background-color: var(--black-accent);
  border-radius: 8px;
  padding: 8px;
  margin-bottom: 12px;
  background: ${({ avatarUrl }) =>
    avatarUrl
      ? `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('${avatarUrl}')`
      : `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url('https://a.notoxic.pro/avatars/default.png')`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  &:last-child {
    margin-bottom: 0;
  }
`;

const PlayerBlock = styled.div`
  display: flex;
  align-items: center;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const PlayerName = styled.span`
  font-size: 20px;
  color: white;
`;

const FlagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  margin-top: 8px;

  & > img {
    width: 30px;
    height: 20px;
  }
`;

const ResultOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--red-accent);
  opacity: 0;
  transition: opacity 0.2s;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    opacity: 0.2;
  }
`;

const ResultOverlayLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--red-accent);
  opacity: 0;
  transition: opacity 0.2s;
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    opacity: 0.2;
  }
`;

interface Props {
  onResultSelected?: (res: PlayerSearchResult) => void;
  asLink?: boolean;
  filteredPlayers?: number[];
  onBlur?: () => void;
  closed?: () => void;
  placeholder?: string;
}

export const SearchV2: React.FC<Props> = (props) => {
  const [searchResults, setSearchResults] = useState<PlayerSearchResult[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const keyUpHandler = debounce((e) => {
    const val = e.target.value;
    if (val !== searchTerm) {
      setSearchResults([]);
      setSearchTerm(val);
    }
  }, 300);

  useEffect(() => {
    const filteredPlayers = props.filteredPlayers || [];
    if (!searchTerm) {
      setSearchResults([]);
      return;
    }

    const doRequest = async () => {
      setLoading(true);
      setError(undefined);
      try {
        let res = await search(searchTerm);
        if (filteredPlayers) {
          res = res.filter((p) => !filteredPlayers.includes(p.userId));
        }
        setSearchResults(res);
        setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [searchTerm, props.filteredPlayers]);

  const clickHandler = (res: PlayerSearchResult, isLink: boolean) => {
    if (props.onResultSelected) {
      props.onResultSelected(res);
    }
    if (isLink) {
      navigate('/players/' + res.userId);
    }

    if (props.closed) {
      props.closed();
    }

    setSearchResults([]);
    setSearchTerm('');
    inputRef.current!.value = '';
  };

  const blurHandler = () => {
    setSearchResults([]);
    setSearchTerm('');
    inputRef.current!.value = '';

    if (props.closed) {
      props.closed();
    }
  };

  return (
    <Container>
      <Input
        type="text"
        placeholder={props.placeholder || 'Введите имя игрока'}
        ref={inputRef}
        onChange={keyUpHandler}
        autoFocus
        onBlur={blurHandler}
      ></Input>
      <ResultsContainer>
        {searchTerm.length > 0 && (
          <>
            <ResultsTitle>Результаты</ResultsTitle>
            <Results>
              {loading && <LoadingSpinner />}
              {error && <Error error={error} />}
              {searchResults.length === 0 && !loading && !error && (
                <span style={{ color: 'white' }}>Ничего не найдено</span>
              )}
              {searchResults.length > 0 &&
                searchResults.map((player) => (
                  <Result key={player.userId} avatarUrl={player.avatarUrl}>
                    {props.asLink ? (
                      <ResultOverlayLink
                        to={`/players/${player.userId}`}
                        onMouseDown={clickHandler.bind(null, player, true)}
                      />
                    ) : (
                      <ResultOverlay
                        onMouseDown={clickHandler.bind(null, player, false)}
                      />
                    )}
                    <PlayerBlock>
                      <Avatar
                        widthpx={100}
                        heightpx={100}
                        radiuspx="24px"
                        avatarUrl={player.avatarUrl}
                      ></Avatar>
                      <PlayerContainer>
                        <PlayerName>{player.username}</PlayerName>
                        {player.country && (
                          <FlagContainer>
                            <Flag country={player.country}></Flag>
                          </FlagContainer>
                        )}
                      </PlayerContainer>
                    </PlayerBlock>
                  </Result>
                ))}
            </Results>
          </>
        )}
      </ResultsContainer>
    </Container>
  );
};
