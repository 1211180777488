import React from 'react';
import { AdminHofPlayer } from '../../models';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { Plus } from 'phosphor-react';

interface Props {
  players: AdminHofPlayer[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-self: flex-end;
`;

const PlayerList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Player = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  color: white;
  transition: 0.3s background-color;
  padding: 8px;
  align-items: center;

  &:hover {
    background-color: var(--red-accent-light);
    cursor: pointer;
  }
`;

const AddButton = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const HOF: React.FC<Props> = (props) => {
  return (
    <Container>
      <TopBar>
        <AddButton to="/admin/hof/add">
          <Plus width={24} height={24} color="white" />
        </AddButton>
      </TopBar>
      <PlayerList>
        {props.players.map((p) => (
          <Player key={p.id} to={`/admin/hof/${p.id}`}>
            <span>{p.username}</span>
          </Player>
        ))}
      </PlayerList>
    </Container>
  );
};
