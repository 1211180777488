import { axiosPrivate } from '../lib/axios';
import { useEffect } from 'react';
import useRefreshToken from './useRefreshToken';
import useAuth from './useAuth';

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const auth = useAuth();

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `Bearer ${
            auth.authData?.accessToken || ''
          }`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      async (error) => {
        const prevReq = error?.config;
        if (
          error?.response.status === 401 ||
          (error?.response.status === 403 && !prevReq?.sent)
        ) {
          prevReq.sent = true;
          try {
            const newTokenPair = await refresh();
            prevReq.headers[
              'Authorization'
            ] = `Bearer ${newTokenPair.accessToken}`;
            return axiosPrivate(prevReq);
          } catch (err: any) {
            if (err?.response.status === 401 || err?.response.status === 403) {
              auth.clear();
            }
          }
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.response.eject(responseIntercept);
      axiosPrivate.interceptors.request.eject(requestIntercept);
    };
  }, [auth, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
