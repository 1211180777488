import React from 'react';
import { Tournament } from '../../models';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

interface Props {
  tournaments: Map<number, Tournament[]>;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const TournamentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const TournamentsYear = styled.span`
  color: white;
  font-weight: bold;
  font-size: 48px;
`;

const TournamentContainer = styled.div<{ badgeUrl: string }>`
  width: 432px;
  height: 202px;
  background-image: ${(props) => `url(${props.badgeUrl})`};
  border-radius: 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
`;

const TournamentOverlay = styled(NavLink)`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  transition: 0.3s background-color;
  border-radius: 12px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    cursor: pointer;
  }
`;

export const List: React.FC<Props> = (props) => {
  return (
    <Container>
      {Array.from(props.tournaments).map(([key, value]) => (
        <TournamentBlock key={key}>
          <TournamentsYear>{key}</TournamentsYear>
          {value.map((tournament) => (
            <TournamentContainer
              key={tournament.id}
              badgeUrl={tournament.badge}
            >
              <TournamentOverlay to={`/tournaments/${tournament.id}`} />
            </TournamentContainer>
          ))}
        </TournamentBlock>
      ))}
    </Container>
  );
};
