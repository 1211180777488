import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  background-color: var(--gray-accent);
  width: 50%;
  border-radius: 12px;
`;

const TitleContainer = styled.div`
  display: flex;
`;

const TitleBar = styled(NavLink)`
  padding: 8px;
  background-color: var(--red-accent-light);
  width: 50%;
  text-align: center;
  user-select: none;
  color: black;
  transition: 0.3s background-color;

  &.active {
    background-color: var(--red-accent);
  }

  &:hover {
    background-color: var(--red-accent);
  }

  &:first-child {
    border-top-left-radius: 12px;
  }

  &:last-child {
    border-top-right-radius: 12px;
  }
`;

const Body = styled.div`
  padding: 12px;
`;

export const AuthPage = () => {
  return (
    <Wrapper>
      <Container>
        <TitleContainer>
          <TitleBar to="login">Логин</TitleBar>
          <TitleBar to="register">Регистрация</TitleBar>
        </TitleContainer>
        <Body>
          <Outlet />
        </Body>
      </Container>
    </Wrapper>
  );
};
