import React from 'react';
import { Header } from './header/Header';
import styled from 'styled-components';

const Main = styled.main`
  margin-top: 24px;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  /* width: 100wh; */
  height: 100%;
  /* min-height: calc(~'100vh - 64px'); */
`;

const Container = styled.div`
  width: var(--content-width);
`;

export const MainLayout = (props: any) => {
  return (
    <>
      <Header></Header>
      <Main>
        <Container>{props.children}</Container>
      </Main>
    </>
  );
};
