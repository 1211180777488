import React, { useState } from 'react';
import { Violation } from '../../models';
import { Table, Column, Row } from '../UI/Table';
import { prettyDateDiff, prettyDate } from '../../lib/date';
import { Modal } from '../UI/Modal';
import styled from 'styled-components';
import { NoContent } from '../UI/NoContent';
import { Link } from 'react-router-dom';
import { Avatar } from '../UI/Avatar';

interface Props {
  violations: Violation[];
  forUser?: boolean;
  isCitation?: boolean;
}

const ShowModalSpan = styled.span`
  color: var(--red-accent);
  transition: color 0.3s;

  &:hover {
    color: white;
    cursor: pointer;
  }
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled(Link)`
  margin-left: 8px;
  transition: color 0.3s;

  &:hover {
    color: var(--red-accent);
  }
`;

export const Bans: React.FC<Props> = (props) => {
  const [modalItems, setModalItems] = useState<string[]>([]);

  return (
    <>
      {props.violations.length === 0 && !props.isCitation && (
        <NoContent text="На текущий момент банов нет" />
      )}
      {props.violations.length === 0 && props.isCitation && (
        <NoContent text="На текущий момент предупреждений нет" />
      )}
      {props.violations.length > 0 && (
        <Table>
          <thead>
            <tr>
              {!props.forUser && <th>Игрок</th>}
              <th>{props.isCitation ? 'Выдано' : 'Начало бана'}</th>
              {!props.isCitation && (
                <>
                  <th>Конец бана</th>
                  <th>Осталось</th>
                </>
              )}
              <th>Причина</th>
            </tr>
          </thead>
          <tbody>
            {props.violations.map((violation) => (
              <Row key={violation.id}>
                {!props.forUser && (
                  <Column>
                    <UserContainer>
                      <Avatar
                        widthpx={40}
                        heightpx={40}
                        radiuspx="50%"
                        avatarUrl={violation.avatarUrl}
                      ></Avatar>
                      <Username to={`/players/${violation.userId}`}>
                        {violation.username}
                      </Username>
                    </UserContainer>
                  </Column>
                )}
                <Column>{prettyDate(violation.startsAt)}</Column>
                {!props.isCitation && (
                  <Column>
                    {violation.endsAt
                      ? prettyDate(violation.endsAt)
                      : 'Перманентный'}
                  </Column>
                )}
                {!props.isCitation && (
                  <Column>
                    {violation.endsAt
                      ? new Date() > violation.endsAt
                        ? '-'
                        : prettyDateDiff(new Date(), violation.endsAt)
                      : '-'}
                  </Column>
                )}
                <Column>
                  {violation.reasons.length === 1 && violation.reasons[0]}
                  {violation.reasons.length > 1 && (
                    <ShowModalSpan
                      onClick={() => {
                        setModalItems(violation.reasons);
                      }}
                    >{`Показать (${violation.reasons.length} причин)`}</ShowModalSpan>
                  )}
                </Column>
              </Row>
            ))}
          </tbody>
        </Table>
      )}
      {modalItems.length > 0 && (
        <Modal
          title="Причины"
          onClose={() => {
            setModalItems([]);
          }}
        >
          {modalItems.map((reason) => (
            <div style={{ color: 'white' }} key={reason}>
              {reason}
            </div>
          ))}
        </Modal>
      )}
    </>
  );
};
