import React, { useEffect, useState } from 'react';
import { leaderboardMonthly } from '../../lib/api';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';
import { LeaderboardPlayer } from '../../models';
import { Leaderboard } from '../../components/leaderboard/Leaderboard';
import { FaqCardContainer, FaqContainer, FaqElement } from './Faq';
import { Card } from '../../components/UI/Card';
import { DescriptionCard } from '../../components/UI/Desc-Card';
import styled from 'styled-components';

const Container = styled.div`
  margin-top: 12px;
  text-align: center;
  color: white;
`;

export const LeaderboardMonthlyPage = () => {
  const [players, setPlayers] = useState<LeaderboardPlayer[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    setLoading(true);
    const doRequest = async () => {
      try {
        const res = await leaderboardMonthly();
        setPlayers(res);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (players.length === 0)
    return <Container>Данных на текущий месяц нет</Container>;

  return (
    <>
      <Leaderboard
        players={players}
        title="Таблица лидеров за текущий месяц"
        description="Таблица лидеров No Toxic за текущий месяц"
      />
      <FaqContainer>
        <FaqCardContainer>
          <Card>
            <FaqElement>
              <DescriptionCard title="Как часто обновляется таблица?">
                Таблица обнуляется 1 числа каждого месяца в 00:00 (МСК)
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="No Toxic Points">
                В ежемесячной таблице не учитываются множители и бонусы, которые
                работают в основной таблице. Все игроки в равных условиях: за
                победу +26, за поражение -24 рейтинга. Теперь у всех есть шанс!
              </DescriptionCard>
            </FaqElement>
            <FaqElement>
              <DescriptionCard title="Турнир 'Woland's Cup'">
                Первые 10 игроков с наивысшим рейтингом в ежемесячной таблице
                приглашаются на ежемесячный турнир с денежным призом
              </DescriptionCard>
            </FaqElement>
          </Card>
        </FaqCardContainer>
      </FaqContainer>
    </>
  );
};
