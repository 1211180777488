import React, { useState } from 'react';
import { AdminPlayer } from '../../models';
import styled from 'styled-components';
import { Check, Minus, Plus } from 'phosphor-react';
import { Input } from '../UI/Input';
import { NavLink } from 'react-router-dom';

interface Props {
  players: AdminPlayer[];
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

const PlayerList = styled.div`
  display: flex;
  flex-direction: column;
`;

const Player = styled(NavLink)`
  display: flex;
  justify-content: space-between;
  color: white;
  transition: 0.3s background-color;
  padding: 8px;
  align-items: center;

  &:hover {
    background-color: var(--red-accent-light);
    cursor: pointer;
  }
`;

const AddButton = styled(NavLink)`
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
`;

export const Players: React.FC<Props> = (props) => {
  const [filtered, setFiltered] = useState<AdminPlayer[]>([...props.players]);

  const onInputChange = (str: string) => {
    const f = props.players.filter(
      (p) => p.username.toLowerCase().indexOf(str) > -1
    );
    setFiltered(f);
  };

  return (
    <Container>
      <TopBar>
        <Input placeholder="Фильтр" onChange={onInputChange} />
        <AddButton to="/admin/players/add">
          <Plus width={24} height={24} color="white" />
        </AddButton>
      </TopBar>
      <PlayerList>
        {filtered.map((p) => (
          <Player key={p.id} to={`/admin/players/${p.id}`}>
            <span>{p.username}</span>
            <span>
              {p.isRanked ? (
                <Check width={24} height={24} />
              ) : (
                <Minus width={24} height={24} />
              )}
            </span>
          </Player>
        ))}
      </PlayerList>
    </Container>
  );
};
