import React from 'react';
import {
  DayActivity,
  HourActivity,
  MonthActivity,
} from '../../models/activity';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { TitledCard } from './TitledCard';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  days: DayActivity[];
  hours: HourActivity[];
  months: MonthActivity[];
}

export const Activity: React.FC<Props> = (props) => {
  return (
    <>
      <TitledCard title="Дни недели">{DaysBar(props.days)}</TitledCard>
      <TitledCard title="Часы">{HoursBar(props.hours)}</TitledCard>
      <TitledCard title="Месяцы">{MonthsBar(props.months)}</TitledCard>
    </>
  );
};

const HoursBar = (hours: HourActivity[]) => {
  const sorted = hours.sort((a, b) => (a.hour > b.hour ? 1 : -1));
  const labels = sorted.map((h) => h.hour);
  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (ctx: any) {
            const idx = ctx.dataIndex;
            const data = ctx.dataset.data[idx];
            return `${data.won} побед / ${data.played} игр (${data.winrate}% wr)`;
          },
          title: function (ctx: any) {
            return `${ctx[0].label}:00`;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: sorted.map((h) => ({
          played: h.played,
          won: h.won,
          winrate: Math.round((h.won / h.played) * 100),
        })),
        backgroundColor: '#521721',
        hoverBackgroundColor: '#6d1f2c',
        parsing: {
          xAxisKey: 'played',
          yAxisKey: 'played',
        },
      },
    ],
  };
  return <Bar data={data} options={options}></Bar>;
};

const DaysBar = (days: DayActivity[]) => {
  const sorted = days.sort((a, b) => (a.day > b.day ? 1 : -1));
  const idxToName: any = {
    1: 'Понедельник',
    2: 'Вторник',
    3: 'Среда',
    4: 'Четверг',
    5: 'Пятница',
    6: 'Суббота',
    7: 'Воскресенье',
  };
  const labels = sorted.map((a) => idxToName[a.day]);
  const options: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (ctx: any) {
            const idx = ctx.dataIndex;
            const data = ctx.dataset.data[idx];
            return `${data.won} побед / ${data.played} игр (${data.winrate}% wr)`;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: sorted.map((a) => ({
          played: a.played,
          won: a.won,
          winrate: Math.round((a.won / a.played) * 100),
        })),
        backgroundColor: '#521721',
        hoverBackgroundColor: '#6d1f2c',
        parsing: {
          xAxisKey: 'played',
          yAxisKey: 'played',
        },
      },
    ],
  };
  return <Bar data={data} options={options}></Bar>;
};

const MonthsBar = (months: MonthActivity[]) => {
  const sorted = months.sort((a, b) => (a.month > b.month ? 1 : -1));
  const idxToName: any = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь',
  };
  const labels = sorted.map((a) => idxToName[a.month]);
  const options: ChartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (ctx: any) {
            const idx = ctx.dataIndex;
            const data = ctx.dataset.data[idx];
            return `${data.won} побед / ${data.played} игр (${data.winrate}% wr)`;
          },
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        data: sorted.map((a) => ({
          played: a.played,
          won: a.won,
          winrate: Math.round((a.won / a.played) * 100),
        })),
        backgroundColor: '#521721',
        hoverBackgroundColor: '#6d1f2c',
        parsing: {
          xAxisKey: 'played',
          yAxisKey: 'played',
        },
      },
    ],
  };
  return <Bar data={data} options={options}></Bar>;
};
