import React from 'react';
import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;
  color: white;
  border-collapse: collapse;
  border-spacing: 0;
  text-align: center;
  border-radius: 8px;
  overflow: hidden;

  th {
    background-color: var(--red-accent-light);
    padding: 12px 24px;
  }

  tr {
    transition: background-color 0.2s;
  }

  tr:hover {
    background-color: var(--black-accent);
  }

  td:first-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  td:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const Row = styled.tr`
  background-color: var(--gray-accent);

  &:nth-child(odd) {
    background-color: var(--gray-accent3);
  }
`;

export const Column = styled.td`
  padding: 6px 12px;
`;
