import React from 'react';
import { PlayerStat } from '../../models/stats';
import { Game } from '../../models/game';
import { LastActivity } from '../../models/activity';
import classes from './Overview.module.css';
import { prettyDate, prettyDateDiff } from '../../lib/date';
import { Card } from '../UI/Card';
import { Table, Row, Column } from '../UI/Table';
import { Civilian, Mafia, Sheriff, Godfather } from '../../icons';
import { TitledCard } from './TitledCard';
import './overview.css';
import 'react-tooltip/dist/react-tooltip.css';
import { ResponsiveTimeRange } from '@nivo/calendar';
import { NtpHistory } from '../../models';
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

interface Props {
  stats: PlayerStat;
  games: Game[];
  activity: LastActivity[];
  ntpHistory: NtpHistory[];
}

const roleToIcon = (role: string) => {
  if (role === 'civilian') {
    return <Civilian />;
  }
  if (role === 'sheriff') {
    return <Sheriff />;
  }
  if (role === 'mafia') {
    return <Mafia />;
  }

  if (role === 'godfather') {
    return <Godfather />;
  }

  return <div className={classes['role-container']}>Судья</div>;
};

export const Overview: React.FC<Props> = (props) => {
  let totalGames = 0;
  if (props.stats.Civilian) {
    totalGames += props.stats.Civilian.gamesPlayed;
  }
  if (props.stats.Mafia) {
    totalGames += props.stats.Mafia.gamesPlayed;
  }
  if (props.stats.Godfather) {
    totalGames += props.stats.Godfather.gamesPlayed;
  }
  if (props.stats.Sheriff) {
    totalGames += props.stats.Sheriff.gamesPlayed;
  }

  const activityData = props.activity.reduce((acc, curr) => {
    let percent = 0;
    if (curr.won > 0) {
      percent = Math.round((curr.won / curr.played) * 100);
    }

    return [
      ...acc,
      {
        value: curr.played > 0 ? curr.played : null,
        day: curr.dt.toString().slice(0, 10),
        percent: percent,
        won: curr.won,
      },
    ];
  }, [] as any);

  const chartData = {
    labels: props.ntpHistory.map((h) => h.dt.toISOString().split('T')[0]),
    datasets: [
      {
        data: props.ntpHistory,
        color: 'white',
        fill: true,
        tension: 0.5,
        pointRadius: 5,
        borderWidth: 4,
        strokeColor: 'white',
        borderColor: '#f24462',
        pointBackgroundColor: 'transparent',
        pointBorderColor: 'transparent',
        // pointHoverBackgroundColor: '#f24462',
        // pointHoverBorderColor: '#f24462',
        parsing: {
          xAxisKey: 'dt',
          yAxisKey: 'ntp',
        },
      },
    ],
  };

  const chartOptions = {
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          label: function (ctx: any) {
            const idx = ctx.dataIndex;
            const data = ctx.dataset.data[idx];
            return `ntp: ${data.ntp}`;
          },
          title: function (ctx: any) {
            const idx = ctx[0].dataIndex;
            const data = ctx[0].dataset.data[idx];
            const now = new Date();
            const diffInMs = now.valueOf() - data.dt.valueOf();
            const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
            if (diffInDays > 0) {
              return `${diffInDays} дней назад:`;
            }

            return 'Сегодня: ';
          },
        },
      },
    },
    scales: {
      y: {
        display: false,
        min: Math.min(...props.ntpHistory.map((h) => h.ntp)) / 1.5,
        max: Math.max(...props.ntpHistory.map((h) => h.ntp)) * 1.5,
      },
      x: {
        display: false,
      },
    },
    animation: {
      duration: 0,
    },
  };

  return (
    <div className={classes.container}>
      <div className={classes.left}>
        <TitledCard title="Статистика по ролям">
          <Table>
            <thead>
              <tr>
                <th>Роль</th>
                <th>Матчи</th>
                <th>% Побед</th>
              </tr>
            </thead>
            <tbody>
              {props.stats.Civilian && (
                <Row>
                  <Column>{roleToIcon('civilian')}</Column>
                  <Column>{props.stats.Civilian.gamesPlayed}</Column>
                  <Column>
                    {(
                      (props.stats.Civilian.gamesWon /
                        props.stats.Civilian.gamesPlayed) *
                      100
                    ).toFixed(1)}
                  </Column>
                </Row>
              )}
              {props.stats.Mafia && (
                <Row>
                  <Column>{roleToIcon('mafia')}</Column>
                  <Column>{props.stats.Mafia.gamesPlayed}</Column>
                  <Column>
                    {(
                      (props.stats.Mafia.gamesWon /
                        props.stats.Mafia.gamesPlayed) *
                      100
                    ).toFixed(1)}
                  </Column>
                </Row>
              )}
              {props.stats.Sheriff && (
                <Row>
                  <Column>{roleToIcon('sheriff')}</Column>
                  <Column>{props.stats.Sheriff.gamesPlayed}</Column>
                  <Column>
                    {(
                      (props.stats.Sheriff.gamesWon /
                        props.stats.Sheriff.gamesPlayed) *
                      100
                    ).toFixed(1)}
                  </Column>
                </Row>
              )}
              {props.stats.Godfather && (
                <Row>
                  <Column>{roleToIcon('godfather')}</Column>
                  <Column>{props.stats.Godfather.gamesPlayed}</Column>
                  <Column>
                    {(
                      (props.stats.Godfather.gamesWon /
                        props.stats.Godfather.gamesPlayed) *
                      100
                    ).toFixed(1)}
                  </Column>
                </Row>
              )}
            </tbody>
          </Table>
        </TitledCard>
        <TitledCard title="Последние игры">
          <Table>
            <thead>
              <tr>
                <th>Роль</th>
                <th>Результат</th>
                <th>Дата</th>
                <th>Длительность</th>
                <th>Баллы</th>
                <th>MVP</th>
                <th>NTP</th>
              </tr>
            </thead>
            <tbody>
              {props.games.map((game) => (
                <Row key={game.gameId}>
                  <Column>{roleToIcon(game.role)}</Column>
                  <Column
                    className={
                      game.role === 'judge'
                        ? ''
                        : game.won
                        ? `${classes.won}`
                        : `${classes.loss}`
                    }
                  >
                    {game.role === 'judge'
                      ? '-'
                      : game.won
                      ? 'Победа'
                      : 'Поражение'}
                  </Column>
                  <Column>{prettyDate(game.startedAt)}</Column>
                  <Column>
                    {prettyDateDiff(game.startedAt, game.endedAt)}
                  </Column>
                  <Column
                    className={
                      game.points > 0
                        ? `${classes.won}`
                        : game.points < 0
                        ? `${classes.loss}`
                        : ''
                    }
                  >
                    {game.points}
                  </Column>
                  <Column className={game.isMvp ? `${classes.mvp}` : ''}>
                    {game.isMvp ? 'Да' : 'Нет'}
                  </Column>
                  <Column
                    className={
                      game.ntp && game.ntp > 0
                        ? `${classes.won}`
                        : game.ntp && game.ntp < 0
                        ? `${classes.loss}`
                        : ''
                    }
                  >
                    {game.ntp}
                  </Column>
                </Row>
              ))}
            </tbody>
          </Table>
        </TitledCard>
      </div>
      <div className={classes.right}>
        <TitledCard title="Последняя активность" height={300}>
          <ResponsiveTimeRange
            data={activityData}
            from={activityData[0].day}
            to={activityData[activityData.length - 1].day}
            emptyColor="#fff"
            colors={['#f24462']}
            margin={{ top: 40 }}
            dayBorderWidth={0}
            dayBorderColor="#ffffff"
            tooltip={(v) => {
              if (v.value === 'null') {
                return null;
              }
              const played = v.value === 'null' ? 0 : v.value;
              const won = (v as any)['won'];
              const percent = (v as any)['percent'];
              return (
                <>
                  <div
                    style={{
                      padding: '8px',
                      color: 'white',
                      backgroundColor: 'black',
                      fontSize: '12px',
                      border: '2px solid var(--gray-accent2)',
                      textAlign: 'center',
                    }}
                  >
                    <span>{v.day}</span>
                    <br></br>
                    <span>
                      Игр: {played}, Побед: {won}, wr: {percent}%
                    </span>
                  </div>
                </>
              );
            }}
            daySpacing={8}
            square={true}
          />
        </TitledCard>
        {props.ntpHistory.length > 0 && (
          <TitledCard title="История NTP" height={300}>
            <Line
              data={chartData}
              options={chartOptions}
              style={{
                // position: 'absolute',
                maxWidth: '100%',
                maxHeight: '100%',
                position: 'relative',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            ></Line>
          </TitledCard>
        )}
        {/* <TitledCard title="Матчи">
          <span>Сыграно игр: {totalGames}</span>
          <br></br>
          <span>Покинуто игр: 99</span>
        </TitledCard>
        <TitledCard title="Тип лобби">
          <span>Лобби: 99</span>
          <br></br>
          <span>Лига: 99</span>
          <br></br>
          <span>Другие: 99</span>
          <br></br>
        </TitledCard>
        <TitledCard title="Лучшие показатели">
          <span>Лучшая карта: мафия</span>
          <br></br>
          <span>Лучший слот: 7</span>
          <br></br>
          <span>Лучший месяц: январь</span>
          <br></br>
          <span>Среднее время игры: 99</span>
          <br></br>
        </TitledCard> */}
      </div>
    </div>
  );
};
