import React from 'react';
import { AccuracyStat, NightStat, Player, PlayerStat } from '../../models';
import styled from 'styled-components';
import { Column, Row, Table } from '../UI/Table';
import { ArrowUp, ArrowDown } from 'phosphor-react';

interface Props {
  playerOneInfo: Player;
  playerTwoInfo: Player;
  playerOneRoles: PlayerStat;
  playerTwoRoles: PlayerStat;
  playerOneAccuracy: AccuracyStat;
  playerTwoAccuracy: AccuracyStat;
  playerOneNight: NightStat;
  playerTwoNight: NightStat;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  width: 70%;
`;

const PaddedColumn = styled(Column)`
  :first-child {
    padding: 12px 24px;
    text-align: left;
  }
`;

const TableContainer = styled.div`
  margin-top: 24px;
`;

export const Versus: React.FC<Props> = (props) => {
  const playerOneWR = (
    (props.playerOneInfo.gamesWon /
      (props.playerOneInfo.gamesLost + props.playerOneInfo.gamesWon)) *
    100
  ).toFixed(1);
  const playerTwoWR = (
    (props.playerTwoInfo.gamesWon /
      (props.playerTwoInfo.gamesLost + props.playerTwoInfo.gamesWon)) *
    100
  ).toFixed(1);

  let playerOneCivilWin = 0;
  let playerOneCivilLost = 0;
  let playerOneCivilWR = '0';
  let playerOneCivilTotalGames = 0;
  if (props.playerOneRoles.Civilian) {
    playerOneCivilWin = props.playerOneRoles.Civilian.gamesWon;
    playerOneCivilLost =
      props.playerOneRoles.Civilian.gamesPlayed -
      props.playerOneRoles.Civilian.gamesWon;
    playerOneCivilTotalGames = props.playerOneRoles.Civilian.gamesPlayed;
    playerOneCivilWR = (
      (playerOneCivilWin / playerOneCivilTotalGames) *
      100
    ).toFixed(1);
  }

  let playerTwoCivilWin = 0;
  let playerTwoCivilLost = 0;
  let playerTwoCivilWR = '0';
  let playerTwoCivilTotalGames = 0;
  if (props.playerTwoRoles.Civilian) {
    playerTwoCivilWin = props.playerTwoRoles.Civilian.gamesWon;
    playerTwoCivilLost =
      props.playerTwoRoles.Civilian.gamesPlayed -
      props.playerTwoRoles.Civilian.gamesWon;
    playerTwoCivilTotalGames = props.playerTwoRoles.Civilian.gamesPlayed;
    playerTwoCivilWR = (
      (playerTwoCivilWin / playerTwoCivilTotalGames) *
      100
    ).toFixed(1);
  }

  let playerOneMafiaWin = 0;
  let playerOneMafiaLost = 0;
  let playerOneMafiaWR = '0';
  let playerOneMafiaTotalGames = 0;
  if (props.playerOneRoles.Mafia) {
    playerOneMafiaWin = props.playerOneRoles.Mafia.gamesWon;
    playerOneMafiaLost =
      props.playerOneRoles.Mafia.gamesPlayed -
      props.playerOneRoles.Mafia.gamesWon;
    playerOneMafiaTotalGames = props.playerOneRoles.Mafia.gamesPlayed;
    playerOneMafiaWR = (
      (playerOneMafiaWin / playerOneMafiaTotalGames) *
      100
    ).toFixed(1);
  }

  let playerTwoMafiaWin = 0;
  let playerTwoMafiaLost = 0;
  let playerTwoMafiaWR = '0';
  let playerTwoMafiaTotalGames = 0;
  if (props.playerTwoRoles.Mafia) {
    playerTwoMafiaWin = props.playerTwoRoles.Mafia.gamesWon;
    playerTwoMafiaLost =
      props.playerTwoRoles.Mafia.gamesPlayed -
      props.playerTwoRoles.Mafia.gamesWon;
    playerTwoMafiaTotalGames = props.playerTwoRoles.Mafia.gamesPlayed;
    playerTwoMafiaWR = (
      (playerTwoMafiaWin / playerTwoMafiaTotalGames) *
      100
    ).toFixed(1);
  }

  let playerOneSheriffWin = 0;
  let playerOneSheriffLost = 0;
  let playerOneSheriffWR = '0';
  let playerOneSheriffTotalGames = 0;
  if (props.playerOneRoles.Sheriff) {
    playerOneSheriffWin = props.playerOneRoles.Sheriff.gamesWon;
    playerOneSheriffLost =
      props.playerOneRoles.Sheriff.gamesPlayed -
      props.playerOneRoles.Sheriff.gamesWon;
    playerOneSheriffTotalGames = props.playerOneRoles.Sheriff.gamesPlayed;
    playerOneSheriffWR = (
      (playerOneSheriffWin / playerOneSheriffTotalGames) *
      100
    ).toFixed(1);
  }

  let playerTwoSheriffWin = 0;
  let playerTwoSheriffLost = 0;
  let playerTwoSheriffWR = '0';
  let playerTwoSheriffTotalGames = 0;
  if (props.playerTwoRoles.Sheriff) {
    playerTwoSheriffWin = props.playerTwoRoles.Sheriff.gamesWon;
    playerTwoSheriffLost =
      props.playerTwoRoles.Sheriff.gamesPlayed -
      props.playerTwoRoles.Sheriff.gamesWon;
    playerTwoSheriffTotalGames = props.playerTwoRoles.Sheriff.gamesPlayed;
    playerTwoSheriffWR = (
      (playerTwoSheriffWin / playerTwoSheriffTotalGames) *
      100
    ).toFixed(1);
  }

  let playerOneGodfatherWin = 0;
  let playerOneGodfatherLost = 0;
  let playerOneGodfatherWR = '0';
  let playerOneGodfatherTotalGames = 0;
  if (props.playerOneRoles.Godfather) {
    playerOneGodfatherWin = props.playerOneRoles.Godfather.gamesWon;
    playerOneGodfatherLost =
      props.playerOneRoles.Godfather.gamesPlayed -
      props.playerOneRoles.Godfather.gamesWon;
    playerOneGodfatherTotalGames = props.playerOneRoles.Godfather.gamesPlayed;
    playerOneGodfatherWR = (
      (playerOneGodfatherWin / playerOneGodfatherTotalGames) *
      100
    ).toFixed(1);
  }

  let playerTwoGodfatherWin = 0;
  let playerTwoGodfatherLost = 0;
  let playerTwoGodfatherWR = '0';
  let playerTwoGodfatherTotalGames = 0;
  if (props.playerTwoRoles.Godfather) {
    playerTwoGodfatherWin = props.playerTwoRoles.Godfather.gamesWon;
    playerTwoGodfatherLost =
      props.playerTwoRoles.Godfather.gamesPlayed -
      props.playerTwoRoles.Godfather.gamesWon;
    playerTwoGodfatherTotalGames = props.playerTwoRoles.Godfather.gamesPlayed;
    playerTwoGodfatherWR = (
      (playerTwoGodfatherWin / playerTwoGodfatherTotalGames) *
      100
    ).toFixed(1);
  }

  let playerOneAccuracy = '0';
  let playerTwoAccuracy = '0';

  let playerOneTotalVotes = 0;
  let playerOnePositiveVotes = 0;

  let playerTwoTotalVotes = 0;
  let playerTwoPositiveVotes = 0;

  let playerOneCivilAccuracy = '0';
  let playerTwoCivilAccuracy = '0';

  let playerOneMafiaAccuracy = '0';
  let playerTwoMafiaAccuracy = '0';

  let playerOneSheriffAccuracy = '0';
  let playerTwoSheriffAccuracy = '0';

  let playerOneGodfatherAccuracy = '0';
  let playerTwoGodfatherAccuracy = '0';

  if (props.playerOneAccuracy.civilian) {
    playerOneTotalVotes += props.playerOneAccuracy.civilian.total;
    playerOnePositiveVotes += props.playerOneAccuracy.civilian.positive;

    playerOneCivilAccuracy = (
      (props.playerOneAccuracy.civilian.positive /
        props.playerOneAccuracy.civilian.total) *
      100
    ).toFixed(1);
  }
  if (props.playerOneAccuracy.mafia) {
    playerOneTotalVotes += props.playerOneAccuracy.mafia.total;
    playerOnePositiveVotes += props.playerOneAccuracy.mafia.positive;

    playerOneMafiaAccuracy = (
      (props.playerOneAccuracy.mafia.positive /
        props.playerOneAccuracy.mafia.total) *
      100
    ).toFixed(1);
  }
  if (props.playerOneAccuracy.sheriff) {
    playerOneTotalVotes += props.playerOneAccuracy.sheriff.total;
    playerOnePositiveVotes += props.playerOneAccuracy.sheriff.positive;

    playerOneSheriffAccuracy = (
      (props.playerOneAccuracy.sheriff.positive /
        props.playerOneAccuracy.sheriff.total) *
      100
    ).toFixed(1);
  }
  if (props.playerOneAccuracy.godfather) {
    playerOneTotalVotes += props.playerOneAccuracy.godfather.total;
    playerOnePositiveVotes += props.playerOneAccuracy.godfather.positive;

    playerOneGodfatherAccuracy = (
      (props.playerOneAccuracy.godfather.positive /
        props.playerOneAccuracy.godfather.total) *
      100
    ).toFixed(1);
  }

  if (props.playerTwoAccuracy.civilian) {
    playerTwoTotalVotes += props.playerTwoAccuracy.civilian.total;
    playerTwoPositiveVotes += props.playerTwoAccuracy.civilian.positive;

    playerTwoCivilAccuracy = (
      (props.playerTwoAccuracy.civilian.positive /
        props.playerTwoAccuracy.civilian.total) *
      100
    ).toFixed(1);
  }
  if (props.playerTwoAccuracy.mafia) {
    playerTwoTotalVotes += props.playerTwoAccuracy.mafia.total;
    playerTwoPositiveVotes += props.playerTwoAccuracy.mafia.positive;

    playerTwoMafiaAccuracy = (
      (props.playerTwoAccuracy.mafia.positive /
        props.playerTwoAccuracy.mafia.total) *
      100
    ).toFixed(1);
  }
  if (props.playerTwoAccuracy.sheriff) {
    playerTwoTotalVotes += props.playerTwoAccuracy.sheriff.total;
    playerTwoPositiveVotes += props.playerTwoAccuracy.sheriff.positive;

    playerTwoSheriffAccuracy = (
      (props.playerTwoAccuracy.sheriff.positive /
        props.playerTwoAccuracy.sheriff.total) *
      100
    ).toFixed(1);
  }
  if (props.playerTwoAccuracy.godfather) {
    playerTwoTotalVotes += props.playerTwoAccuracy.godfather.total;
    playerTwoPositiveVotes += props.playerTwoAccuracy.godfather.positive;

    playerTwoGodfatherAccuracy = (
      (props.playerTwoAccuracy.godfather.positive /
        props.playerTwoAccuracy.godfather.total) *
      100
    ).toFixed(1);
  }

  playerOneAccuracy = (
    (playerOnePositiveVotes / playerOneTotalVotes) *
    100
  ).toFixed(1);

  playerTwoAccuracy = (
    (playerTwoPositiveVotes / playerTwoTotalVotes) *
    100
  ).toFixed(1);

  let playerOneMissesPercent = '0';
  let playerTwoMissesPercent = '0';

  if (props.playerOneRoles.Mafia) {
    let played = props.playerOneRoles.Mafia.gamesPlayed;
    if (props.playerOneRoles.Godfather) {
      played += props.playerOneRoles.Godfather.gamesPlayed;
    }
    playerOneMissesPercent = (
      (props.playerOneNight.misses / played) *
      100
    ).toFixed(1);
  }

  if (props.playerTwoRoles.Mafia) {
    let played = props.playerTwoRoles.Mafia.gamesPlayed;
    if (props.playerTwoRoles.Godfather) {
      played += props.playerTwoRoles.Godfather.gamesPlayed;
    }
    playerTwoMissesPercent = (
      (props.playerTwoNight.misses / played) *
      100
    ).toFixed(1);
  }

  let playerOneKilledAtFirstNightPercent = '0';
  let playerTwoKilledAtFirstNightPercent = '0';
  if (
    props.playerOneNight.firstKilled.civilian > 0 &&
    props.playerOneRoles.Civilian
  ) {
    playerOneKilledAtFirstNightPercent = (
      (props.playerOneNight.firstKilled.civilian /
        props.playerOneRoles.Civilian.gamesPlayed) *
      100
    ).toFixed(1);
  }
  if (
    props.playerTwoNight.firstKilled.civilian > 0 &&
    props.playerTwoRoles.Civilian
  ) {
    playerTwoKilledAtFirstNightPercent = (
      (props.playerTwoNight.firstKilled.civilian /
        props.playerTwoRoles.Civilian.gamesPlayed) *
      100
    ).toFixed(1);
  }

  let playerOneSheriffKilledAtFirstNightPercent = '0';
  let playerTwoSheriffKilledAtFirstNightPercent = '0';
  if (
    props.playerOneNight.firstKilled.sheriff > 0 &&
    props.playerOneRoles.Sheriff
  ) {
    playerOneSheriffKilledAtFirstNightPercent = (
      (props.playerOneNight.firstKilled.sheriff /
        props.playerOneRoles.Sheriff.gamesPlayed) *
      100
    ).toFixed(1);
  }
  if (
    props.playerTwoNight.firstKilled.sheriff > 0 &&
    props.playerTwoRoles.Sheriff
  ) {
    playerTwoSheriffKilledAtFirstNightPercent = (
      (props.playerTwoNight.firstKilled.sheriff /
        props.playerTwoRoles.Sheriff.gamesPlayed) *
      100
    ).toFixed(1);
  }

  let playerOneDonSheriffKilledAtFirstNightPercent = '0';
  let playerTwoDonSheriffKilledAtFirstNightPercent = '0';
  if (
    props.playerOneNight.sheriffsKilledAtFirstNight > 0 &&
    props.playerOneRoles.Godfather
  ) {
    playerOneDonSheriffKilledAtFirstNightPercent = (
      (props.playerOneNight.sheriffsKilledAtFirstNight /
        props.playerOneRoles.Godfather.gamesPlayed) *
      100
    ).toFixed(1);
  }
  if (
    props.playerTwoNight.sheriffsKilledAtFirstNight > 0 &&
    props.playerTwoRoles.Godfather
  ) {
    playerTwoDonSheriffKilledAtFirstNightPercent = (
      (props.playerTwoNight.sheriffsKilledAtFirstNight /
        props.playerTwoRoles.Godfather.gamesPlayed) *
      100
    ).toFixed(1);
  }

  let playerOneSheriffDonFoundAtFirstNightPercent = '0';
  let playerTwoSheriffDonFoundAtFirstNightPercent = '0';
  if (
    props.playerOneNight.blackChecksAtFirstNight > 0 &&
    props.playerOneRoles.Sheriff
  ) {
    playerOneSheriffDonFoundAtFirstNightPercent = (
      (props.playerOneNight.blackChecksAtFirstNight /
        props.playerOneRoles.Sheriff.gamesPlayed) *
      100
    ).toFixed(1);
  }
  if (
    props.playerTwoNight.blackChecksAtFirstNight > 0 &&
    props.playerTwoRoles.Sheriff
  ) {
    playerTwoSheriffDonFoundAtFirstNightPercent = (
      (props.playerTwoNight.blackChecksAtFirstNight /
        props.playerTwoRoles.Sheriff.gamesPlayed) *
      100
    ).toFixed(1);
  }

  let playerOneDonSheriffCheckedAtFirstNightPercent = '0';
  let playerTwoDonSheriffCheckedAtFirstNightPercent = '0';
  if (
    props.playerOneNight.sheriffsFoundAtFirstNight > 0 &&
    props.playerOneRoles.Godfather
  ) {
    playerOneDonSheriffCheckedAtFirstNightPercent = (
      (props.playerOneNight.sheriffsFoundAtFirstNight /
        props.playerOneRoles.Godfather.gamesPlayed) *
      100
    ).toFixed(1);
  }
  if (
    props.playerTwoNight.sheriffsFoundAtFirstNight > 0 &&
    props.playerTwoRoles.Godfather
  ) {
    playerTwoDonSheriffCheckedAtFirstNightPercent = (
      (props.playerTwoNight.sheriffsFoundAtFirstNight /
        props.playerTwoRoles.Godfather.gamesPlayed) *
      100
    ).toFixed(1);
  }

  return (
    <Container>
      <Content>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '50%' }}>Общее</th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerOneInfo.username}
                </th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerTwoInfo.username}
                </th>
              </tr>
            </thead>
            <tbody>
              <Row>
                <PaddedColumn>
                  <span>Ранк</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerOneInfo.rank}{' '}
                    {getRankDiff(
                      props.playerOneInfo.rank!,
                      props.playerTwoInfo.rank!
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerTwoInfo.rank}{' '}
                    {getRankDiff(
                      props.playerTwoInfo.rank!,
                      props.playerOneInfo.rank!
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Винрейт%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneWR} {getDiffElement(+playerOneWR, +playerTwoWR)}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoWR} {getDiffElement(+playerTwoWR, +playerOneWR)}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Точность голосования%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneAccuracy}{' '}
                    {getDiffElement(+playerOneAccuracy, +playerTwoAccuracy)}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoAccuracy}{' '}
                    {getDiffElement(+playerTwoAccuracy, +playerOneAccuracy)}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Количество часов</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerOneInfo.hoursPlayed}{' '}
                    {getDiffElement(
                      props.playerOneInfo.hoursPlayed,
                      props.playerTwoInfo.hoursPlayed
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerTwoInfo.hoursPlayed}{' '}
                    {getDiffElement(
                      props.playerTwoInfo.hoursPlayed,
                      props.playerOneInfo.hoursPlayed
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Количество игр</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerOneInfo.gamesLost +
                      props.playerOneInfo.gamesWon}{' '}
                    {getDiffElement(
                      props.playerOneInfo.gamesLost +
                        props.playerOneInfo.gamesWon,
                      props.playerTwoInfo.gamesLost +
                        props.playerTwoInfo.gamesWon
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerTwoInfo.gamesLost +
                      props.playerTwoInfo.gamesWon}{' '}
                    {getDiffElement(
                      props.playerTwoInfo.gamesLost +
                        props.playerTwoInfo.gamesWon,
                      props.playerOneInfo.gamesLost +
                        props.playerOneInfo.gamesWon
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>NTP</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerOneInfo.ntp || 0}{' '}
                    {getDiffElement(
                      props.playerOneInfo.ntp || 0,
                      props.playerTwoInfo.ntp || 0
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {props.playerTwoInfo.ntp || 0}{' '}
                    {getDiffElement(
                      props.playerTwoInfo.ntp || 0,
                      props.playerOneInfo.ntp || 0
                    )}
                  </span>
                </PaddedColumn>
              </Row>
            </tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '50%' }}>Мирный</th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerOneInfo.username}
                </th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerTwoInfo.username}
                </th>
              </tr>
            </thead>
            <tbody>
              <Row>
                <PaddedColumn>
                  <span>Винрейт%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneCivilWR}{' '}
                    {getDiffElement(+playerOneCivilWR, +playerTwoCivilWR)}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoCivilWR}{' '}
                    {getDiffElement(+playerTwoCivilWR, +playerOneCivilWR)}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Точность голосования%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneCivilAccuracy}{' '}
                    {getDiffElement(
                      +playerOneCivilAccuracy,
                      +playerTwoCivilAccuracy
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoCivilAccuracy}{' '}
                    {getDiffElement(
                      +playerTwoCivilAccuracy,
                      +playerOneCivilAccuracy
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Убит в первую ночь%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneKilledAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerOneKilledAtFirstNightPercent,
                      +playerTwoKilledAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoKilledAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerTwoKilledAtFirstNightPercent,
                      +playerOneKilledAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Количество игр</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneCivilTotalGames}{' '}
                    {getDiffElement(
                      playerOneCivilTotalGames,
                      playerTwoCivilTotalGames
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoCivilTotalGames}{' '}
                    {getDiffElement(
                      playerTwoCivilTotalGames,
                      playerOneCivilTotalGames
                    )}
                  </span>
                </PaddedColumn>
              </Row>
            </tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '50%' }}>Мафия</th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerOneInfo.username}
                </th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerTwoInfo.username}
                </th>
              </tr>
            </thead>
            <tbody>
              <Row>
                <PaddedColumn>
                  <span>Винрейт%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneMafiaWR}{' '}
                    {getDiffElement(+playerOneMafiaWR, +playerTwoMafiaWR)}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoMafiaWR}{' '}
                    {getDiffElement(+playerTwoMafiaWR, +playerOneMafiaWR)}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Точность голосования%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneMafiaAccuracy}{' '}
                    {getDiffElement(
                      +playerOneMafiaAccuracy,
                      +playerTwoMafiaAccuracy
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoMafiaAccuracy}{' '}
                    {getDiffElement(
                      +playerTwoMafiaAccuracy,
                      +playerOneMafiaAccuracy
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Промахи%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneMissesPercent}{' '}
                    {getRankDiff(
                      +playerOneMissesPercent,
                      +playerTwoMissesPercent
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoMissesPercent}{' '}
                    {getRankDiff(
                      +playerTwoMissesPercent,
                      +playerOneMissesPercent
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Количество игр</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneMafiaTotalGames}{' '}
                    {getDiffElement(
                      playerOneMafiaTotalGames,
                      playerTwoMafiaTotalGames
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoMafiaTotalGames}{' '}
                    {getDiffElement(
                      playerTwoMafiaTotalGames,
                      playerOneMafiaTotalGames
                    )}
                  </span>
                </PaddedColumn>
              </Row>
            </tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '50%' }}>Шериф</th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerOneInfo.username}
                </th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerTwoInfo.username}
                </th>
              </tr>
            </thead>
            <tbody>
              <Row>
                <PaddedColumn>
                  <span>Винрейт%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneSheriffWR}{' '}
                    {getDiffElement(+playerOneSheriffWR, +playerTwoSheriffWR)}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoSheriffWR}{' '}
                    {getDiffElement(+playerTwoSheriffWR, +playerOneSheriffWR)}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Точность голосования%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneSheriffAccuracy}{' '}
                    {getDiffElement(
                      +playerOneSheriffAccuracy,
                      +playerTwoSheriffAccuracy
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoSheriffAccuracy}{' '}
                    {getDiffElement(
                      +playerTwoSheriffAccuracy,
                      +playerOneSheriffAccuracy
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Убит в первую ночь%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneSheriffKilledAtFirstNightPercent}{' '}
                    {getRankDiff(
                      +playerOneSheriffKilledAtFirstNightPercent,
                      +playerTwoSheriffKilledAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoSheriffKilledAtFirstNightPercent}{' '}
                    {getRankDiff(
                      +playerTwoSheriffKilledAtFirstNightPercent,
                      +playerOneSheriffKilledAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Черных проверок в первую ночь%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneSheriffDonFoundAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerOneSheriffDonFoundAtFirstNightPercent,
                      +playerTwoSheriffDonFoundAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoSheriffDonFoundAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerTwoSheriffDonFoundAtFirstNightPercent,
                      +playerOneSheriffDonFoundAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Количество игр</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneSheriffTotalGames}{' '}
                    {getDiffElement(
                      playerOneSheriffTotalGames,
                      playerTwoSheriffTotalGames
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoSheriffTotalGames}{' '}
                    {getDiffElement(
                      playerTwoSheriffTotalGames,
                      playerOneSheriffTotalGames
                    )}
                  </span>
                </PaddedColumn>
              </Row>
            </tbody>
          </Table>
        </TableContainer>
        <TableContainer>
          <Table>
            <thead>
              <tr>
                <th style={{ textAlign: 'left', width: '50%' }}>Дон</th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerOneInfo.username}
                </th>
                <th
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {props.playerTwoInfo.username}
                </th>
              </tr>
            </thead>
            <tbody>
              <Row>
                <PaddedColumn>
                  <span>Винрейт%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneGodfatherWR}{' '}
                    {getDiffElement(
                      +playerOneGodfatherWR,
                      +playerTwoGodfatherWR
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoGodfatherWR}{' '}
                    {getDiffElement(
                      +playerTwoGodfatherWR,
                      +playerOneGodfatherWR
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Точность голосования%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneGodfatherAccuracy}{' '}
                    {getDiffElement(
                      +playerOneGodfatherAccuracy,
                      +playerTwoGodfatherAccuracy
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoGodfatherAccuracy}{' '}
                    {getDiffElement(
                      +playerTwoGodfatherAccuracy,
                      +playerOneGodfatherAccuracy
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Шериф убит в первую ночь%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneDonSheriffKilledAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerOneDonSheriffKilledAtFirstNightPercent,
                      +playerTwoDonSheriffKilledAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoDonSheriffKilledAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerTwoDonSheriffKilledAtFirstNightPercent,
                      +playerOneDonSheriffKilledAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Шериф найден в первую ночь%</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneDonSheriffCheckedAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerOneDonSheriffCheckedAtFirstNightPercent,
                      +playerTwoDonSheriffCheckedAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoDonSheriffCheckedAtFirstNightPercent}{' '}
                    {getDiffElement(
                      +playerTwoDonSheriffCheckedAtFirstNightPercent,
                      +playerOneDonSheriffCheckedAtFirstNightPercent
                    )}
                  </span>
                </PaddedColumn>
              </Row>
              <Row>
                <PaddedColumn>
                  <span>Количество игр</span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerOneGodfatherTotalGames}{' '}
                    {getDiffElement(
                      playerOneGodfatherTotalGames,
                      playerTwoGodfatherTotalGames
                    )}
                  </span>
                </PaddedColumn>
                <PaddedColumn>
                  <span>
                    {playerTwoGodfatherTotalGames}{' '}
                    {getDiffElement(
                      playerTwoGodfatherTotalGames,
                      playerOneGodfatherTotalGames
                    )}
                  </span>
                </PaddedColumn>
              </Row>
            </tbody>
          </Table>
        </TableContainer>
      </Content>
    </Container>
  );
};

const getRankDiff = (rank1: number, rank2: number) => {
  const diff = rank1 - rank2;

  if (diff === 0) {
    return null;
  }

  const goodColor = '#baefba';
  const badColor = '#f24462';

  if (diff < 0) {
    return (
      <span>
        (
        <span style={{ color: goodColor }}>
          <ArrowUp width={16} height={16}></ArrowUp>
          {Math.abs(diff) % 1 === 0
            ? Math.abs(diff)
            : Math.abs(diff).toFixed(1)}
        </span>
        )
      </span>
    );
  }

  return (
    <span>
      (
      <span style={{ color: badColor }}>
        <ArrowDown width={16} height={16}></ArrowDown>
        {Math.abs(diff) % 1 === 0 ? Math.abs(diff) : Math.abs(diff).toFixed(1)}
      </span>
      )
    </span>
  );
};

const getDiffElement = (num1: number, num2: number) => {
  const diff = num1 - num2;

  if (diff === 0) {
    return null;
  }

  const goodColor = '#baefba';
  const badColor = '#f24462';

  if (diff < 0) {
    return (
      <span>
        (
        <span style={{ color: badColor }}>
          <ArrowDown width={16} height={16}></ArrowDown>
          {Math.abs(diff) % 1 === 0
            ? Math.abs(diff)
            : Math.abs(diff).toFixed(1)}
        </span>
        )
      </span>
    );
  }

  return (
    <span>
      (
      <span style={{ color: goodColor }}>
        <ArrowUp width={16} height={16}></ArrowUp>
        {diff % 1 === 0 ? diff : diff.toFixed(1)}
      </span>
      )
    </span>
  );
};
