import React, { useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../components/UI/Input';
import { LoadingButton } from '../../components/UI/LoadingButton';
import { Error } from '../../components/UI/Error';
import { Success } from '../../components/UI/Success';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
`;

const RadioGroup = styled.div`
  display: flex;
  color: white;

  input {
    margin-right: 4px;
  }
`;

export const AddPlayer = () => {
  const axios = useAxiosPrivate();
  const [type, setType] = useState<string>('id');
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const [username, setUsername] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const onRadioChange = (val: string) => {
    if (val === 'По имени') {
      setType('name');
    } else {
      setType('id');
    }
  };

  const handleClick = () => {
    const doRequest = async () => {
      setLoading(true);
      setError(undefined);
      setSuccess(false);

      if (type === 'id') {
        try {
          await axios.post(`/admin/players/by_id?id=${username}`);
          setSuccess(true);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      }

      if (type === 'name') {
        try {
          await axios.post(`/admin/players/by_name?name=${username}`);
          setSuccess(true);
        } catch (error) {
          setError(error);
        } finally {
          setLoading(false);
        }
      }
    };
    doRequest();
  };

  return (
    <Container>
      <RadioGroup>
        <input
          type="radio"
          value="По имени"
          name="type"
          onChange={(e) => onRadioChange(e.target.value)}
          checked={type === 'name'}
        />{' '}
        По имени
        <input
          type="radio"
          value="По айди"
          name="type"
          onChange={(e) => onRadioChange(e.target.value)}
          checked={type === 'id'}
        />{' '}
        По айди
      </RadioGroup>
      <Input
        placeholder={type === 'id' ? 'айди игрока' : 'имя игрока'}
        type={type === 'name' ? 'text' : 'number'}
        onChange={(str) => setUsername(str)}
      />
      <LoadingButton
        isLoading={loading}
        text="Добавить"
        onClick={handleClick}
      />
      {error && <Error error={error} />}
      {success && (
        <Success text="Игрок добавлен. Когда-нибудь его игры подтянутся" />
      )}
    </Container>
  );
};
