import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

const Button = styled.a`
  background-color: #f59407;
  padding: 12px 24px;
  border-radius: 12px;
  outline: none;
  border: none;
  transition: 0.3s background-color;
  color: black;
  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: #dabb0e;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 12px;
`;

export const SupportPage = () => {
  return (
    <Container>
      <p>
        Если есть желание поддержать проект, можно задонатить через систему
        DonationAlerts. Деньги пойдут на оплату сервера и домена для поддержания
        работы notoxic.pro. Остальное пойдет на призовые для турниров No Toxic.
      </p>
      <ButtonContainer>
        <Button href="https://www.donationalerts.com/r/florean" target="_blank">
          Поддержать
        </Button>
      </ButtonContainer>
    </Container>
  );
};
