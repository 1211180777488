import React from 'react';
import woland from '../../assets/img/woland.png';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
`;

export const NotFoundPage = () => {
  return (
    <Container>
      <img src="https://a.notoxic.pro/misc/woland.png"></img>
      <span>Тут ничего нет, кроме курящего воланда. </span>
      <NavLink to="/" style={{ color: 'var(--red-accent)' }}>
        Вернуться в безопасное место
      </NavLink>
    </Container>
  );
};
