import React from 'react';
import styled from 'styled-components';

interface Props {
  avatarUrl?: string;
  widthpx: number;
  heightpx: number;
  radiuspx: string;
  className?: string;
  children?: React.ReactNode;
}

const Container = styled.div<Props>`
  width: ${(props) => props.widthpx + 'px'};
  height: ${(props) => props.heightpx + 'px'};
  border-radius: ${(props) => props.radiuspx};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ avatarUrl }) =>
    avatarUrl
      ? `url('${avatarUrl}')`
      : `url('https://a.notoxic.pro/avatars/default.png')`};
  background-color: var(--red-accent-light);
  position: relative;
`;

export const Avatar: React.FC<Props> = (props) => {
  let avatarUrl = props.avatarUrl;

  return (
    <Container
      avatarUrl={avatarUrl}
      widthpx={props.widthpx}
      heightpx={props.heightpx}
      radiuspx={props.radiuspx}
      className={props.className}
    >
      {props.children}
    </Container>
  );
};
