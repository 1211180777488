import React, { useEffect, useState } from 'react';
import { PaginatedResponse, Game } from '../../models';
import { fetchGames } from '../../lib/api';
import { LoadingSpinner } from '../UI/LoadingSpinner';
import { GamesTable } from './GamesTable';
import styled from 'styled-components';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { Error } from '../UI/Error';

interface Props {
  userId?: number;
  enablePagination?: boolean;
  itemsPerPage: number;
}

const LoadingContainer = styled.div`
  width: 100;
  height: 500px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Pagination = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const PaginationButton = styled.button<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--gray-accent-light)' : 'var(--red-accent)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 6px 12px;
  border-radius: 24px;
  /* margin-right: 50px; */
  transition: background-color 0.3s;

  /* &:last-child {
    margin-right: 0;
  } */

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'var(--gray-accent-light)' : 'var(--red-accent-light)'};
  }
`;

export const Games: React.FC<Props> = (props) => {
  const [games, setGames] = useState<PaginatedResponse<Game>>();
  const [nextToken, setNextToken] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    const doRequest = async () => {
      try {
        const resp = await fetchGames(
          props.userId,
          props.itemsPerPage,
          nextToken
        );
        setGames(resp);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [props.itemsPerPage, props.userId, nextToken]);

  const handlePrevPage = () => {
    if (games!.prevToken === '') return;

    setNextToken(games!.prevToken);
  };
  const handleNextPage = () => {
    if (games!.nextToken === '') return;

    setNextToken(games!.nextToken);
  };

  if (loading)
    return (
      <LoadingContainer>
        <LoadingSpinner />
      </LoadingContainer>
    );

  if (error) return <Error error={error} />;

  return (
    <Container>
      <GamesTable games={games!.items} forUser={!!props.userId} />
      <Pagination>
        <PaginationButton
          disabled={games!.prevToken === ''}
          onClick={handlePrevPage}
        >
          <ArrowLeft size={24}></ArrowLeft>
        </PaginationButton>
        <PaginationButton
          disabled={games!.nextToken === ''}
          onClick={handleNextPage}
        >
          <ArrowRight size={24}></ArrowRight>
        </PaginationButton>
      </Pagination>
    </Container>
  );
};
