import React, { useEffect, useState } from 'react';
import { Card } from '../../components/UI/Card';
import styled from 'styled-components';
import { SearchV2 } from '../../components/search/SearchV2';
import {
  Player,
  PlayerSearchResult,
  PlayerStat,
  AccuracyStat,
  NightStat,
} from '../../models';
import { Avatar } from '../../components/UI/Avatar';
import { LoadingButton } from '../../components/UI/LoadingButton';
import { info, roleStats, fetchAccuracy, fetchNightStats } from '../../lib/api';
import { Versus } from '../../components/versus/Versus';
import { Error } from '../../components/UI/Error';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 400px;
  min-height: 157px;
  align-items: center;
  justify-content: center;
`;

const PlayerName = styled.span`
  color: white;
  margin-bottom: 8px;
  font-size: 24px;
  margin-bottom: 12px;
`;

const PlayerInfo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  background-color: var(--gray-accent);
  border-radius: 8px;
  width: 400px;
`;

const Username = styled.span`
  font-size: 32px;
  color: white;
  margin-left: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 50%;
`;

const Cross = styled.button`
  position: absolute;
  top: 0;
  right: 4px;
  font-size: 24px;
  color: white;
  border: 0;
  outline: 0;
  background: none;
  cursor: pointer;
`;

const VersusText = styled.span`
  font-size: 64px;
  color: white;
  align-self: center;
  text-shadow: -1px -1px 0 var(--red-accent), 1px -1px 0 var(--red-accent),
    -1px 1px 0 var(--red-accent), 1px 1px 0 var(--red-accent);
`;

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VersusPage = () => {
  const [playerOne, setPlayerOne] = useState<PlayerSearchResult>();
  const [playerTwo, setPlayerTwo] = useState<PlayerSearchResult>();
  const [playerOneRoles, setPlayerOneRoles] = useState<PlayerStat>();
  const [playerTwoRoles, setPlayerTwoRoles] = useState<PlayerStat>();
  const [playerOneInfo, setPlayerOneInfo] = useState<Player>();
  const [playerTwoInfo, setPlayerTwoInfo] = useState<Player>();
  const [playerOneAccuracy, setPlayerOneAccuracy] = useState<AccuracyStat>();
  const [playerTwoAccuracy, setPlayerTwoAccuracy] = useState<AccuracyStat>();
  const [playerOneNight, setPlayerOneNight] = useState<NightStat>();
  const [playerTwoNight, setPlayerTwoNight] = useState<NightStat>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();

  const selectPlayerOne = (player: PlayerSearchResult) => {
    setPlayerOne(player);
  };

  const selectPlayerTwo = (player: PlayerSearchResult) => {
    setPlayerTwo(player);
  };

  const handleClick = () => {
    setIsLoading(true);
    setError(undefined);

    const doRequest = async () => {
      try {
        const [
          playerOneInfoResp,
          playerTwoInfoResp,
          playerOneRolesResp,
          playerTwoRolesResp,
          playerOneAccuracyResp,
          playerTwoAccuracyResp,
          playerOneNightResp,
          playerTwoNightResp,
        ] = await Promise.all([
          info(playerOne!.userId),
          info(playerTwo!.userId),
          roleStats(playerOne!.userId),
          roleStats(playerTwo!.userId),
          fetchAccuracy(playerOne!.userId),
          fetchAccuracy(playerTwo!.userId),
          fetchNightStats(playerOne!.userId),
          fetchNightStats(playerTwo!.userId),
        ]);
        setPlayerOneInfo(playerOneInfoResp);
        setPlayerTwoInfo(playerTwoInfoResp);
        setPlayerOneRoles(playerOneRolesResp);
        setPlayerTwoRoles(playerTwoRolesResp);
        setPlayerOneAccuracy(playerOneAccuracyResp);
        setPlayerTwoAccuracy(playerTwoAccuracyResp);
        setPlayerOneNight(playerOneNightResp);
        setPlayerTwoNight(playerTwoNightResp);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    doRequest();
  };

  useEffect(() => {
    setPlayerOneInfo(undefined);
    setPlayerTwoInfo(undefined);
    setPlayerOneRoles(undefined);
    setPlayerTwoRoles(undefined);
    setPlayerOneAccuracy(undefined);
    setPlayerTwoAccuracy(undefined);
    setPlayerOneNight(undefined);
    setPlayerTwoNight(undefined);
  }, [playerOne, playerTwo]);

  return (
    <Card>
      <Container>
        <PlayerContainer>
          <PlayerName>Игрок 1</PlayerName>
          {!playerOne && !playerTwo && (
            <SearchV2 onResultSelected={selectPlayerOne} />
          )}
          {!playerOne && playerTwo && (
            <SearchV2
              onResultSelected={selectPlayerOne}
              filteredPlayers={[playerTwo.userId]}
            />
          )}
          {playerOne && (
            <PlayerInfo>
              <Cross
                onClick={() => {
                  setPlayerOne(undefined);
                }}
              >
                &times;
              </Cross>
              <Avatar
                avatarUrl={playerOne.avatarUrl}
                heightpx={100}
                widthpx={100}
                radiuspx="50%"
              ></Avatar>
              <Username title={playerOne.username}>
                {playerOne.username}
              </Username>
            </PlayerInfo>
          )}
        </PlayerContainer>
        <VersusText>VERSUS</VersusText>
        <PlayerContainer>
          <PlayerName>Игрок 2</PlayerName>
          {!playerTwo && !playerOne && (
            <SearchV2 onResultSelected={selectPlayerTwo} />
          )}
          {!playerTwo && playerOne && (
            <SearchV2
              onResultSelected={selectPlayerTwo}
              filteredPlayers={[playerOne.userId]}
            />
          )}
          {playerTwo && (
            <PlayerInfo>
              <Cross
                onClick={() => {
                  setPlayerTwo(undefined);
                }}
              >
                &times;
              </Cross>
              <Avatar
                avatarUrl={playerTwo.avatarUrl}
                heightpx={100}
                widthpx={100}
                radiuspx="50%"
              ></Avatar>
              <Username title={playerTwo.username}>
                {playerTwo.username}
              </Username>
            </PlayerInfo>
          )}
        </PlayerContainer>
      </Container>

      {playerOne && playerTwo && (
        <ResultContainer>
          <LoadingButton
            isLoading={isLoading}
            text="Сравнить"
            onClick={handleClick}
          ></LoadingButton>

          {!isLoading && error && <Error error={error} />}

          {!isLoading &&
            !error &&
            playerOneInfo &&
            playerTwoInfo &&
            playerOneRoles &&
            playerTwoRoles &&
            playerOneAccuracy &&
            playerTwoAccuracy &&
            playerOneNight &&
            playerTwoNight && (
              <Versus
                playerOneInfo={playerOneInfo}
                playerTwoInfo={playerTwoInfo}
                playerOneRoles={playerOneRoles}
                playerTwoRoles={playerTwoRoles}
                playerOneAccuracy={playerOneAccuracy}
                playerTwoAccuracy={playerTwoAccuracy}
                playerOneNight={playerOneNight}
                playerTwoNight={playerTwoNight}
              />
            )}
        </ResultContainer>
      )}
    </Card>
  );
};
