import React, { useEffect, useState } from 'react';
import { Tournament } from '../models';
import { fetchTournaments } from '../lib/api';
import { LoadingSpinner } from '../components/UI/LoadingSpinner';
import { Error } from '../components/UI/Error';
import { List } from '../components/tournaments/List';

export const TournamentListPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [tournaments, setTournaments] = useState<Map<number, Tournament[]>>();
  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await fetchTournaments();
        const res: Map<number, Tournament[]> = new Map();
        for (const t of resp) {
          const year = t.startedAt.getFullYear();
          const existing = res.get(year) || [];
          existing.push(t);
          res.set(year, existing);
        }
        setTournaments(res);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <List tournaments={tournaments!} />;
};
