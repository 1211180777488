import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { fetchMonthActivity, info } from '../../lib/api';
import { Player } from '../../models/player';
import { Panel } from '../../components/profile/Panel';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import styled from 'styled-components';
import { NavLink as BaseNavLink } from 'react-router-dom';
import { Error } from '../../components/UI/Error';
import { CEO } from '../../components/CEO/CEO';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Navigation = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 12px;
`;

const NavLink = styled(BaseNavLink)`
  padding: 8px;
  color: white;
  text-align: center;
  background-color: var(--gray-accent);
  width: 100%;
  /* margin-right: 4px; */
  transition: background-color 0.3s;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
    background-color: var(--red-accent-light);
  }

  &.active {
    background-color: var(--red-accent-light);
  }
`;

export const ProfilePage = () => {
  const [player, setPlayer] = useState<Player>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const doRequest = async () => {
      try {
        const resp = await info(+params.playerId!);
        setPlayer(resp);
        setLoading(false);
      } catch (error: any) {
        if (error.response?.status === 404) {
          navigate('/notfound', { replace: true });
          return;
        }
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [params.playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return (
    <Container>
      <CEO
        title={'Профиль игрока ' + player?.username}
        description={`Игрок ${player?.username}, ранк ${player?.rank}`}
        image={player?.avatarUrl}
      />
      <Panel player={player!} />
      <Navigation>
        <NavLink to="overview">Обзор</NavLink>
        <NavLink to="games">Матчи</NavLink>
        <NavLink to="partners">Партнеры</NavLink>
        <NavLink to="stats">Статистика</NavLink>
        <NavLink to="night">Ночь</NavLink>
        <NavLink to="slots">Слоты</NavLink>
        <NavLink to="activity">Активность</NavLink>
        <NavLink to="bans">Баны</NavLink>
        <NavLink to="killers">Убийцы</NavLink>
      </Navigation>
      <Outlet />
    </Container>
  );
};
