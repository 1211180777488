import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Input } from '../../components/UI/Input';
import { LoadingButton } from '../../components/UI/LoadingButton';
import { Minus, Pencil } from 'phosphor-react';
import { SearchV2 } from '../../components/search/SearchV2';
import { PlayerSearchResult } from '../../models';
import { Column, Row, Table } from '../../components/UI/Table';
import { TextArea } from '../../components/UI/TextArea';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Error } from '../../components/UI/Error';
import { Success } from '../../components/UI/Success';

type TablePlayer = {
  id: number;
  username: string;
  points: number;
  placement: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const BadgeRow = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
`;

const Badge = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

const BadgeTitle = styled.span`
  color: white;
  font-size: 12px;
`;

const BadgeContainer = styled.div`
  width: 86px;
  height: 40px;
  position: relative;
`;

const BadgeImg = styled.img`
  width: 86px;
  height: 40px;
  outline: none;
  border: none;
`;

const BadgeOverlay = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  color: transparent;
  transition: 0.3s background-color;

  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    cursor: pointer;
  }
`;

const PlayerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const RadioGroup = styled.div`
  display: flex;
  color: white;

  input {
    margin-right: 4px;
  }
`;

export const AddTournament = () => {
  const [selectedPlayer, setSelectedPlayer] = useState<PlayerSearchResult>();
  const [players, setPlayers] = useState<TablePlayer[]>([]);
  const pointsRef = useRef<HTMLInputElement>(null);
  const placeRef = useRef<HTMLInputElement>(null);
  const [type, setType] = useState<string>('allstars');
  const [defaultBadgeSrc, setDefaultBadgeSrc] = useState<string>('');
  const [firstPlaceBadgeSrc, setFirstPlaceBadgeSrc] = useState<string>('');
  const [secondPlaceBadgeSrc, setSecondPlaceBadgeSrc] = useState<string>('');
  const [thirdPlaceBadgeSrc, setThirdPlaceBadgeSrc] = useState<string>('');
  const [hqBadgeSrc, setHQBadgeSrc] = useState<string>('');
  const [defaultBadgeBlob, setDefaultBadgeBlob] = useState<Blob>();
  const [firstPlaceBadgeBlob, setFirstPlaceBadgeBlob] = useState<Blob>();
  const [secondPlaceBadgeBlob, setSecondPlaceBadgeBlob] = useState<Blob>();
  const [thirdPlaceBadgeBlob, setThirdPlaceBadgeBlob] = useState<Blob>();
  const [hqBadgeBlob, setHQBadgeBlob] = useState<Blob>();
  const defaultBadgeInputRef = useRef<any>(null);
  const firstPlaceBadgeInputRef = useRef<any>(null);
  const secondPlaceBadgeInputRef = useRef<any>(null);
  const thirdPlaceBadgeInputRef = useRef<any>(null);
  const hqBadgeInputRef = useRef<any>(null);
  const [description, setDescription] = useState<string>();
  const [youtubeLinks, setYoutubeLinks] = useState<string>();
  const [vkLinks, setVkLinks] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [name, setName] = useState<string>('');
  const axios = useAxiosPrivate();

  const playerSelected = (player: PlayerSearchResult) => {
    setSelectedPlayer(player);
  };

  const addPlayer = () => {
    setPlayers((prev) => {
      const p: TablePlayer = {
        id: selectedPlayer?.userId || 0,
        username: selectedPlayer?.username || '',
        points: pointsRef.current?.value ? +pointsRef.current.value : 0,
        placement: placeRef.current?.value ? +placeRef.current.value : 0,
      };
      const arr = [...prev, p];
      arr.sort((a: TablePlayer, b: TablePlayer) => a.placement - b.placement);

      return arr;
    });
    setSelectedPlayer(undefined);
  };

  const deletePlayer = (id: number) => {
    setPlayers((prev) => prev.filter((p) => p.id !== id));
  };

  const onRadioChange = (str: string) => {
    setType(str);
  };

  const handleFileSelected = (
    e: React.ChangeEvent<HTMLInputElement>,
    setImgState: any,
    setBlobState: any
  ) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const res = reader.result?.toString() || '';
        setImgState(res);
        fetch(res)
          .then((res) => res.blob())
          .then(setBlobState);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const clickOverlayInput = (ref: any) => {
    ref.current.click();
  };

  const saveButtonClicked = () => {
    setLoading(true);
    setError(undefined);
    setSuccess(false);

    const doRequest = async () => {
      try {
        await axios.postForm(`/admin/tournaments`, {
          name: name,
          description: description,
          youtube_links: youtubeLinks
            ? JSON.stringify(youtubeLinks.split(','))
            : null,
          vk_links: vkLinks ? JSON.stringify(vkLinks.split(',')) : null,
          start_date: startDate,
          end_date: endDate,
          players: JSON.stringify(players),
          default_badge: defaultBadgeBlob,
          first_place_badge: firstPlaceBadgeBlob,
          second_place_badge: secondPlaceBadgeBlob,
          third_place_badge: thirdPlaceBadgeBlob,
          hq_badge: hqBadgeBlob,
          type: type,
        });
        setSuccess(true);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  };

  return (
    <Container>
      <input
        type="file"
        onChange={(e) =>
          handleFileSelected(e, setDefaultBadgeSrc, setDefaultBadgeBlob)
        }
        ref={defaultBadgeInputRef}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png"
      />
      <input
        type="file"
        onChange={(e) =>
          handleFileSelected(e, setFirstPlaceBadgeSrc, setFirstPlaceBadgeBlob)
        }
        ref={firstPlaceBadgeInputRef}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png"
      />
      <input
        type="file"
        onChange={(e) =>
          handleFileSelected(e, setSecondPlaceBadgeSrc, setSecondPlaceBadgeBlob)
        }
        ref={secondPlaceBadgeInputRef}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png"
      />
      <input
        type="file"
        onChange={(e) =>
          handleFileSelected(e, setThirdPlaceBadgeSrc, setThirdPlaceBadgeBlob)
        }
        ref={thirdPlaceBadgeInputRef}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png"
      />
      <input
        type="file"
        onChange={(e) => handleFileSelected(e, setHQBadgeSrc, setHQBadgeBlob)}
        ref={hqBadgeInputRef}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png"
      />
      <Input placeholder="Имя турнира" onChange={(s) => setName(s)} />
      <span style={{ color: 'white' }}>Дата начала</span>
      <input
        type="date"
        onChange={(e) => setStartDate(new Date(e.target.value))}
      />
      <span style={{ color: 'white' }}>Дата конца</span>
      <input
        type="date"
        onChange={(e) => setEndDate(new Date(e.target.value))}
      />
      <RadioGroup>
        <input
          type="radio"
          value="allstars"
          name="type"
          onChange={(e) => onRadioChange(e.target.value)}
          checked={type === 'allstars'}
        />{' '}
        All stars
        <input
          type="radio"
          value="minor"
          name="type"
          onChange={(e) => onRadioChange(e.target.value)}
          checked={type === 'minor'}
        />{' '}
        Minor
        <input
          type="radio"
          value="major"
          name="type"
          onChange={(e) => onRadioChange(e.target.value)}
          checked={type === 'major'}
        />{' '}
        Major
        <input
          type="radio"
          value="other"
          name="type"
          onChange={(e) => onRadioChange(e.target.value)}
          checked={type === 'other'}
        />{' '}
        Other
      </RadioGroup>
      <BadgeRow>
        <Badge>
          <BadgeTitle>Дефолтный бадж</BadgeTitle>
          <BadgeContainer>
            <BadgeOverlay
              onClick={() => clickOverlayInput(defaultBadgeInputRef)}
            >
              <Pencil height={24} width={24} />
            </BadgeOverlay>
            <BadgeImg src={defaultBadgeSrc}></BadgeImg>
          </BadgeContainer>
        </Badge>
        <Badge>
          <BadgeTitle>Бадж за первое место</BadgeTitle>
          <BadgeContainer>
            <BadgeOverlay
              onClick={() => clickOverlayInput(firstPlaceBadgeInputRef)}
            >
              <Pencil height={24} width={24} />
            </BadgeOverlay>
            <BadgeImg src={firstPlaceBadgeSrc}></BadgeImg>
          </BadgeContainer>
        </Badge>
        <Badge>
          <BadgeTitle>Бадж за второе место</BadgeTitle>
          <BadgeContainer>
            <BadgeOverlay
              onClick={() => clickOverlayInput(secondPlaceBadgeInputRef)}
            >
              <Pencil height={24} width={24} />
            </BadgeOverlay>
            <BadgeImg src={secondPlaceBadgeSrc}></BadgeImg>
          </BadgeContainer>
        </Badge>
        <Badge>
          <BadgeTitle>Бадж за третье место</BadgeTitle>
          <BadgeContainer>
            <BadgeOverlay
              onClick={() => clickOverlayInput(thirdPlaceBadgeInputRef)}
            >
              <Pencil height={24} width={24} />
            </BadgeOverlay>
            <BadgeImg src={thirdPlaceBadgeSrc}></BadgeImg>
          </BadgeContainer>
        </Badge>
        <Badge>
          <BadgeTitle>Бадж высокого разрешения</BadgeTitle>
          <BadgeContainer>
            <BadgeOverlay onClick={() => clickOverlayInput(hqBadgeInputRef)}>
              <Pencil height={24} width={24} />
            </BadgeOverlay>
            <BadgeImg src={hqBadgeSrc}></BadgeImg>
          </BadgeContainer>
        </Badge>
      </BadgeRow>
      <TextArea
        placeholder="Описание"
        value={description}
        onChange={(s) => setDescription(s)}
      />
      <TextArea
        placeholder="Ссылки на ютуб через запятую"
        value={youtubeLinks}
        onChange={(s) => setYoutubeLinks(s)}
      />
      <TextArea
        placeholder="Ссылки на вк через запятую (СПЕЦИАЛЬНЫЙ ФОРМАТ!!!!!!!!!! ОБРАЩАЦА К РОБУ)"
        value={vkLinks}
        onChange={(s) => setVkLinks(s)}
      />
      {!selectedPlayer && <SearchV2 onResultSelected={playerSelected} />}
      {selectedPlayer && (
        <PlayerRow>
          <span style={{ color: 'white' }}>{selectedPlayer.username}</span>
          <input placeholder="Баллы" type="number" ref={pointsRef} />
          <input placeholder="Место" type="number" ref={placeRef} />
          <LoadingButton isLoading={false} text="Ок" onClick={addPlayer} />
        </PlayerRow>
      )}
      {players.length > 0 && (
        <Table>
          <thead>
            <tr>
              <th>Место</th>
              <th>Игрок</th>
              <th>Баллы</th>
              <th>Удалить</th>
            </tr>
          </thead>
          <tbody>
            {players.map((player) => (
              <Row key={player.id}>
                <Column>{player.placement}</Column>
                <Column>{player.username}</Column>
                <Column>{player.points}</Column>
                <Column>
                  <Minus
                    height={24}
                    width={24}
                    style={{ cursor: 'pointer' }}
                    onClick={() => deletePlayer(player.id)}
                  />
                </Column>
              </Row>
            ))}
          </tbody>
        </Table>
      )}
      <LoadingButton
        isLoading={loading}
        text="Добавить"
        onClick={saveButtonClicked}
      />
      {error && <Error error={error} />}
      {success && <Success text="Турнир добавлен" />}
    </Container>
  );
};
