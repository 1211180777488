import React from 'react';
import { LeaderboardPlayer } from '../../models/leaderboard';
import defaultavatar from '../../assets/img/defaultavatar.png';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar } from '../UI/Avatar';
import { Flag } from '../../flags/flags';

interface Props {
  player: LeaderboardPlayer;
}

const Rank = styled.td`
  background-color: var(--gray-accent2);
  min-width: 48px;
  text-transform: italic;
  padding: 4px;
`;

const PlayerLink = styled(NavLink)`
  cursor: pointer;
  margin-left: 12px;
  transition: color 0.3s;

  &:hover {
    color: var(--red-accent);
  }
`;

const PlayerContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 4px;
`;

const PlayerAvatar = styled(Avatar)`
  margin-left: 6px;
`;

const FlagContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 20px;
  margin-left: 8px;
  margin-right: 8px;

  img {
    width: 30px;
    height: 20px;
  }
`;

export const LeaderboardItem: React.FC<Props> = (props) => {
  return (
    <>
      <Rank>{props.player.rank}</Rank>
      <td>
        <PlayerContainer>
          {/* <FlagContainer>
            <Flag country={props.player.country}></Flag>
          </FlagContainer> */}
          {/* <div className={classes.flag}>{getFlag(props.player.country)}</div> */}
          {/* <PlayerAvatar avatarUrl={props.player.avatarUrl}></PlayerAvatar> */}
          <PlayerAvatar
            widthpx={30}
            heightpx={30}
            radiuspx="50%"
            avatarUrl={props.player.avatarUrl}
          ></PlayerAvatar>
          <PlayerLink to={`/players/${props.player.userId}`}>
            {props.player.username}
          </PlayerLink>
        </PlayerContainer>
      </td>
      <td>{props.player.totalGames}</td>
      <td>{props.player.civilWr}</td>
      <td>{props.player.mafiaWr}</td>
      <td>{props.player.sheriffWr}</td>
      <td>{props.player.godfatherWr}</td>
      <td>{props.player.wrTotal}</td>
      <td>{(Math.round(props.player.avgPoints * 100) / 100).toFixed(2)}</td>
      <td>
        {props.player.ntp.toLocaleString('en-US', { minimumFractionDigits: 0 })}
      </td>
    </>
  );
};
