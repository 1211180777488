import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Game } from '../../models';
import { Avatar } from '../UI/Avatar';
import { Column, Row, Table } from '../UI/Table';
import { Civilian, Mafia, Sheriff, Godfather } from '../../icons';
import { prettyDate, prettyDateDiff } from '../../lib/date';

interface Props {
  forUser?: boolean;
  games: Game[];
}

const UserContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Username = styled(Link)`
  margin-left: 8px;
  transition: color 0.3s;

  &:hover {
    color: var(--red-accent);
  }
`;

const RoleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const roleToIcon = (role: string) => {
  if (role === 'civilian') {
    return <Civilian />;
  }
  if (role === 'sheriff') {
    return <Sheriff />;
  }
  if (role === 'mafia') {
    return <Mafia />;
  }

  if (role === 'godfather') {
    return <Godfather />;
  }

  return <RoleContainer>Судья</RoleContainer>;
};

const WonLossSpan = styled.span<{ role: string; won: boolean }>`
  color: ${(props) =>
    props.role === 'judge'
      ? 'white'
      : props.won
      ? 'var(--green-accent)'
      : 'var(--red-accent)'};
`;

const PointsSpan = styled.span<{ points: number }>`
  color: ${(props) =>
    props.points > 0
      ? 'var(--green-accent)'
      : props.points < 0
      ? 'var(--red-accent)'
      : 'white'};
`;

const MVPSpan = styled.span<{ isMvp: boolean }>`
  color: ${(props) => (props.isMvp ? 'var(--golden)' : 'white')};
`;

export const GamesTable: React.FC<Props> = (props) => {
  return (
    <Table>
      <thead>
        <tr>
          {!props.forUser && (
            <th style={{ width: '30%', textAlign: 'left' }}>Игрок</th>
          )}
          <th>Роль</th>
          <th>Результат</th>
          <th>Дата</th>
          <th>Длительность</th>
          <th>Баллы</th>
          <th>Слот</th>
          <th>MVP</th>
          <th>NTP</th>
          <th>Действия</th>
        </tr>
      </thead>
      <tbody>
        {props.games.map((game) => (
          <Row key={game.gameId + game.username}>
            {!props.forUser && (
              <Column>
                <UserContainer>
                  <Avatar
                    widthpx={40}
                    heightpx={40}
                    radiuspx="50%"
                    avatarUrl={game.avatarUrl}
                  ></Avatar>
                  <Username to={`/players/${game.userId}`}>
                    {game.username}
                  </Username>
                </UserContainer>
              </Column>
            )}
            <Column>{roleToIcon(game.role)}</Column>
            <Column>
              <WonLossSpan role={game.role} won={game.won}>
                {game.role === 'judge'
                  ? '-'
                  : game.won
                  ? 'Победа'
                  : 'Поражение'}
              </WonLossSpan>
            </Column>
            <Column>{prettyDate(game.startedAt)}</Column>
            <Column>{prettyDateDiff(game.startedAt, game.endedAt)}</Column>
            <Column>
              <PointsSpan points={game.points}>{game.points}</PointsSpan>
            </Column>
            <Column>{game.slot}</Column>
            <Column>
              <MVPSpan isMvp={game.isMvp}>{game.isMvp ? 'Да' : 'Нет'}</MVPSpan>
            </Column>
            <Column>
              <PointsSpan points={game.ntp || 0}>{game.ntp}</PointsSpan>
            </Column>
            <Column>
              <a
                href={`https://polemicagame.com/game-statistics/${game.gameId}`}
                target="_blank"
              >
                Посмотреть
              </a>
            </Column>
          </Row>
        ))}
      </tbody>
    </Table>
  );
};
