import styled from 'styled-components';
import { Input } from '../../components/UI/Input';
import { LoadingButton } from '../../components/UI/LoadingButton';
import { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { login } from '../../lib/api';
import axios from '../../lib/axios';
import { Error } from '../../components/UI/Error';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const LoginPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>('');
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const auth = useAuth();
  const navigate = useNavigate();

  const handleClick = async () => {
    setLoading(true);
    setError('');

    try {
      const resp = await login({ login: username, password: password });
      auth.setAuth(resp.access, resp.refresh);
      navigate('/admin', { replace: true });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const usernameChange = (name: string) => {
    setUsername(name);
  };

  const passwordChange = (pass: string) => {
    setPassword(pass);
  };

  return (
    <Container>
      <InputContainer>
        <Input placeholder="Логин" onChange={usernameChange} />
      </InputContainer>
      <InputContainer>
        <Input placeholder="Пароль" type="password" onChange={passwordChange} />
      </InputContainer>

      <ButtonContainer>
        <LoadingButton
          isLoading={loading}
          text="Отправить"
          onClick={handleClick}
        />
      </ButtonContainer>

      {error && <Error error={error} />}
    </Container>
  );
};
