import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { fetchPartners } from '../../lib/api';
import { Partner } from '../../models/partner';
import { Partners } from '../../components/profile/Partners';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';

export const PartnersPage = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [partners, setPartners] = useState<Partner[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await fetchPartners(playerId);
        setPartners(resp);
        setLoading(false);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Partners partners={partners} />;
};
