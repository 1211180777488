import React from 'react';
import { HallOfFame as hof } from '../../models';
import styled from 'styled-components';
import { Badge } from '../UI/Badge';
import { Flag } from '../../flags/flags';
import { NavLink } from 'react-router-dom';

interface Props {
  hof: hof;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

const TitleText = styled.span`
  font-size: 60px;
  color: white;
  margin-top: 12px;
  text-align: center;
  font-weight: bold;
  margin-top: 48px;
  text-shadow: 1px 1px 10px #f06868, 1px 1px 10px #ccc;
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 58px;
`;

const PlayerInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  color: white;
  gap: 12px;
`;

const PlayerPhoto = styled.div<{ source: string }>`
  width: 350px;
  height: 320px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: ${({ source }) => `url('${source}')`};
  flex-shrink: 0;
  border-radius: 24px;
`;

const PlayerNameContainer = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 40px;
    height: 30px;
    margin-left: 12px;
  }
`;

const PlayerLink = styled(NavLink)`
  font-size: 42px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: var(--red-accent);
  }
`;

const PlayerDescription = styled.p`
  white-space: pre-wrap;
`;

const BadgeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const HallOfFame: React.FC<Props> = (props) => {
  return (
    <Container>
      {props.hof.allStars && <TitleText>ПОБЕДИТЕЛИ ТУРНИРОВ ГОДА</TitleText>}
      {props.hof.allStars &&
        props.hof.allStars.map((player) => (
          <ProfileContainer key={player.username}>
            <PlayerPhoto source={player.photoUrl}></PlayerPhoto>
            <PlayerInfo>
              <PlayerNameContainer>
                <PlayerLink to={`/players/${player.playerId}`}>
                  {player.username}
                </PlayerLink>
                <Flag country={player.country}></Flag>
              </PlayerNameContainer>
              <BadgeContainer>
                {player.badges.map((badge) => (
                  <Badge
                    key={badge.name}
                    tooltip={badge.text}
                    badge={badge.name}
                    tournamentId={badge.tournamentId}
                    width={215}
                    height={100}
                  ></Badge>
                ))}
              </BadgeContainer>
              <PlayerDescription>{player.description}</PlayerDescription>
            </PlayerInfo>
          </ProfileContainer>
        ))}
      {props.hof.major && <TitleText>ПОБЕДИТЕЛИ МАЖОРОВ</TitleText>}
      {props.hof.major &&
        props.hof.major.map((player) => (
          <ProfileContainer key={player.username}>
            <PlayerPhoto source={player.photoUrl}></PlayerPhoto>
            <PlayerInfo>
              <PlayerNameContainer>
                <PlayerLink to={`/players/${player.playerId}`}>
                  {player.username}
                </PlayerLink>
                <Flag country={player.country}></Flag>
              </PlayerNameContainer>
              <BadgeContainer>
                {player.badges.map((badge) => (
                  <Badge
                    key={badge.name}
                    tooltip={badge.text}
                    badge={badge.name}
                    tournamentId={badge.tournamentId}
                    width={215}
                    height={100}
                  ></Badge>
                ))}
              </BadgeContainer>
              <PlayerDescription>{player.description}</PlayerDescription>
            </PlayerInfo>
          </ProfileContainer>
        ))}
      {props.hof.minor && <TitleText>ПОБЕДИТЕЛИ МИНОРОВ</TitleText>}
      {props.hof.minor &&
        props.hof.minor.map((player) => (
          <ProfileContainer key={player.username}>
            <PlayerPhoto source={player.photoUrl}></PlayerPhoto>
            <PlayerInfo>
              <PlayerNameContainer>
                <PlayerLink to={`/players/${player.playerId}`}>
                  {player.username}
                </PlayerLink>
                <Flag country={player.country}></Flag>
              </PlayerNameContainer>
              <BadgeContainer>
                {player.badges.map((badge) => (
                  <Badge
                    key={badge.name}
                    tooltip={badge.text}
                    badge={badge.name}
                    tournamentId={badge.tournamentId}
                    width={215}
                    height={100}
                  ></Badge>
                ))}
              </BadgeContainer>
              <PlayerDescription>{player.description}</PlayerDescription>
            </PlayerInfo>
          </ProfileContainer>
        ))}
    </Container>
  );
};
