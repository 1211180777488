import React, { useEffect, useState } from 'react';
import { ComboGame, PaginatedResponse, PlayerSearchResult } from '../../models';
import { LoadingButton } from '../UI/LoadingButton';
import styled from 'styled-components';
import { fetchComboGames } from '../../lib/api';
import { Table, Row, Column } from '../UI/Table';
import { prettyDate, prettyDateDiff } from '../../lib/date';
import { ArrowLeft, ArrowRight } from 'phosphor-react';
import { Error } from '../UI/Error';

interface Props {
  civilians: PlayerSearchResult[];
  mafia: PlayerSearchResult[];
  sheriff: PlayerSearchResult[];
  godfather: PlayerSearchResult[];
}

const Container = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Pagination = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const PaginationButton = styled.button<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ disabled }) =>
    disabled ? 'var(--gray-accent-light)' : 'var(--red-accent)'};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  padding: 6px 12px;
  border-radius: 24px;
  /* margin-right: 50px; */
  transition: background-color 0.3s;

  /* &:last-child {
    margin-right: 0;
  } */

  &:hover {
    background-color: ${({ disabled }) =>
      disabled ? 'var(--gray-accent-light)' : 'var(--red-accent-light)'};
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Combos: React.FC<Props> = (props) => {
  const [games, setGames] = useState<PaginatedResponse<ComboGame>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [requestFired, setRequestFired] = useState<boolean>(false);
  const [nextToken, setNextToken] = useState<string>();
  const [error, setError] = useState<any>();

  const handleClick = () => {
    setIsLoading(true);
    setError(undefined);
    setRequestFired(true);
    const doRequest = async () => {
      try {
        const resp = await fetchComboGames(
          {
            civilians: props.civilians.map((c) => c.userId),
            mafia: props.mafia.map((m) => m.userId),
            sheriff: props.sheriff.map((s) => s.userId),
            godfather: props.godfather.map((g) => g.userId),
          },
          nextToken!
        );
        setGames(resp);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    doRequest();
  };

  useEffect(() => {
    if (!nextToken) return;

    setIsLoading(true);
    setRequestFired(true);
    setError(undefined);
    const doRequest = async () => {
      try {
        const resp = await fetchComboGames(
          {
            civilians: props.civilians.map((c) => c.userId),
            mafia: props.mafia.map((m) => m.userId),
            sheriff: props.sheriff.map((s) => s.userId),
            godfather: props.godfather.map((g) => g.userId),
          },
          nextToken!
        );
        setGames(resp);
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };
    doRequest();
  }, [nextToken]);

  const handlePrevPage = () => {
    if (games!.prevToken === '') return;

    setNextToken(games!.prevToken);
  };
  const handleNextPage = () => {
    if (games!.nextToken === '') return;

    setNextToken(games!.nextToken);
  };

  return (
    <Container>
      <LoadingButton
        isLoading={isLoading}
        text="Найти"
        onClick={handleClick}
      ></LoadingButton>

      {error && <Error error={error} />}

      {requestFired && !error && games?.items.length === 0 && (
        <span style={{ color: 'white', marginTop: '12px' }}>
          Таких игр не найдено
        </span>
      )}

      {!isLoading && games && games.items.length > 0 && !error && (
        <TableContainer>
          <Table style={{ marginTop: '12px' }}>
            <thead>
              <tr>
                <th>Номер</th>
                <th>Дата</th>
                <th>Длительность</th>
                <th>Действия</th>
              </tr>
            </thead>
            <tbody>
              {games!.items.map((item) => (
                <Row key={item.gameId}>
                  <Column>{item.gameId}</Column>
                  <Column>{prettyDate(item.startedAt)}</Column>
                  <Column>
                    {prettyDateDiff(item.startedAt, item.endedAt)}
                  </Column>
                  <Column>
                    <a
                      href={`https://polemicagame.com/game-statistics/${item.gameId}`}
                      target="_blank"
                    >
                      Посмотреть
                    </a>
                  </Column>
                </Row>
              ))}
            </tbody>
          </Table>
          <Pagination>
            <PaginationButton
              disabled={games!.prevToken === ''}
              onClick={handlePrevPage}
            >
              <ArrowLeft size={24}></ArrowLeft>
            </PaginationButton>
            <PaginationButton
              disabled={games!.nextToken === ''}
              onClick={handleNextPage}
            >
              <ArrowRight size={24}></ArrowRight>
            </PaginationButton>
          </Pagination>
        </TableContainer>
      )}
    </Container>
  );
};
