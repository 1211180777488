import React from 'react';
import { Helmet } from 'react-helmet-async';

const FAVICON_URL = 'https://notoxic.pro/favicon.ico';

interface Props {
  title: string;
  description: string;
  image?: string;
}

export const CEO: React.FC<Props> = (props) => {
  return (
    <Helmet>
      <title>{props.title}</title>
      <meta name="description" content={props.description} />
      <meta property="og:title" content={props.title} />
      <meta property="og:description" content={props.description} />
      {props.image && <meta property="og:image" content={props.image} />}
      <link rel="shortcut icon" href={FAVICON_URL} type="image/x-icon" />
    </Helmet>
  );
};
