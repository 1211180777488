import React, { useEffect, useState } from 'react';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { AdminTournament } from '../../models';
import { Error } from '../../components/UI/Error';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Tournaments } from '../../components/admin/Tournaments';

export const TournamentsPage = () => {
  const axios = useAxiosPrivate();
  const [tournaments, setTournaments] = useState<AdminTournament[]>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const doRequest = async () => {
      try {
        const resp = await axios.get('/admin/tournaments');
        const data = resp.data;
        setTournaments(
          data.map((t: any) => {
            const tournament: AdminTournament = {
              id: t['id'],
              name: t['name'],
              startedAt: new Date(t['started_at']),
              defaultBadge: '',
              description: '',
              endedAt: new Date(),
              firstPlaceBadge: '',
              hqBadge: '',
              links: {
                youtube: [],
                vk: [],
              },
              placements: [],
              secondPlaceBadge: '',
              thirdPlaceBadge: '',
              type: '',
            };
            return tournament;
          })
        );
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <Tournaments tournaments={tournaments!} />;
};
