import React from 'react';
import styled from 'styled-components';

interface Props {
  className?: string;
  children: any;
  height?: number;
}

const CardContainer = styled.div<{ height?: number }>`
  padding: 12px;
  background-color: var(--black-accent);
  border: 1px solid var(--gray-accent);
  border-radius: 8px;
  /* height: 300px; */
  height: ${(props) => (props.height ? `${props.height}px` : null)};
  position: relative;
`;

export const Card: React.FC<Props> = (props) => {
  return (
    <CardContainer className={props.className} height={props.height}>
      {props.children}
    </CardContainer>
  );
};
