import React from 'react';
import styled from 'styled-components';
import { Tooltip } from './Tooltip';
import { NavLink } from 'react-router-dom';

interface Props {
  badge: string;
  tooltip: string;
  tournamentId: number;
  width?: number;
  height?: number;
}

const BadgeContainer = styled.img<{ width?: number; height?: number }>`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : '86px')};
  height: ${({ height }) => (height ? `${height}px` : '40px')};
  /* margin-right: 8px;
  margin-top: 8px;

  &:last-child {
    margin-right: 0;
  } */
`;

const LinkContainer = styled(NavLink)`
  margin-right: 8px;
  margin-top: 8px;

  &:last-child {
    margin-right: 0;
  }
`;

export const Badge: React.FC<Props> = (props) => {
  return (
    <Tooltip text={props.tooltip}>
      <LinkContainer to={`/tournaments/${props.tournamentId}`}>
        <BadgeContainer
          // badge={props.badge}
          // tournamentId={props.tournamentId}
          // src={require(`../../assets/img/badges/${props.badge}`)}
          src={props.badge}
          width={props.width}
          height={props.height}
        ></BadgeContainer>
      </LinkContainer>
    </Tooltip>
  );
};
