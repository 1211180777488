import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Card } from '../UI/Card';

const Container = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;

  &:first-child {
    margin-top: 0;
  }
`;

const Title = styled.span`
  color: white;
  font-size: 24px;
`;

const Separarator = styled.div`
  width: 100%;
  height: 1px;
  background-color: #2a2a2a;
  margin-top: 12px;
  margin-bottom: 12px;
`;

export const TitledCard: React.FC<{
  title: string;
  height?: number;
  children: ReactNode;
}> = (props) => {
  return (
    <Container>
      <Title>{props.title}</Title>
      <Separarator></Separarator>
      <Card height={props.height}>{props.children}</Card>
    </Container>
  );
};
