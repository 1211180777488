import React, { useRef, useState } from 'react';
import { AdminPlayer, Sticker } from '../../models';
import styled from 'styled-components';
import { Avatar } from '../UI/Avatar';
import { LoadingButton } from '../UI/LoadingButton';
import { Crosshair, Minus, Pencil, Plus, Trash } from 'phosphor-react';
import ReactCrop, { type Crop, PixelCrop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Error } from '../UI/Error';
import { Success } from '../UI/Success';
import Resizer from 'react-image-file-resizer';
import { Input } from '../UI/Input';

interface Props {
  player: AdminPlayer;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 12px;
`;

const PlayerRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  color: white;
`;

const AvatarOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 100%;
  border-radius: 12px;
  transition: 0.3s background-color, color;
  color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    cursor: pointer;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
`;

const CropContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StickersContainer = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StickersRow = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 8px;
  align-items: center;
`;

const resizeFile = (file: any, type: string) => {
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      25,
      25,
      type,
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      'base64'
    );
  });
};

type StickerBlob = {
  id: number;
  blob: Blob;
};

export const EditPlayer: React.FC<Props> = (props) => {
  const countries = [
    'AD',
    'AE',
    'AF',
    'AG',
    'AI',
    'AL',
    'AM',
    'AO',
    'AQ',
    'AR',
    'AS',
    'AT',
    'AU',
    'AW',
    'AX',
    'AZ',
    'BA',
    'BB',
    'BD',
    'BE',
    'BF',
    'BG',
    'BH',
    'BI',
    'BJ',
    'BL',
    'BM',
    'BN',
    'BO',
    'BQ',
    'BR',
    'BS',
    'BT',
    'BV',
    'BW',
    'BY',
    'BZ',
    'CA',
    'CC',
    'CD',
    'CF',
    'CG',
    'CH',
    'CI',
    'CK',
    'CL',
    'CM',
    'CN',
    'CO',
    'CR',
    'CU',
    'CV',
    'CW',
    'CX',
    'CY',
    'CZ',
    'DE',
    'DJ',
    'DK',
    'DM',
    'DO',
    'DZ',
    'EC',
    'EE',
    'EG',
    'EH',
    'ER',
    'ES',
    'ET',
    'FI',
    'FJ',
    'FK',
    'FM',
    'FO',
    'FR',
    'GA',
    'GB',
    'GD',
    'GE',
    'GF',
    'GG',
    'GH',
    'GI',
    'GL',
    'GM',
    'GN',
    'GP',
    'GQ',
    'GR',
    'GS',
    'GT',
    'GU',
    'GW',
    'GY',
    'HK',
    'HM',
    'HN',
    'HR',
    'HT',
    'HU',
    'ID',
    'IE',
    'IL',
    'IM',
    'IN',
    'IO',
    'IQ',
    'IR',
    'IS',
    'IT',
    'JE',
    'JM',
    'JO',
    'JP',
    'KE',
    'KG',
    'KH',
    'KI',
    'KM',
    'KN',
    'KP',
    'KR',
    'KW',
    'KY',
    'KZ',
    'LA',
    'LB',
    'LC',
    'LI',
    'LK',
    'LR',
    'LS',
    'LT',
    'LU',
    'LV',
    'LY',
    'MA',
    'MC',
    'MD',
    'ME',
    'MF',
    'MG',
    'MH',
    'MK',
    'ML',
    'MM',
    'MN',
    'MO',
    'MP',
    'MQ',
    'MR',
    'MS',
    'MT',
    'MU',
    'MV',
    'MW',
    'MX',
    'MY',
    'MZ',
    'NA',
    'NC',
    'NE',
    'NF',
    'NG',
    'NI',
    'NL',
    'NO',
    'NP',
    'NR',
    'NU',
    'NZ',
    'OM',
    'PA',
    'PE',
    'PF',
    'PG',
    'PH',
    'PK',
    'PL',
    'PM',
    'PN',
    'PR',
    'PS',
    'PT',
    'PW',
    'PY',
    'QA',
    'RE',
    'RO',
    'RS',
    'RU',
    'RW',
    'SA',
    'SB',
    'SC',
    'SD',
    'SE',
    'SG',
    'SH',
    'SI',
    'SJ',
    'SK',
    'SL',
    'SM',
    'SN',
    'SO',
    'SR',
    'SS',
    'ST',
    'SV',
    'SX',
    'SY',
    'SZ',
    'TC',
    'TD',
    'TF',
    'TG',
    'TH',
    'TJ',
    'TK',
    'TL',
    'TM',
    'TN',
    'TO',
    'TR',
    'TT',
    'TV',
    'TW',
    'TZ',
    'UA',
    'UG',
    'UM',
    'US',
    'UY',
    'UZ',
    'VA',
    'VC',
    'VE',
    'VG',
    'VI',
    'VN',
    'VU',
    'WF',
    'WS',
    'YE',
    'YT',
    'ZA',
    'ZM',
    'ZW',
  ];

  const axios = useAxiosPrivate();
  const [checked, setChecked] = useState<boolean>(props.player.isRanked);
  const [country, setCountry] = useState<string | undefined>(
    props.player.country
  );
  const [imgSrc, setImgSrc] = useState<string>(props.player.avatarUrl || '');
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<Crop>();
  const [selectedImage, setSelectedImage] = useState<string>();
  const inputRef = useRef<any>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [imageBlob, setImageBlob] = useState<Blob>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const [stickerSelected, setStickerSelected] = useState<string>();
  const [newStickers, setNewStickers] = useState<Sticker[]>([]);
  const [stickerTitle, setStickerTitle] = useState<string>('');
  const [stickerBlob, setStickerBlob] = useState<Blob>();
  const [newStickerBlobs, setNewStickerBlobs] = useState<StickerBlob[]>([]);
  const [stickers, setStickers] = useState<Sticker[]>(props.player.stickers);

  const checkboxChange = (checked: boolean) => {
    setChecked(checked);
  };

  const selectChange = (country: string) => {
    setCountry(country);
  };

  const handleFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const res = reader.result?.toString() || '';
        setImgSrc(res);
        console.log('set img src!', res);
        fetch(res)
          .then((res) => res.blob())
          .then(setImageBlob);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleStickerSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      // const read = async () => {
      //   const file = e.target.files![0];
      //   const type =
      //     file.name.split('.').length > 0 ? file.name.split('.')[1] : 'jpg';
      //   console.log('type:', type);
      //   const resized = await resizeFile(e.target.files![0], type);
      //   const res = await fetch(resized as URL);
      //   const blob = await res.blob();
      //   setStickerSelected(resized as string);
      //   console.log('img:', resized);
      // };
      // read();
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const res = reader.result?.toString() || '';
        setStickerSelected(res);
        fetch(res)
          .then((res) => res.blob())
          .then(setStickerBlob);
      });
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const addSticker = () => {
    const id = Math.random();
    setNewStickers((prev) => {
      const na = [
        ...prev,
        {
          title: stickerTitle,
          url: stickerSelected!,
          id: id,
        },
      ];
      return na;
    });
    setNewStickerBlobs((prev) => [...prev, { id: id, blob: stickerBlob! }]);
    setStickerSelected('');
  };

  const overlayClicked = () => {
    inputRef.current!.click();
  };

  const cropCompleted = () => {
    const img = document.getElementsByTagName('img')[0];
    const completed = completedCrop!;
    const canvas = document.createElement('canvas');
    canvas.width = completed.width;
    canvas.height = completed.height;
    const ctx = canvas.getContext('2d')!;

    ctx.drawImage(
      img,
      completed.x,
      completed.y,
      completed.width,
      completed.height,
      0,
      0,
      completed.width,
      completed.height
    );

    // As Base64 string
    const base64Image = canvas.toDataURL('image/jpeg');
    setImgSrc(base64Image);
    setShowModal(false);
    setSelectedImage(undefined);

    // return new Promise((resolve, reject) => {
    //   canvas.toBlob((file) => {
    //     file.name = fileName;
    //     resolve(file);
    //   }, 'image/jpeg');
    // });
  };

  const saveButtonClicked = () => {
    setLoading(true);
    setError(undefined);
    setSuccess(false);
    const doRequest = async () => {
      try {
        await axios.postForm(`/admin/players/${props.player.id}`, {
          country: country,
          is_ranked: checked,
          avatar: imageBlob,
          new_stickers: newStickerBlobs.map((b) => {
            const title = newStickers.find((s) => s.id === b.id)?.title;
            return {
              title: title,
              blob: b.blob,
            };
          }),
          stickers: JSON.stringify(stickers),
        });
        setSuccess(true);
        setNewStickerBlobs([]);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  };

  const removeNewSticker = (id: number) => {
    setNewStickers((prev) => prev.filter((s) => s.id !== id));
    setNewStickerBlobs((prev) => prev.filter((s) => s.id !== id));
  };

  const removeExistingSticker = (url: string) => {
    setStickers((prev) => prev.filter((s) => s.url !== url));
  };

  return (
    <Container>
      <input
        type="file"
        onChange={(e) => handleFileSelected(e)}
        ref={inputRef}
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png,.gif"
      ></input>
      <PlayerRow>
        <Avatar heightpx={200} widthpx={200} radiuspx="12px" avatarUrl={imgSrc}>
          <AvatarOverlay onClick={overlayClicked}>
            <Pencil height={48} width={48} />
            <span>Изменить аватар</span>
          </AvatarOverlay>
        </Avatar>
        <InfoContainer>
          <input
            type="checkbox"
            value="Показывать в лидерборде"
            checked={checked}
            onChange={(e) => checkboxChange(e.target.checked)}
          ></input>{' '}
          Показывать в лидерборде
          <select
            name="country"
            value={country}
            onChange={(e) => selectChange(e.target.value)}
          >
            {countries.map((c) => (
              <option key={c} value={c}>
                {c}
              </option>
            ))}
          </select>
        </InfoContainer>
      </PlayerRow>
      <StickersContainer>
        <span style={{ color: 'white', fontSize: 24 }}>Стикеры</span>
        <StickersRow>
          {!stickerSelected && (
            <input
              type="file"
              onChange={(e) => handleStickerSelected(e)}
              style={{ color: 'white' }}
              accept=".png,.jpg,.jpeg,.gif"
            ></input>
          )}
          {stickerSelected && <img src={stickerSelected} height={24} />}
          <Input placeholder="Заголовок" onChange={(e) => setStickerTitle(e)} />
          {stickerSelected && (
            <Plus
              width={24}
              height={24}
              style={{ color: 'white', cursor: 'pointer' }}
              onClick={addSticker}
            ></Plus>
          )}
        </StickersRow>
        {newStickers.map((s) => (
          <StickersRow key={Math.random()}>
            <img src={s.url} height={24} />
            <span style={{ color: 'white' }}>{s.title}</span>
            <Trash
              width={24}
              height={24}
              style={{ color: 'white', cursor: 'pointer' }}
              onClick={() => removeNewSticker(s.id!)}
            ></Trash>
          </StickersRow>
        ))}
        {stickers.map((s) => (
          <StickersRow key={s.url}>
            <img src={s.url} height={24} />
            <span style={{ color: 'white' }}>{s.title}</span>
            <Trash
              width={24}
              height={24}
              style={{ color: 'white', cursor: 'pointer' }}
              onClick={() => removeExistingSticker(s.url)}
            ></Trash>
          </StickersRow>
        ))}
      </StickersContainer>
      <LoadingButton
        isLoading={loading}
        text="Сохранить"
        onClick={saveButtonClicked}
      />
      {/* // <Modal title="Обрезка" notCentered={true}>
        //   <CropContainer>
        //     <ReactCrop
        //       crop={crop}
        //       aspect={1}
        //       maxWidth={500}
        //       maxHeight={500}
        //       onChange={(c) => {
        //         setCrop(c);
        //       }}
        //       onComplete={(c) => {
        //         setCompletedCrop(c);
        //       }}
        //     >
        //       <img
        //         src={selectedImage}
        //         // style={{ maxWidth: '600px', maxHeight: '600px' }}
        //       />
        //     </ReactCrop>
        //     <button onClick={cropCompleted}>Принять</button>
        //   </CropContainer>
        // </Modal> */}
      {/* )} */}
      {error && <Error error={error} />}
      {success && <Success text="Профиль обновлен" />}
    </Container>
  );
};
