import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AdminPlayer } from '../../models';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { Error } from '../../components/UI/Error';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { EditPlayer } from '../../components/admin/EditPlayer';

export const EditPlayerPage = () => {
  const axios = useAxiosPrivate();
  const params = useParams();
  const playerId = params.playerId!;
  const [player, setPlayer] = useState<AdminPlayer>();
  const [error, setError] = useState<any>();
  const [success, setSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(true);
    setError(undefined);
    setSuccess(false);

    const doRequest = async () => {
      try {
        const resp = await axios.get(`/admin/players/${playerId}`);
        const data = resp.data;
        setPlayer({
          id: data['id'],
          username: data['username'],
          avatarUrl: data['avatar_url'],
          isRanked: data['is_ranked'],
          country: data['country'],
          stickers: data['stickers'],
        });
        setSuccess(true);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, [playerId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return <EditPlayer player={player!} />;
};
