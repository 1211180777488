import React from 'react';
import styled from 'styled-components';
import { NavLink, Outlet } from 'react-router-dom';

const CardContainer = styled.div``;

const Navigation = styled.div`
  display: flex;
  justify-content: space-evenly;
`;

const NavigationItem = styled(NavLink)`
  text-align: center;
  color: white;
  padding: 8px 30px;
  background-color: var(--gray-accent);
  width: 100%;
  /* margin-right: 4px; */
  transition: background-color 0.3s;

  &:hover {
    background-color: var(--red-accent-light);
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
    border-top-right-radius: 8px;
  }

  &:first-child {
    border-top-left-radius: 8px;
  }

  &.active {
    background-color: var(--red-accent-light);
  }
`;

const CardBody = styled.div`
  padding: 12px;
  background-color: var(--black-accent);
  border: 1px solid var(--gray-accent);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
`;

export const BansPage = () => {
  return (
    <CardContainer>
      <Navigation>
        <NavigationItem to="current">Текущие</NavigationItem>
        <NavigationItem to="expired">Прошедшие</NavigationItem>
        <NavigationItem to="citations">Предупреждения</NavigationItem>
      </Navigation>
      <CardBody>
        <Outlet />
      </CardBody>
    </CardContainer>
  );
};
