import { Tooltip } from '../components/UI/Tooltip';

export const Godfather = () => {
  return (
    <Tooltip text="Дон">
      <svg
        width="24"
        height="24"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.89388 3.12085C6.51862 3.2214 6.54875 3.77238 6.09766 3.89325C5.66058 4.01036 5.23845 3.70418 4.82986 3.81367C4.44011 3.9181 4.26413 4.27169 4.2276 4.91197C4.1013 7.10074 4.10737 7.00749 4.14054 7.11196C4.31291 7.75527 7.23397 9.23517 10.3872 8.39025L10.3885 8.39508C11.2004 8.17755 12.325 7.6821 12.0461 6.64132C12.0088 6.46341 12.0583 6.59041 10.8743 4.27911C10.6318 3.81091 10.4707 3.61132 9.54736 3.39544C8.8226 3.21745 7.29764 3.01267 6.89388 3.12085ZM3.88288 7.57027C2.78691 7.92139 1.40686 8.50858 1.75238 9.79809C2.3179 11.9086 7.93681 13.1931 11.8343 12.1488C14.819 11.349 15.2049 9.77554 14.9138 8.68888C14.6847 7.83403 13.7014 7.06222 12.2373 6.83337C12.5085 7.40103 12.5483 7.58811 12.5691 7.66587C12.8419 8.68395 11.9528 9.52886 10.5826 9.896C7.49548 10.7289 4.24912 9.45676 3.89647 8.14068C3.8473 7.95716 3.86091 7.75681 3.88336 7.57014L3.88288 7.57027Z"
          fill="#A3A4A8"
        ></path>
      </svg>
    </Tooltip>
  );
};
