import React from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  isLoading: boolean;
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 24px;
  border-radius: 12px;
  text-align: center;
  background: var(--red-accent);
  border: 1px solid var(--gray-accent);
  color: var(--black-accent);
  transition: background 0.3s;
  min-width: 100px;
  min-height: 42px;

  &:hover {
    background: var(--gray-accent3);
    cursor: pointer;
  }

  &:active {
    background: var(--black-accent);
  }

  &.disabled {
    background: var(--gray-accent3);
    cursor: not-allowed;
  }
`;

export const LoadingButton: React.FC<Props> = (props) => {
  const onClick = () => {
    if (props.isLoading) {
      return;
    }

    props.onClick();
  };

  if (props.isLoading)
    return (
      <Button className="disabled">
        <LoadingSpinner zeroPadding={true} />
      </Button>
    );

  if (props.disabled) return <Button className="disabled">{props.text}</Button>;

  return <Button onClick={onClick}>{props.text}</Button>;
};
