import React from 'react';
import { useParams } from 'react-router-dom';
import { Games } from '../../components/games/Games';
import { Card } from '../../components/UI/Card';

export const GamesPage = () => {
  const params = useParams();
  const userId = params.playerId ? +params.playerId : undefined;

  return (
    <Card>
      <Games itemsPerPage={userId ? 10 : 50} userId={userId}></Games>
    </Card>
  );
};
