import React, { useEffect, useState } from 'react';
import {
  AdminBanReason,
  VIOLATION_TYPE_CURRENT,
  VIOLATION_TYPE_EXPIRED,
  VIOLATION_TYPE_TEMP,
  Violation,
} from '../../models';
import { useParams } from 'react-router-dom';
import useAxiosPrivate from '../../hooks/useAxiosPrivate';
import { fetchViolations } from '../../lib/api';
import { LoadingSpinner } from '../../components/UI/LoadingSpinner';
import { Error } from '../../components/UI/Error';
import { EditBans } from '../../components/admin/EditBans';

export const EditBansPage = () => {
  const params = useParams();
  const playerId = +params.playerId!;
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [currentViolations, setCurrentViolations] = useState<Violation[]>([]);
  const [expiredViolations, setExpiredViolations] = useState<Violation[]>([]);
  const [citations, setCitations] = useState<Violation[]>([]);
  const [reasons, setReasons] = useState<AdminBanReason[]>([]);
  const axios = useAxiosPrivate();

  useEffect(() => {
    setLoading(true);
    const doRequest = async () => {
      try {
        const [currentResp, expiredResp, citationsResp, reasonsResp] =
          await Promise.all([
            fetchViolations(VIOLATION_TYPE_CURRENT, playerId),
            fetchViolations(VIOLATION_TYPE_EXPIRED, playerId),
            fetchViolations(VIOLATION_TYPE_TEMP, playerId),
            axios.get('/admin/bans/reasons'),
          ]);
        setReasons(
          reasonsResp.data.map((v: any) => {
            const item: AdminBanReason = {
              id: v['id'],
              body: v['reason'],
            };
            return item;
          })
        );
        setCurrentViolations(currentResp);
        setExpiredViolations(expiredResp);
        setCitations(citationsResp);
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    doRequest();
  }, []);

  if (loading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;

  return (
    <EditBans
      playerId={playerId}
      current={currentViolations}
      expired={expiredViolations}
      citations={citations}
      reasons={reasons}
    />
  );
};
